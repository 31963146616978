.container {
    padding: 5px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 25px);
}

.arrowContainer {
    height: 22px;
    margin-top: -5px;
    font-size: 40px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 3px;
}

.subjectContainer {
    min-width: 0px;
    position: relative;
    display: flex;
    margin-bottom: 5px;
    flex-direction: row;
    max-width: 100%;
    display: inline;
    color: var(--accent);
    font-size: 14px;
}
