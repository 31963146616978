.container {
    padding: 5px;
    display: flex;
    flex-direction: row;
    height: 40px;
}

.messageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    overflow: hidden;
}

.iconContainer {
    height: 35px;
    width: 35px;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--secondary);
}
.messageLbl {
    font-size: 14px;
    margin-left: 4px;
    color: var(--accent);
    white-space: nowrap;
    overflow: hidden;
    margin-right: 5px;
    text-overflow: ellipsis;
    min-width: 125px;
}
