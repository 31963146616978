.container,
.dateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
    overflow: hidden;
}
.dateContainer {
    margin-right: 3px;
}
.lbl {
    font-size: 8px;
    color: var(--accent);
    font-weight: 700;
}

.dateLbl {
    font-size: 8px;
    margin-right: 3px;
    color: var(--accent);
}

.bigLbl {
    font-size: 8px;
    color: var(--accent);
}

.arrowContainer {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 10px;
    margin-top: 0px;
    height: 10px;
    color: var(--accent);
}
