.navBarNotificationsCont {
    height: 54px;
    display: flex;
    margin-right: 5px;
    flex-direction: column;
    justify-content: center;
    color: var(--accent);
}
.navBarNotificationsIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 32px;
    color: var(--accent);
}

.navBarNotificationsIcon:hover {
    color: white;
    background-color: var(--accent);
}
