.urlLabel {
    margin-top: 5px;
    font-size: 18px;
    margin-bottom: 10px;
}

.paramsLabel {
    font-size: 18px;
    margin-top: 10px;
}

.queryParamsContainer {
    margin-top: 5px;
}
.paramsContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.addParam {
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 15px;
    cursor: pointer;
}

.addIconContainer {
    font-size: 24px;
    height: 24px;
}

.addParamLbl {
    font-size: 15px;
    color: var(--accent);
}

.customLabel {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
    font-size: 20px;
}
