.button {
    background-color: var(--defaultGrey);
    border-radius: 10px;
    width: 25px;
    height: 25px;
    border: 1px solid #0000003b;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--accent);
    justify-content: center;
}

.infoContainer {
    width: 80vw;
    font-size: 12px;
}
.iconContainer {
    height: 25px;
    font-size: 18px;
    margin-left: 2px;
}

.button:hover {
    border: 1px solid var(--greyFont);
}

.container {
    width: 1000px;
    min-height: 700px;
    height: 80vh;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerLbl {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.widgetContainer {
    display: block;
    height: fit-content;
    width: fit-content;
    max-width: calc(100% - 40px);
    width: 960px;
    padding: 20px;
    overflow-y: auto;
}
