.termsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.termsLabel {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.addTermButton {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 15px;
    cursor: pointer;
}
.addIconContainer {
    font-size: 28px;
    height: 28px;
}
.addTermLbl {
    font-size: 16px;
    color: var(--accent);
}
