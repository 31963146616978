.emptyContainer {
    padding-top: 0px;
    /* margin-right: -4px; */
    height: 350px;
}

.container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
    border: 1px solid var(--light-grey);
    overflow: hidden;
    overflow-y: auto;
    max-width: 220px;
    min-width: 140px;
    width: fit-content;
    max-height: 350px;
    position: relative;
    z-index: 20;
    background-color: white;
}

.optionContainer,
.optionSelectableContainer {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--light-grey);
    min-height: 30px;
    width: 100%;
}
.optionSelectableContainer {
    cursor: pointer;
}
.optionSelectableContainer:hover {
    background-color: var(--greyHover);
}
.left {
    width: 100%;
}
.top {
    height: 20px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bottom {
    font-size: 12px;
    padding-left: 10px;
    margin-top: 2px;
    color: var(--greyFont);
}

.rootLbl {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.checkIcon {
    height: 18px;
    width: 18px;
    font-size: 18px;
    margin-left: 5px;
    margin-right: 3px;
}
