.infiniteList {
    overflow-y: scroll;
    height: 100%;
    padding-left: 25px;
    max-width: 100%;
}

.loadMoreBtn {
    font-size: 16px;
    color: var(--secondary);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
}

.loaderDots {
    text-align: center;
    margin-bottom: 10px;
}

.loaderDot {
    animation: blink 1s infinite;
    color: var(--secondary);
    font-size: 48px;
}

.loaderDot:nth-child(2) {
    animation-delay: 250ms;
}

.loaderDot:nth-child(3) {
    animation-delay: 500ms;
}

@keyframes blink {
    50% {
        color: transparent;
    }
}

@media screen and (max-width: 576px) {
    .infiniteList {
        padding-left: 10px;
        max-width: calc(100% - 10px);
    }
}

.loaderContainer {
    position: relative;
    height: 100%;
}
