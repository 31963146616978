.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 5px 0px;
}
.editingContainer {
    display: flex;
    flex-direction: column;
}
.titleLbl {
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.descriptionPar {
    font-size: 16px;
    margin: 0px;
    white-space: pre-wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: default;
}
