.container {
    background-color: var(--primary1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    margin-top: 2px;
    max-width: 300px;
    /* padding-right: 10px; */
    width: fit-content;
    border: 1px solid var(--outlineGrey);
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    max-height: 215px;
    font-size: 16px;
}

.attachmentsContainer {
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--light-grey);
    min-width: 160px;
}
.attachmentsLbl {
    margin-left: 10px;
    font-weight: 600;
    cursor: default;
}

.addBtn {
    background-color: var(--secondary);
    color: white;
    border-radius: 5px;
    font-size: 20px;
    margin-left: auto;
    margin-right: 7px;
    width: 30px;
    display: flex;
    justify-content: center;
    height: 20px;
    cursor: pointer;
}

.filesContainer {
    padding-top: 7px;
    overflow-y: auto;
    min-height: 35px;
    overflow-x: hidden;
    padding-bottom: 7px;
    padding-left: 1px;
    max-height: 500px;
}
