.container {
    position: relative;
    background-color: var(--primary1);
    height: 90px;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 25px;
    overflow: auto;
    display: flex;
    cursor: pointer;
}

.info {
    flex-grow: 1;
    max-width: calc(100% - 80px);
    overflow: hidden;
}

.photo {
    margin: auto 15px;
}

.sideBar {
    position: absolute;
    background-color: var(--secondary);
    height: 90px;
    width: 10px;
    border-radius: 10px 0 0 10px;
}

.title {
    margin: 10px 0 0 20px;
    font-size: 16px;
    font-weight: 600;
    max-width: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    margin: 10px 0 0 20px;
    font-size: 14px;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media screen and (max-width: 576px) {
    .container {
        margin-bottom: 15px;
    }
}

.whatsappIcon {
    height: 32px;
    width: 32px;
}
