.header {
    display: flex;
    flex-direction: row;
}
.titleLbl {
    font-size: 17px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.editContainer {
    margin-left: auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    padding-left: 5px;
}

.container {
    margin-bottom: 10px;
    width: 100%;
    margin-top: 10px;
}

.processContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5x;
    margin-left: 15px;
    max-width: calc(100% - 15px);
}
