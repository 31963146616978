.container {
    position: relative;
}
.header {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}
.menuContainer {
    position: relative;
}
.titleLbl {
    font-size: 17px;
    cursor: pointer;
}

.editContainer {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
}
.pillContainer {
    max-width: 100%;
    padding-top: 1px;
    height: 26px;
    display: flex;
}

.pillsContainer {
    width: calc(100%);
    display: flex;
    flex-direction: column;
}

.pillCont {
    max-width: 100%;
    display: inline;
    cursor: pointer;
}
.pillContClosed {
    max-width: 100%;
    display: inline;
}

.container {
    margin-top: 10px;
    margin-bottom: 10px;
}
