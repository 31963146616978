.container {
    padding: 5px;
    display: flex;
    flex-direction: row;
    height: 40px;
}

.areaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    overflow: hidden;
}

.iconContainer {
    margin-left: 8px;
    color: var(--secondary);
    font-size: 26px;
    width: 30px;
    height: 28px;
}
.nameLbl {
    font-size: 14px;
    margin-left: 4px;
    color: var(--accent);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
