.container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.entityContainer {
    display: flex;
    /* justify-content: center; */
    position: relative;
    width: fit-content;
    max-width: 100%;
    flex-direction: column;
}

.btnsContainer {
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.copyBtn,
.delBtn {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
