.container {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 11px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--light-grey);
    cursor: pointer;
}
.container:hover {
    background-color: var(--greyHover);
}
.top {
    height: 20px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bottom {
    font-size: 12px;
    margin-left: 1px;
    margin-top: 2px;
    color: var(--greyFont);
    cursor: pointer;
}

.colorIcon {
    height: 14px;
    width: 14px;
    border-radius: 5px;
}

.rootLbl {
    margin-left: 7px;
}
.dotsLbl {
    margin-left: 2px;
    font-weight: 700;
    font-size: 15px;
}
.valueLbl {
    margin-left: 5px;
}
.closeIcon {
    margin-left: auto;
    margin-right: 5px;
    height: 22px;
    width: 22px;
    font-size: 20px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.closeIcon:hover {
    border: 1px solid var(--greyFont);
    margin-right: 4px;
}

.nameLbl {
    max-width: calc(100% - 41px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
    cursor: pointer;
}

.search {
    background-color: var(--secondary2);
    border-radius: 3px;
}

.searchSymbol {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;
    margin-bottom: 10px;
    font-size: 10px;
    background-color: var(--secondary2);
    border-radius: 10px;
    padding: 1px;
}
