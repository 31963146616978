.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 30px 0px 10px;
    height: calc(100% - 30px);
    overflow-y: auto;
}

.topContainer {
    display: flex;
    flex-direction: row;
    height: fit-content;
}

.profileCard {
    flex-grow: 1;
    background-color: white;
    margin: 0 0px 20px 20px;
    border-radius: 10px;
    min-height: 350px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 20px);
    position: relative;
}
.profileContent {
    padding: 20px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: fit-content;
    padding-bottom: 45px;
}

.profileTitle {
    color: var(--secondary);
    font-size: 22px;
}

.statCard {
    width: 100%;
    background-color: white;
    border-radius: 10px;
}

.organization {
    margin: 5px auto 0 auto;
    font-size: 18px;
    color: var(--secondary);
    font-weight: 700;
}

.profileContainer {
    display: flex;
    flex-grow: 1;
}

.leftPanel {
    padding-top: 20px;
    width: fit-content;
    max-width: 100%;
    position: relative;
    padding-right: 20px;
}

.rightPanel {
    margin-left: 20px;
    width: 360px;
    height: 200px;
    max-width: 100%;
    margin-right: auto;
}

.divider {
    background-color: var(--secondary);
    width: 1px;
    margin-left: auto;
}

.profileLbl {
    margin-left: 10px;
    color: var(--accent);
    font-size: 18px;
    font-weight: 500;
}

.inputContainer {
    width: 390px;
    max-width: calc(100% - 10px);
    margin-left: 10px;
    margin-top: 7px;
    margin-bottom: 10px;
}
.containerSubmitBtn {
    margin-top: 25px;
    margin-left: 10px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 390px;
}
.headerCont {
    flex-direction: row;
    display: flex;
}

.changePasswordCont {
    margin-left: auto;
}
