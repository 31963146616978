.stepContainer {
    display: flex;
    position: relative;
    overflow: hidden;
}

.replyCont {
    width: fit-content;
    max-width: 75%;
    margin-right: auto;
}

.messageCard {
    min-height: 20px;
    background-color: #cbe0ec;
    border-radius: 0px 10px 10px 10px;
    border: 1px solid var(--secondary2);
    margin-left: 20px;
    padding: 10px;
    min-height: 20px;
    max-width: calc(100% - 42px);
}

.trContainer {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -8px;
    left: -15px;
    cursor: default;
    overflow: hidden;
}

.tr {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0px;
    left: 26px;
    background-color: #cbe0ec;
    border-left: 1px solid var(--secondary2);
    border-top: 1px solid var(--secondary2);
    border-radius: 0px 2px 0px 0px;
    transform: rotate(-33deg);
}

.trBorder {
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0px;
    left: 8px;
    border-top: 1px solid var(--secondary2);
}

.locationTagContainer {
    width: 100%;
    position: relative;
    display: flex;
    height: 20px;
}

.locationTag {
    position: absolute;
    display: flex;
    max-width: calc(100% - 50px);
    margin-left: 25px;
    background-color: #cbe0ec;
    height: 17px;
    margin-top: 1px;
    border-top: 1px solid var(--secondary2);
    border-left: 1px solid var(--secondary2);
    border-right: 1px solid var(--secondary2);
    padding-left: 5px;
    padding-top: 1px;
    padding-right: 5px;
    border-radius: 5px 5px 0px 0px;
}
.locationLbl {
    display: block;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default;
}
