.conceptsContainer {
    margin-top: 10px;
}
.toggleCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.toggleContainer {
    margin-top: 2px;
    margin-left: 5px;
}
.conceptLabel {
    font-size: 20px;
    margin-left: 15px;
    font-weight: 600;
}

.conceptPickerContainer {
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 45px;
    width: 300px;
}

.conceptPropContainer {
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 60px;
    margin-right: 80px;
}
