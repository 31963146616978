.container {
    display: flex;
    flex-direction: column;
}

.editorContainer {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
    margin-bottom: 5px;
}

.headerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.footerContainer {
    margin-left: 20px;
    margin-right: 20px;
}

.btnLblContainer {
    max-width: calc(100% - 20px);
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 20px;
}

.toggleLabelContainer {
    display: flex;
    font-size: 15px;
    align-items: center;
    margin-left: 20px;
}

.toggleContainer {
    margin-left: 5px;
}

.clientContainer {
    display: flex;
    flex-direction: column;
}

.clientLbl,
.clientInfoLabel {
    font-size: 18px;
}
.clientLbl {
    margin-right: 5px;
}

.clientInfoContainer {
    margin-left: 50px;
    margin-bottom: 10px;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.clientInfoSelectorContainer {
    width: 200px;
    margin-left: 20px;
    font-size: 15px;
    max-width: 100%;
}

.recurringLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 20px;
}
.requestEditorContainer {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.lblCont {
    font-size: 18px;
}

.optionsContainer {
    display: flex;
    font-size: 20px;
    margin-left: 20px;
    flex-direction: column;
}
.optionsList {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.optionContainer {
    display: flex;
    flex-direction: column;
}
.optionContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.optionRadio {
    margin-right: 5px;
    margin-left: 10px;
}

.optionLabel {
    font-size: 16px;
}

.addOption {
    width: 150px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 35px;
    cursor: pointer;
}

.addIconContainer {
    font-size: 24px;
    height: 24px;
}

.addOptionLbL {
    font-size: 15px;
    color: var(--accent);
}

.btnsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    margin-left: 5px;
}

.deleteBtn {
    font-size: 22px;
    height: 21px;
    width: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greyFont);
    cursor: pointer;
}

.deleteBtn:hover {
    color: var(--dark-grey);
}

.hideBtn,
.conditionsBtn {
    fill: var(--greyFont);
    cursor: pointer;
}

.showBtn {
    fill: var(--error);
    cursor: pointer;
}

.hideBtn:hover,
.showBtn:hover,
.conditionsBtn:hover {
    fill: var(--dark-grey);
}

.optionClassifierInput {
    max-width: calc(100% - 147px);
    width: 200px;
    margin-left: 50px;
}
