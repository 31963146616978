.messageContainer {
    font-size: 15px;
}

.replyContent {
    margin: 0px 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.documentIcon {
    font-size: 34px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-bottom: 2px;
    color: var(--secondary);
}

.w1 {
    border-radius: 10px;
    background-color: var(--secondary2);
    height: 20px;
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
}
