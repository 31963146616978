.container {
    display: flex;
    position: relative;
    height: calc(100% - 30px);
    flex-direction: column;
    padding-top: 30px;
}
.overViewBtnContainer {
    top: 70px;
    position: absolute;
    height: 50px;
    width: 60px;
    background-color: var(--secondary2);
    font-size: 35px;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--accent);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-right: 10px;
}
.overViewBtnContainer:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
}
.prevBtn,
.nextBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 40px;
    top: calc(50% - 35px);
    background-color: var(--secondary2);
    font-size: 60px;
    color: var(--accent);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    cursor: pointer;
    overflow: hidden;
}
.prevBtn {
    left: 15px;
    border-radius: 40px 15px 15px 40px;
}

.nextBtn {
    right: 15px;
    border-radius: 15px 40px 40px 15px;
}
.prevBtn:disabled,
.nextBtn:disabled {
    background-color: var(--greyBtn);
    color: var(--greyFont);
    cursor: not-allowed;
}

.pageTitleCont {
    position: absolute;
    top: 0px;
}
.contentContainer {
    display: flex;
    height: fit-content;
    overflow-x: auto;
    overflow-y: scroll;
    padding-left: 30px;
    padding-right: 30px;
}
.stepsCard {
    background-color: var(--primary1);
    width: fit-content;
    min-height: calc(100vh - 54px - 63px - 30px - 70px);
    height: fit-content;
    border-radius: 20px;
    padding: 20px;
    padding-top: 40px;
    margin-bottom: 30px;
    margin-top: 23px;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 60px);
}
.stepsLayout {
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.addBtnContainer {
    margin-top: 10px;
    width: 100%;
}
