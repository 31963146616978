.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
.photoContainer {
    width: 240px;
    margin-left: -10px;
    margin-right: 5px;
    text-align: center;
}

.avatarContainer {
    width: min-content;
    margin: 0 auto;
}

.basicInfoContainer {
    flex: 1;
    padding: 15px;
    max-width: calc(100% - 30px);
}
.infoContainer {
    display: flex;
    width: 100%;
    column-gap: 20px;
    flex-wrap: wrap;
}

.emailContainer {
    display: flex;
    width: 300px;
    max-width: 100%;
    flex-direction: column;
}
.celContainer {
    display: flex;
    width: 300px;
    row-gap: 5px;
    min-height: 65px;
    max-width: 100%;
    flex-direction: column;
}

.label {
    margin: 0;
    font-size: 14px;
    margin-left: 5px;
    color: var(--secondary);
}

.valueContainer {
    margin-bottom: 10px;
    height: 29px;
    font-size: 20px;
}

.orgAreaContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    width: 300px;
    max-width: 100%;
}

.value {
    margin: 0;
    width: 100%;
    min-height: 30px;
    padding-left: 5px;
    margin-top: 2px;
    font-size: 20px;
    font-weight: 300;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subtitlesContainer {
    margin-top: 5px;
    margin-bottom: 40px;
    margin-right: auto;
    max-width: 100%;
}

.permissionListContainer {
    margin-top: 0px;
}

@media screen and (max-width: 576px) {
    .photoContainer {
        width: 100%;
    }
}

.nameLbl {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.nameContainer {
    font-size: 18px;
    margin-top: 5px;
}

.nameInput {
    margin-top: -5px;
    margin-left: -2px;
    font-family: inherit;
    font-weight: 600;
    padding-top: 0px;
    padding-bottom: 0px;
}

.jobInput {
    margin-top: -6px;
    margin-left: -3px;
    font-family: inherit;
    font-weight: 300;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
}

.identifierInput {
    margin-top: -6px;
    margin-left: -3px;
    font-family: inherit;
    font-weight: 300;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
}

.permissionsContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.projectPermsContainer {
    max-width: 100%;
}
.permissionsLbl {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    margin-right: auto;
    color: var(--secondary);
}

.orgLblName {
    font-size: 17px;
    margin-left: 10px;
    font-weight: 600;
}
.orgPermissionsList {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    margin-bottom: 10px;
}

.orgPermission {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}
.orgPermissionLbl {
    font-size: 15px;
}
.toggleContainer {
    margin-left: 3px;
}
.submitBtnContainer {
    margin-top: 20px;
    width: 150px;
    margin-bottom: 20px;
}

.submitBtn {
    border-radius: 10px;
    padding: 8px 10px;
    width: 100%;
    margin: 0 auto 0 auto;
    font-size: 14px;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    position: relative;
    background-color: var(--secondary);
    color: var(--primary1);
}
.submitBtn[disabled] {
    pointer-events: none;
    opacity: 0.5;
}

.loaderContainer {
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    position: absolute;
}

.permissionsDescr {
    font-size: 15px;
    margin-left: 10px;
    font-weight: 200;
    font-style: italic;
}

.vacationsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.vacationsLabel {
    margin: 0;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 8px;
    color: var(--secondary);
}

.replacement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    width: fit-content;
}

.replacementContainer {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 20px;
    width: 300px;
    max-width: 100%;
}

.transferContainer {
    padding-bottom: 10px;
}

.supportContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.supportLabel {
    font-size: 16px;
    margin-left: 5px;
    margin-right: 8px;
    color: var(--secondary);
}

.btnsContainer {
    z-index: 2;
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.delBtn {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-right: 5px;
}
