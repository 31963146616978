.container,
.fixedContainer {
    margin: 10px 0px 0px 25px;
    display: flex;
    flex-direction: column;
}
.route,
.fixedRoute {
    font-size: 14px;
}

.title,
.fixedTitle {
    height: 34px;
    font-size: 25px;
    font-weight: 600;
    margin: 0px;
}

@media screen and (max-width: 576px) {
    .container {
        margin: 0px 0px 0px 10px;
    }
    .route {
        font-size: 12px;
    }
    .title {
        font-size: 24px;
    }
}

@media screen and (max-height: 800px) {
    .container {
        height: 15px;
        min-height: 15px;
        margin: 0px;
    }
    .route,
    .title {
        display: none;
    }
}
