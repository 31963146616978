.container {
    min-height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.container:hover {
    background-color: var(--greyHover);
}
.avatarContainer {
    margin-left: 30px;
}
.nameLbl {
    cursor: pointer;
    font-size: 14px;
    margin-left: 8px;
    color: var(--accent);
}
.emailContainer {
    font-size: 12px;
    margin-left: 8px;
    color: var(--greyFont);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
}
.lblContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
}
