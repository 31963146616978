.container {
    display: flex;
    flex-direction: column;
}

.top {
    margin-top: 10px;
    height: 50px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
}

.valueContainer {
    position: relative;
    width: fit-content;
    font-size: 24px;
}

.nameLbl {
    padding-left: 8px;
    padding-right: 5px;
    cursor: pointer;
    font-size: 24px;
}

.nameInput {
    font-family: 'Nunito';
    min-width: 100px;
    font-size: 24px;
    margin: auto 0;
}

.decisionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 25px;
    width: fit-content;
    min-width: 785px;
    padding: 0px 30px;
}

.addBtn {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: var(--secondary2);
    color: var(--primary1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.addIconContainer {
    font-size: 38px;
    height: 38px;
}

.addBtnContainer {
    padding-bottom: 10px;
}

.elseContainer {
    width: 100%;
    padding: 12px 0px;
    border-radius: 10px;
    border: var(--secondary2) solid 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.labelOthers {
    font-size: 20px;
}

.decisionTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: var(--secondary2) solid 1px;
}

.descriptorTextField {
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(100% - 30px);
}
