.standardLayout {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 60px);
}
.mobileLayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--primary1);
    border-top: 2px solid var(--light-grey);
    min-height: calc(100% - 2px);
    overflow-y: auto;
}
.loaderContainer {
    background-color: var(--primary1);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
}
.iframeContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.mobileIframeContainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.visualizationCard {
    height: calc(100% - 20px);
    background-color: white;
    margin: 10px;
    padding-bottom: 6px;
    border-radius: 10px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    position: relative;
}

.iframeStyles {
    height: 100%;
    z-index: 1;
    border-radius: 10px;
    overflow-y: hidden;
    overflow-x: scroll;
}

.titleContainer {
    display: flex;
    flex-direction: row;
}

.mobileTabs {
    z-index: 2;
}
.tabs {
    margin: auto 0 0 5px;
    z-index: 2;
}

.vizTitle {
    z-index: 2;
    margin: 10px;
}
