.container {
    background-color: transparent;
    color: var(--accent);
    overflow-y: hidden;
    overflow-x: hidden;
}

.scrollContainer {
    width: fit-content;
    min-width: 600px;
    max-width: 80vw;
    min-height: 400px;
    max-height: 80vh;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow-y: scroll;
    overflow-x: hidden;
}

.top {
    margin-top: 10px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
}

.valueContainer {
    position: relative;
    width: fit-content;
    margin-left: 15px;
}

.nameLbl {
    padding-left: 8px;
    padding-right: 5px;
    cursor: pointer;
    font-size: 24px;
}

.nameInput {
    font-family: 'Nunito';
    min-width: 100px;
    font-size: 24px;
    margin: auto 0;
}

.editorContentContainer {
    height: 300px;
    position: relative;
    margin-left: 40px;
    margin-top: 20px;
    margin-right: 40px;
    padding-bottom: 10px;
    max-width: calc(100% - 80px);
    width: 700px;
}

.typeContainer {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin-left: 40px;
    align-items: center;
}
.typeSelectorContainer {
    width: 250px;
    margin-left: 5px;
}

.subjectContainer {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 15px;
}
.recipientsContainer {
    margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
}
.recipientsLbl {
    font-size: 20px;
}
.addBtn {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--secondary);
    color: var(--primary1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.addIconContainer {
    font-size: 30px;
    height: 30px;
}
