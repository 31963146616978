.emptyContainer {
    color: var(--accent);
    padding-top: 10px;
    font-size: 12px;
    padding-left: 12px;
    padding-bottom: 7px;
}

.assignBtnContainer {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--light-grey);
}

.listContainer {
    overflow-y: auto;
    max-height: 255px;
}
