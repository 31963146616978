.DraftEditor-root {
    height: unset;
}

.rdw-option-wrapper {
    border-radius: 7px;
    background-color: transparent;
    border: 1px solid var(--outlineGrey);
}
.rdw-option-wrapper:active {
    border: 1px solid var(--secondary);
}
.rdw-option-wrapper:hover {
    box-shadow: none;
    border: 1px solid var(--secondary);
}

.rdw-option-active {
    border: 1px solid var(--secondary);
    box-shadow: none;
}

.rdw-editor-toolbar {
    border-radius: 15px 15px 0 0;
    border: none;
    margin-bottom: 0px;
    border-bottom: 1px solid var(--outlineGrey);
    background-color: transparent;
}

.Erk-TextEditorContainer .DraftEditor-root {
    cursor: text;
    padding: 12px 10px;
}

.Erk-TextEditorContainer div[contenteditable='false'] {
    pointer-events: none;
}

.Erk-TextEditorContainer.Erk-TextEditor-BottomPadding .DraftEditor-root {
    padding: 12px 10px 40px 10px;
}
.Erk-TextEditorContainer.Erk-TextEditor-Picker,
.Erk-TextEditorContainer.Erk-TextEditor-Input {
    .Erk-TextEditor {
        display: flex;
        align-items: center;
        height: 100% !important;
        overflow-y: hidden !important;
        overflow: hidden;
    }
    .DraftEditor-root {
        padding: 0px;
    }
    .public-DraftStyleDefault-block {
        white-space: nowrap;
    }
    .rdw-editor-toolbar {
        display: none;
    }
    .rdw-editor-wrapper {
        padding: 2px 12px 2px 12px;
    }

    height: 30px;
    font-size: 16px;
    line-height: 25px;
}

.Erk-TextEditorContainer.Erk-TextEditor-Picker {
    cursor: pointer;
}

.public-DraftStyleDefault-block {
    margin: 0px 0;
    max-width: 100%;
}
.public-DraftEditorPlaceholder-root {
    padding-left: 2px;
}

.rdw-image-imagewrapper {
    max-width: 100%;
    /* cursor: pointer; */
    position: relative;
}

.rdw-image-alignment-editor {
    padding-right: 10px;
    padding-left: 5px;
    margin-left: -5px;
}
.rdw-editing-image {
    border: 1px solid var(--secondary);
}

.DraftEditor-root figure {
    margin: 0px;
}

.rdw-image-alignment-option {
    font-size: 16px !important;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    border-radius: 2px;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--light-grey);
}

.rdw-image-alignment-options-popup {
    width: fit-content;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
}

/* Hides empty placeholder */
.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}
