.optionsNav {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 2px solid var(--light-grey);
    width: fit-content;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 3;
    background-color: white;
    width: 95%;
    height: 30px;
    border-top: 2px solid white;
}
.optionsNav {
    scrollbar-width: thin;
    scrollbar-color: var(--defaultGrey) transparent;
}

.formName,
.selectedFormName {
    width: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    padding: 5px 10px;
    border-top: 2px solid white;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}
.formName {
    margin-left: 2px;
    margin-right: 2px;

    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
    background-color: white;
}

.selectedFormName {
    border-top: 2px solid var(--light-grey);
    border-left: 2px solid var(--light-grey);
    border-right: 2px solid var(--light-grey);
    background-color: white;
}
