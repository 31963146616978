.rightPanel {
    background-color: var(--secondary);
    display: flex;
    border-radius: 0 20px 20px 0;
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
}
.rightPanelMessage {
    margin: auto;
    text-align: center;
    padding: 0 30px 0 30px;
}
.rightPanelMessage h1 {
    color: white;
    font-size: 40px;
    font-weight: 900;
}
.rightPanelMessage p {
    color: white;
}
.leftPanel {
    display: flex;
    text-align: center;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    min-height: fit-content;
    flex-basis: 0;
    position: relative;
    justify-content: center;
}
.leftPanel h1 {
    margin-left: auto;
    margin-right: auto;
    color: var(--secondary);
    font-size: 40px;
    font-weight: 900;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
}
.leftPanel a {
    font-size: 22px;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0px auto 25px auto;
    text-decoration: underline;
}
.leftPanel p {
    padding: 0px 10px;
}
.loginButton {
    background-color: var(--secondary);
    border-radius: 40px;
    margin-bottom: 40px;
    padding: 22px 50px;
    margin: 15px auto 0 auto;
    color: white;
    font-weight: 800;
    font-size: 22px;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: fit-content;
    max-width: calc(100% - 40px);
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loginButton[disabled] {
    pointer-events: none;
    opacity: 0.5;
}
.loaderContainer {
    position: absolute;
    margin: auto;
}
.logo {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 40px;
    top: 33px;
    height: 65px;
    margin-right: auto;
}
.loginInputsContainer {
    width: 60%;
    margin: 0 auto 0 auto;
}
.loginInput {
    min-height: 76px;
}
@media screen and (max-width: 825px) {
    .rightPanel {
        display: none;
    }
    .loginInputsContainer {
        width: 70%;
        margin: 0 auto 0 auto;
    }
    .leftPanel h1 {
        margin-top: 5%;
    }
}

@media screen and (max-width: 678px) {
    .logo {
        display: none;
    }
    .loginInputsContainer {
        width: 80%;
    }
}

@media screen and (max-height: 620px) {
    .logo {
        display: none;
    }
}
