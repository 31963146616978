.container {
    width: 100%;
    height: fit-content;
    position: relative;
}

.disabledCurtain {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 5;
}
