.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin-right: 5px;
    max-width: calc(100% - 5px);
    margin-top: 2px;
}
.fileName {
    color: var(--accent);
}
.largeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    width: fit-content;
}

.largeContainer .documentIcon {
    font-size: 34px;
    width: 32px;
    height: 30px;
}

.documentIcon {
    font-size: 1.5rem;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.label,
.downloadableLbl {
    font-size: 1rem;
    margin-left: 2px;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.downloadableLbl {
    cursor: pointer;
}
.downloadableLbl:hover {
    text-decoration: underline;
}

.largeContainer .label,
.largeContainer .downloadableLbl {
    font-size: 18px;
    margin-left: 5px;
}
.deleteBtn {
    font-size: 26px;
    height: 26px;
    margin-left: 5px;
    color: '#787878';
    cursor: pointer;
}
.editBtn {
    font-size: 22px;
    height: 22px;
    margin-left: 3px;
    margin-bottom: 4px;
    color: '#787878';
    cursor: pointer;
}
.loaderContainer {
    position: relative;
    height: 26px;
    width: 26px;
    overflow: hidden;
}
.waitingForUpload {
    height: 22px;
    width: 22px;
    border: 2px solid #787878;
    border-radius: 20px;
    margin-left: 0px;
}

.iconContainer {
    height: 30px;
    width: 26px;
    margin-left: 5px;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorMsg {
    font-size: 12px;
    color: var(--error);
    margin-left: 15px;
    margin-right: 10px;
    margin-bottom: 2px;
}

.contContainer {
    max-width: 100%;
}

.iconsContainer {
    padding-top: 10px;
    margin-top: -10px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}
