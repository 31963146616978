.dialogsLabel {
    font-size: 18px;
    margin-left: 15px;
    font-weight: 600;
    margin-top: 10px;
}

.dialogsContainer {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.addDialog {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.addIconContainer {
    font-size: 28px;
    height: 28px;
}

.addDialogLbL {
    font-size: 16px;
    color: var(--accent);
}
