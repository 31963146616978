.container {
    height: 100%;
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}
.mapContainer {
    height: 50vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}
.helpMessage {
    position: absolute;
    font-size: 18px;
    bottom: -5px;
    color: var(--accent);
}
.detailContainer {
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--primary1);
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    padding-left: 30px;
    width: calc(100% - 110px);
    min-height: calc(50vh - 170px);
}

.whiteCurtain {
    position: absolute;
}
