.ratingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.disabledSentimentContainer,
.sentimentContainer {
    font-size: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: var(--eureka-outline);
}
.disabledLikeContainer,
.likeContainer {
    font-size: 60px;
    height: 75px;
    max-height: 100%;
    display: flex;
    align-items: center;
    color: var(--eureka-outline);
}

.likeContainer:hover,
.sentimentContainer:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: var(--eureka-primary);
}

.hiddenInput {
    height: 0;
    width: 0;
    padding: 0;
    border-width: 0;
}

.scaleContainer {
    height: 60px;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
