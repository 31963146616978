.standard-layout {
    display: flex;
    flex-flow: column;
    width: calc(100vw - 50px);
    overflow-x: hidden;
}

.whiteCurtain {
    position: absolute;
    height: calc(100% - 54px);
    width: 100%;
    display: flex;
    flex-flow: column;
    z-index: 6;
    background-color: white;
}

.standard-layout .filters-container {
    margin-top: 3px;
    min-height: 28px;
    padding: 0 25px;
    gap: 10px;
    height: min-content;
}

.standard-layout .filters-container .search-container {
    flex: 1;
    min-width: 100px;
    max-width: 100%;
}

@media screen and (max-width: 768px) {
    .standard-layout {
        width: 100vw;
    }
}

@media screen and (min-width: 577px) and (max-width: 1279px) {
    .standard-layout .filters-container {
        padding: 0px 25px 0 25px;
    }
}

@media screen and (max-width: 576px) {
    .standard-layout .filters-container {
        padding: 0 10px;
    }
}
