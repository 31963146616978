.container {
    background-color: transparent;
    color: var(--accent);
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 25px;
}
.scrollContainer {
    min-width: 500px;
    min-height: 400px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.top {
    margin-top: 10px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
}

.valueContainer {
    position: relative;
    width: fit-content;
    margin-left: 15px;
}

.nameLbl {
    padding-left: 8px;
    padding-right: 5px;
    cursor: pointer;
    font-size: 24px;
}

.nameInput {
    font-family: 'Nunito';
    min-width: 100px;
    font-size: 24px;
    margin: auto 0;
}

.typeContainer {
    margin-left: 20px;
    width: fit-content;
    display: flex;
    min-width: 216px;
}

.automaticContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.contentContainer {
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
}

.classifierPicker {
    width: fit-content;
    display: flex;
    width: calc(100% - 30px);
}

.channelContainer {
    margin-top: 10px;
    margin-right: 40px;
}

.whatsappEntryContainer {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 30px;
}
.numPickerContainer {
    margin-right: 20px;
    height: 40px;
    width: 140px;
    display: flex;
}

.propertyPickerContainer {
    width: 240px;
    margin-left: 0px;
    max-width: 100%;
}

.areaContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.label {
    font-size: 15px;
    width: 155px;
    padding-left: 8px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.iconContainer {
    color: var(--secondary);
    font-size: 26px;
    height: 28px;
}

.surveyPicker {
    width: 240px;
    margin-left: -2px;
}
.processContainer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-bottom: 20px;
    width: 100%;
    align-items: center;
}
