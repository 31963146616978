.topContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top: 5px;
}

.interaction {
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    overflow: hidden;
}

.interactionImg {
    height: 38px;
    width: 38px;
}

.numContainer {
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}
.numLbl {
    margin-left: 10px;
    font-size: 17px;
    font-weight: bolder;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
}
