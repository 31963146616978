.container {
    padding: 5px;
    display: flex;
    flex-direction: row;
}

.agentsRemovedContainer {
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
}

.arrowContainer {
    font-size: 40px;
    margin-top: 0px;
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.newAgentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    min-width: 130px;
}
.nameLbl {
    font-size: 14px;
    margin-left: 4px;
    color: var(--accent);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
