.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bullet {
    border: 2px solid var(--greyFont);
    background-color: var(--light-grey);
    height: 12px;
    width: 12px;
    border-radius: 20px;
    margin-right: 10px;
    margin-left: 10px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}
.typeContainer {
    height: 56px;
    width: 150px;
}

.removeContainer {
    margin-left: auto;
    min-width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.removeConditionContainer {
    height: 26px;
    min-width: 26px;
    margin-left: auto;
    border-radius: 20px;
    border: 3px solid var(--secondary);
    font-size: 20px;
    color: var(--secondary);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.removeConditionContainer:hover {
    color: var(--error);
    border: 3px solid var(--error);
}

.valueContainer {
    flex-grow: 1;
    margin-left: 20px;
}
.clientContainer {
    margin-left: 16px;
}
