.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.header {
    font-weight: bold;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
}

.container ul {
    margin-inline-start: 10px !important;
    margin-block-start: 3px !important;
    margin-block-end: 3px !important;
    padding-inline-start: 20px !important;
}

.label {
    font-weight: bold;
    margin-right: 5px;
}

.value {
    line-height: 22px;
    cursor: default;
}
