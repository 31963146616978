.entityLbl {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 600;
}

.container {
    gap: 10px;
}
.info {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px 20px;
    margin-left: 10px;
}

.filterTypeSelector {
    width: 320px;
}
.entityValueInput {
    width: 320px;
}
