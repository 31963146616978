.container {
    display: flex;
    flex-direction: row;
}

.infoContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    margin-left: 20px;
}
.infoContainerContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.mobileInfoContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 5px;
}

.titleContainer {
    width: 100%;
    position: relative;
    height: 30px;
    margin-top: 17px;
}
.mobileTitleContainer {
    margin-top: 0px;
    min-height: 37px;
    margin-left: 25px;
    display: flex;
    margin-bottom: 5px;
    max-width: calc(100% - 45px);
}

.lblSubject {
    color: var(--accent);
    font-size: 24px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.mobileLblSubject {
    color: var(--accent);
    font-size: 20px;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 24px;
    margin-top: auto;
    margin-bottom: auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.subTitleContainer {
    display: flex;
    flex-direction: row;
    height: 28px;
    margin-top: -4px;
    width: 100%;
}

.mobileSubTitleContainer {
    display: flex;
    flex-direction: row;
    min-height: 28px;
    margin-top: -4px;
    width: 100%;
    align-items: center;
    margin-bottom: 2px;
    max-width: calc(100% - 20px);
    overflow: hidden;
}

.subTitleInfoContainer {
    padding-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 18px;
}

.mobileSubTitleInfoCont {
    width: fit-content;
}

.subTitleInfo {
    font-size: 13px;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
}
.mobileSubTitleInfo {
    font-size: 13px;
    width: fit-content;
}

.entry,
.user {
    cursor: pointer;
}
.entry {
    white-space: nowrap;
}
.entry:hover,
.user:hover {
    text-decoration: underline;
}

.subTitleBtnsContainer {
    display: flex;
    flex-direction: row;
    padding-top: 2px;
    padding-left: 15px;
    height: 23px;
}
.attachmentsBtn {
    cursor: pointer;
    height: 20px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.attachmentsBtnLbl {
    font-size: 16px;
    margin-right: 2px;
}
.attachmentsIcon {
    transform: rotate(-90deg);
    margin-top: 1px;
}

.btnsContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 30px;
}
