.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 4px;
}

.arrowContainer {
    height: 22px;
    margin-top: -5px;
    font-size: 40px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 3px;
}

.pillContainer {
    min-width: 0px;
    position: relative;
    display: flex;
    width: fit-content;
    margin-bottom: 5px;
    flex-direction: row;
    max-width: 100%;
    display: inline;
}

.trashContainer {
    position: absolute;
    width: 100%;
    top: 0px;
    height: 22px;
    border-radius: 10px;
    font-size: 24px;
    background-color: rgba(128, 128, 128, 0.7);
    color: var(--error);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
