.container {
    background-color: var(--primary1);
    transform: scale(1.8);
    height: 160px;
    width: 120px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.container:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
}

.title {
    font-size: 10px;
    margin-top: 7.5px;
    margin-left: 10px;
    color: var(--accent);
    font-weight: 600;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.fakeTxtInputLarge {
    border-radius: 5px;
    background-color: var(--secondary2);
    width: 80px;
    opacity: 0.8;
    margin-left: 8px;
    height: 10px;
    margin-bottom: 3px;
}

.fakeTxtInputSmall {
    border-radius: 5px;
    background-color: var(--secondary2);
    opacity: 0.8;
    margin-left: 8px;
    height: 10px;
    width: 60px;
    margin-bottom: 3px;
}

.doubleInputContainer {
    display: flex;
    flex-direction: row;
    margin-left: 3px;
}

.fakeTxtInputXSmall {
    border-radius: 5px;
    background-color: var(--secondary2);
    opacity: 0.8;
    margin-left: 5px;
    height: 10px;
    width: 40px;
    margin-bottom: 3px;
}

.fakeTextArea {
    margin-top: 5px;
    border-radius: 5px;
    background-color: var(--light-grey);
    width: 104px;
    height: 50px;
    margin-left: 8px;
}
