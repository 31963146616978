.container {
    position: relative;
    background-color: var(--primary1);
    height: 90px;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 25px;
    display: flex;
    cursor: pointer;
    max-width: 100%;
}

.info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: calc(100% - 80px);
}

.title {
    margin: 10px 0 0 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    height: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 10px);
    margin-bottom: 2px;
}

.infoLbl {
    margin-top: 3px;
    font-size: 14px;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.photo {
    margin: auto 15px;
}

.sideBar {
    position: absolute;
    background-color: var(--secondary);
    height: 90px;
    width: 10px;
    border-radius: 10px 0 0 10px;
}

.email {
    margin: 10px 0 0 20px;
    font-size: 14px;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .container {
        margin-bottom: 15px;
    }
}
