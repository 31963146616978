.emptyContainer {
    color: var(--accent);
    padding-top: 10px;
    font-size: 12px;
    padding-left: 12px;
    padding-bottom: 7px;
}

.transferBtnContainer {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--light-grey);
}

.transferBtn {
    background-color: var(--secondary);
    font-size: 14px;
    color: white;
    border-radius: 3px;
    height: 26px;
    width: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.listContainer {
    overflow-y: auto;
    max-height: 255px;
}
