.emptyContainer {
    padding-top: 5px;
}
.mobileContainer {
    height: 80vh;
    background-color: transparent;
}
.container {
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    overflow: hidden;
    min-width: 300px;
    max-width: 90vw;
    position: relative;
    z-index: 20;
    background-color: white;
}
.filtersHeaderContainer {
    display: flex;
    border-bottom: 1px solid var(--light-grey);
}
.roundSelect {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 260px;
    margin-right: 10px;
    margin-left: 2px;
}
.title {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 6px;
    font-size: 15px;
    font-weight: 600;
    color: var(--accent);
}
.closedCases {
    margin-left: auto;
    font-size: 14px;
    font-weight: 600;
    color: var(--accent);
    margin-right: 5px;
}
.toggleContainer {
    margin-top: 1px;
    margin-right: 5px;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    align-items: center;
}

.tabsContainer {
    display: flex;
    height: 18px;
    padding-left: 8px;
    padding-right: 20px;
    flex-direction: row;
    border-bottom: 1px solid var(--light-grey);
}
.option,
.selectedOption {
    font-size: 12px;
    height: 17px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid var(--primary1);
    position: relative;
    border-bottom: 1px solid var(--light-grey);
    background-color: transparent;
    cursor: pointer;
}

.selectedOption {
    border-top: 1px solid var(--light-grey);
    border-left: 1px solid var(--light-grey);
    border-right: 1px solid var(--light-grey);
    background-color: var(--primary1);
}

.countContainer {
    width: 26px;
    height: 20px;
    top: -9px;
    right: -8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
}
.countLbl {
    font-size: 6px;
    background-color: var(--contrast);
    font-weight: 600;
    color: white;
    padding-top: 1px;
    min-width: 5px;
    text-align: center;
    padding-bottom: 1px;
    border-radius: 20px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
}

.statePickerContainer {
    margin-top: 1px;
    margin-right: 5px;
    height: 22px;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
}
