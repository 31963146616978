.container {
    padding: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    width: calc(100% - 15px);
}

.arrowContainer {
    height: 40px;
    margin-top: 5px;
    font-size: 40px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 3px;
    overflow: hidden;
}

.changeContainer {
    min-width: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    flex-direction: row;
    max-width: 100%;
}
.processContainer {
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: fit-content;
}

.nameLbl {
    font-size: 14px;
    margin-left: 4px;
    color: var(--accent);
}

.rolesContainer {
    margin-left: 5px;
    margin-right: 5px;
}
.roleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    gap: 5px;
    height: 25px;
}
.roleAgentsContainer {
    margin-left: 5px;
}
.roleLbl {
    font-size: 14px;
}
.loaderContainer {
    position: relative;
    height: 20px;
    width: 20px;
}
.originalRole {
    display: flex;
    flex-direction: row;
}

.roleArrowContainer {
    height: 20px;
    width: 28px;
    margin-left: 2px;
    font-size: 30px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.assignedLbl {
    cursor: default;
    margin-top: 9px;
    height: 10px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--secondary);
}
.processContainer {
    position: relative;
}
.removedContainer {
    position: absolute;
    width: 100%;
    top: 0px;
    height: 30px;
    border-radius: 20px;
    padding-right: 3px;
    font-size: 30px;
    color: var(--error);
    background-color: rgba(128, 128, 128, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.removedContainer:hover {
    background-color: transparent;
    font-size: 0px;
}

.formContainer {
    margin-top: -15px;
    margin-bottom: -10px;
    margin-left: -5px;
    margin-right: -5px;
}
