.typeContainer {
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-left: 20px;
    margin-top: 10px;
}

.container {
    display: flex;
    flex-direction: column;
}

.editorContainer {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
    margin-bottom: 5px;
}

.passthroughHelperMessage {
    font-size: 15px;
    margin-left: 20px;
    font-weight: 400;
    font-style: italic;
}
.headerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.footerContainer {
    margin-left: 20px;
    margin-right: 20px;
}

.toggleLabelContainer {
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.toggleContainer {
    margin-left: 5px;
}

.btnLblContainer {
    max-width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 20px;
}

.templateComponentsContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}
