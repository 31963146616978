.container {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    /* min-height: 50vh; */
    max-width: 100%;
    /* width: 600px; */
    max-width: 80vw;
    position: relative;
}
.exitBtn {
    position: absolute;
    font-size: 60px;
    cursor: pointer;
    right: 5px;
    top: 5px;
}

.title {
    margin-top: 15px;
    font-size: 23px;
}
.helperText {
    font-size: 14px;
    margin-top: 3px;
}
.draftContainer {
    margin-top: 10px;
    font-size: 16px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
}

@media screen and (max-width: 769px) {
    .draftContainer {
        width: calc(100% - 20px);
        margin-left: 10px;
        margin-right: 10px;
    }
}
