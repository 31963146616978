.payloadContainer {
    margin: 10px 25px 5px 25px;
}

.revisionTop {
    margin-top: 10px;
}

.agentContainer {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.agentLabel {
    font-size: 16px;
    text-align: center;
    cursor: default;
}

.btnsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 20px;
    row-gap: 10px;
    column-gap: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.revisionComment {
    margin: 0;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 14px;
}
