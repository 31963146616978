.container {
    background-color: var(--primary2);
    border-bottom: var(--greyBtn) solid 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 2px;
    cursor: pointer;
}

.nameLBl,
.selectedLbl {
    font-size: 13px;
    color: var(--dark-grey);
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.selectedLbl {
    /* text-decoration: underline; */
}

.dropDownBtn {
    font-size: 28px;
    height: 24px;
    width: 28px;
    overflow: hidden;
    margin-top: -4px;
    color: var(--dark-grey);
    margin-left: auto;
    margin-right: 8px;
}
.search {
    background-color: var(--secondary2);
    border-radius: 3px;
}
.checkIcon {
    height: 18px;
    width: 12px;
    font-size: 17px;
    margin-left: 6px;
    margin-right: 8px;
}
