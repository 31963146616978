.container {
    background-color: white;
    border-radius: 10px 10px 0 0;
    height: 100%;
    position: relative;
    margin-left: 25px;
}
.treeContainer {
    overflow-y: scroll;
    height: calc(100%);
    margin-right: -4px;
}
.treeHr {
    margin: 0px;
    border-top: var(--light-grey) solid 1px;
}
.loaderContainer {
    position: relative;
    height: 100%;
}
.loader {
    margin: auto;
    width: fit-content;
}

.orderableBtnContainer {
    width: 80px;
    height: 40px;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    top: -15px;
    right: 15px;
}
.orderablePadding {
    height: 20px;
    width: 100%;
}

.orderBtn {
    width: 35px;
    margin-right: 2.5px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.emptyTree {
    display: flex;
    padding-top: 20px;
}
@media screen and (max-width: 576px) {
    .container {
        margin-left: 0px;
        margin-top: 15px;
    }
}
