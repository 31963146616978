.container {
    overflow-x: hidden;
}

.specialMessageContainer {
    height: 100%;
    width: 100%;
}

.specialMessage {
    margin: auto;
}

@media screen and (max-width: 576px) {
    .container {
        width: calc(100% - 60px);
        padding: 20px 30px 0 30px;
    }
}

.containerLoader {
    height: calc(100% - 10px);
    position: relative;
}

/* TODO quitar todo esto */
.flowContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.flowTitle {
    font-size: 26px;
    margin-left: 150px;
}

.flowFoto {
    width: 500px;
    margin-top: 5px;
    max-height: auto;
}

.flowBtn {
    margin-left: 170px;
    margin-top: 20px;
}
