.container {
    margin: 25px;
    margin-bottom: 30px;
    width: fit-content;
    min-width: 400px;
}
.topContainer {
    width: fit-content;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.labelContainer {
    font-size: 22px;
    padding-left: 10px;
    font-weight: 600;
}
.conditionBtn {
    fill: var(--light-grey);
    cursor: pointer;
    margin-left: auto;
}
.conditionBtn:hover {
    fill: var(--dark-grey);
}
.conditionContainer {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.conditionLbl {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    column-gap: 5px;
}
.fallbackContainer {
    padding-bottom: 20px;
}
.fallbackInputContainer {
    padding-top: 15px;
    width: 100%;
}
.bottomContainer {
    display: flex;
    flex-direction: row;
}
.prefixContainer {
    width: 200px;
    display: flex;
    flex-direction: column;
}
.prefixLblContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    column-gap: 5px;
}

.prefixInputContainer {
    margin-top: 15px;
    width: 150px;
}

.contentContainer {
    margin-bottom: 30px;
}

.daysContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.dayContainer {
    width: 100px;
    margin-right: auto;
    margin-left: auto;
}

.workingContainer {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-bottom: 30px;
    font-size: 20px;
}
