.messageBtn {
    background-color: var(--greyHover);
    border-radius: 10px;
    border: 1px solid var(--greyBtn);
    padding: 6px 13px;
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    column-gap: 3px;
    text-overflow: ellipsis;
    position: relative;
}
