.container {
    background-color: var(--contrast);
    padding: 4px 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-width: 140px;
    min-height: 46px;
    max-width: 200px;
}

.top {
    height: 18px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.title {
    width: 100%;
    font-size: 9px;
    margin-top: 3px;
    margin-left: 1px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--primary1);
}
.config {
    height: 12px;
    width: 12px;
    margin-top: 0px;
    margin-right: 1px;
    margin-left: auto;
    font-size: 13px;
    color: var(--primary1);
    cursor: pointer;
}

.contentContainer {
    flex-grow: 1;
    margin-left: 5px;
    width: calc(100% - 5px);
}

/* This is so that the node shows a border and doesn't change positions when it is selected */
:global(.selected) .container {
    border: var(--accent) 1px solid;
    margin-top: -1px;
    margin-left: -1px;
}

:global(.selected) .leftHandle {
    margin-top: -1px;
}

:global(.selected) .rightHandle {
    margin-top: -1px;
    margin-right: 1px;
}

.typeLbl {
    font-size: 8px;
    color: var(--accent);
}
.leftHandle::before,
.rightHandle::before {
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    height: 16px;
    width: 16px;
    border-radius: 10px;
}
