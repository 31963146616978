.formDialogContainer {
    padding: 20px;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerLbl {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}
