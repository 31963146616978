.container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-width: 300px;
}

.label {
    font-size: 16px;
    margin-top: 0px;
}

.inputContainer {
    height: 50px;
}

.containerSubmitBtn {
    margin-left: auto;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 5px;
}

.title {
    margin-left: auto;
    margin-right: auto;
    font-size: 22px;
    margin-bottom: 15px;
}
