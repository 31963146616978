.container {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    margin-bottom: 5px;
}

.nameLbl {
    font-size: 14px;
    margin-left: 4px;
}

.trashContainer {
    position: absolute;
    width: 100%;
    top: 0px;
    height: 30px;
    border-radius: 20px;
    padding-right: 3px;
    font-size: 30px;
    color: var(--error);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
