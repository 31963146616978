.property {
    padding-left: 0px;
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
}

.icon {
    width: 40px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    margin-left: 7px;
    color: var(--secondary);
}
.nameLbl {
    font-size: 26px;
    margin-left: 2px;
    height: 32px;
    width: 26px;
    color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.textInputContainer {
    border-radius: 5px;
    width: 30px;
    height: 20px;
    border: 2px solid var(--secondary);
    font-size: 16px;
    padding-left: 5px;
}

.selectInputContainer {
    position: relative;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid var(--secondary);
    font-size: 18px;
    font-weight: 600;
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--secondary);
}

.dateInputContainer {
    border-radius: 5px;
    width: 20px;
    height: 17px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 16px;
    font-weight: 600;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 15px;
}

.agentInputContainer {
    border-radius: 5px;
    width: 33px;
    height: 17px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 19px;
    font-weight: 600;
    padding-top: 0px;
    padding-bottom: 3px;
    padding-left: 3px;
}

.textAreaContainer {
    position: relative;
    border-radius: 5px;
    background-color: var(--light-grey);
    width: 20px;
    height: 20px;
    border: 2px solid var(--dark-grey);
    font-size: 24px;
    margin-left: 1px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--dark-grey);
}

.sincoIcon {
    margin-right: 7px;
    margin-left: 7px;
    height: 20px;
    width: 20px;
    color: var(--secondary);
}

.sincoImg {
    display: flex;
    height: inherit;
    width: inherit;
}

.entityIcon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    margin-left: 7px;
    color: var(--secondary);
}

.listIconContainer {
    font-size: 26px;
    margin-left: 5px;
    height: 32px;
    width: 26px;
    color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btnIcon {
    width: 15px;
    height: 5px;
    margin-top: 4px;
    margin-bottom: 7px;
    border-radius: 2px;
    margin-right: 1px;
    margin-left: 5px;
    background-color: var(--secondary);
}

.creatableIcon {
    font-size: 30px;
    margin-left: 24px;
    height: 32px;
    width: 26px;
    color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}