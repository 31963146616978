.container {
    background-color: var(--primary1);
    border-radius: 0px;
    max-height: 400px;
    width: 240px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    overflow: hidden;
    margin-left: 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}

.cbrIcon {
    margin-left: 10px;
    margin-right: 4px;
    height: 24px;
    width: 24px;
    color: var(--secondary);
}

.cbrImg {
    display: flex;
    height: inherit;
    width: inherit;
}

.bottomLeftPadding {
    width: 10px;
    height: 40px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    cursor: pointer;
    z-index: 4000;
}

.inputContainer {
    margin-left: 17px;
    width: 32px;
    height: 19px;
    display: flex;
    color: var(--secondary);
    align-items: center;
    font-size: 24px;
}
.iconContainer {
    height: 24px;
    width: 24px;
}
.textInputContainer {
    margin-left: 12px;
    margin-right: 2px;
    width: 35px;
    height: 22px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: var(--secondary);
}
