.container {
    width: 450px;
}

.message {
    font-size: 17px;
    text-align: center;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
.btnContainer {
    margin-top: 20px;
    margin-bottom: 10px;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-wrap: wrap;
    justify-content: center;
}

.btn {
    margin: 10px 5px 0px 5px;
}

.nameSpan {
    margin-left: 5px;
    font-weight: bold;
}
