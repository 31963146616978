.container {
    width: 400px;
    height: 400px;
    padding: 20px;
}
.valueContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}
.valueLabel {
    font-size: 20px;
}
.pickerContainer {
    margin-top: 10px;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
}

.configBtn {
    width: 30px;
    height: 30px;
    font-size: 23px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    font-size: 20px;
    border: solid 1px var(--primary2);
}

.configBtn:hover {
    border: solid 1px var(--light-grey);
}

.configIcon {
    height: 23px;
    width: 23px;
    fill: var(--greyFont);
}
