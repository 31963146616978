.container {
    min-height: 700px;
    max-height: 80vh;
    max-width: 80vw;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerLbl {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}

.formNotFound {
    margin-top: 30px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    width: 600px;
    height: 500px;
}

.widgetContainer {
    height: fit-content;
    width: fit-content;
    max-width: calc(100% - 80px);
    width: 980px;
    padding: 20px;
    padding-bottom: 0px;
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 90vw;
        min-height: 60vh;
        max-height: 80vh;
    }

    .widgetContainer {
        padding: 0px;
        max-width: 100%;
    }
}
