.container {
    border-radius: 30px;
    background-color: var(--accent);
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--primary1);
    font-size: 40px;
}

.rightHandle::before {
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    height: 16px;
    width: 16px;
    border-radius: 10px;
}

:global(.selected) .container {
    border: var(--contrast) 1px solid;
    margin-top: -1px;
    margin-left: -1px;
}
