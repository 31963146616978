.fullContainer {
    display: flex;
    position: relative;
    flex-direction: column;
}

.container {
    display: flex;
    width: 100%;
}

.notificationIcon {
    position: absolute;
    right: 2px;
    top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--contrast);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    min-width: 14px;
    text-align: center;
    padding: 0 3px;
    z-index: 4;
}

.cascadeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.box {
    width: 95%;
    border-radius: 0 0 10px 10px;
    height: 4px;
    background: #637691;
}

.box:nth-child(2) {
    width: 90%;
    background: #3d5371;
}

.box:nth-child(3) {
    width: 85%;
    background: #29374c;
}
