.container {
    border-top: 1px solid var(--light-grey);
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 220px;
}

.mobileContainer {
    border-top: 1px solid var(--light-grey);
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(80vh - 100px);
}

.emptyContainer {
    border-top: 1px solid var(--light-grey);
    color: var(--accent);
    padding-top: 10px;
    font-size: 12px;
    padding-left: 12px;
    padding-bottom: 5px;
}

@media screen and (min-height: 620px) and (max-height: 799px) {
    .container {
        max-height: calc(100vh - 470px);
    }
}

@media screen and (min-height: 800px) and (max-height: 899px) {
    .container {
        max-height: calc(100vh - 500px);
    }
}

@media screen and (min-height: 900px) {
    .container {
        max-height: 400px;
    }
}
