.standard-dialog {
    padding: 10px 30px;
    max-width: calc(100% - 60px);
}

.standard-dialog h2 {
    font-size: 22px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 25px;
}

.standard-dialog h3 {
    font-size: 22px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 15px;
}

.standard-dialog .text-field-container,
.standard-dialog .info-field-container {
    min-height: 55px;
    width: 100%;
}

.standard-dialog .button {
    display: inline;
    margin: 0 5px;
    width: min-content;
}
