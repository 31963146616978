.container {
    max-width: 80vw;
    min-height: 50vh;
    max-height: 60vh;
    overflow-y: auto;
}

.entityContainer {
    padding-bottom: 20px;
    width: fit-content;
    max-width: 100%;
}

.title {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 22px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
