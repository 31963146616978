.link {
    font-family: 'Nunito';
    font-size: 18px;
    width: 100%;
}

.text {
    padding-left: 10px;
    padding-right: 10px;
}

.notificationIcon {
    position: absolute;
    right: 3px;
    top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--contrast);
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    min-width: 14px;
    text-align: center;
    padding: 0 3px;
    z-index: 4;
}