.container {
    background-color: var(--primary1);
    max-height: 400px;
    height: fit-content;
    width: 240px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    overflow-y: auto;
    font-size: 18px;
}

.option {
    border-bottom: 1px solid var(--outlineGrey);
    min-height: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.option:hover {
    background-color: var(--light-grey);
}

.optionDisabled {
    border-bottom: 1px solid var(--outlineGrey);
    background-color: var(--greyHover);
    height: 39px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: default;
    overflow: hidden;
}

.titleIcon {
    margin-left: 15px;
    height: 30px;
    width: 30px;
    font-size: 30px;
    color: var(--secondary);
}

.optionLabel {
    margin-left: 8px;
    margin-right: 5px;
    font-size: 18px;
    color: var(--accent);
}

.icon {
    color: var(--secondary);
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
}

.iconContainer {
    font-size: 26px;
    margin-left: 20px;
    height: 32px;
    width: 26px;
    color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ticketIcon {
    color: var(--secondary);
    height: 26px;
    width: 26px;
    position: absolute;
}
.creatableIcon {
    font-size: 30px;
    margin-left: 24px;
    height: 32px;
    width: 26px;
    color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.btnIcon {
    width: 16px;
    height: 5px;
    margin-top: 4px;
    margin-bottom: 7px;
    border-radius: 2px;
    margin-right: 5px;
    margin-left: 5px;
    background-color: var(--secondary);
}

.textAreaContainer {
    position: relative;
    border-radius: 5px;
    background-color: var(--light-grey);
    width: 21px;
    height: 20px;
    margin-left: 1px;
    margin-right: 1px;
    border: 2px solid var(--dark-grey);
    font-size: 21px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--dark-grey);
}
