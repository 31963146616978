.descriptionLabel {
    color: var(--accent);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
}

.femenineContainer {
    margin-top: 5px;
    gap: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.descriptionInputContainer {
    margin-right: 25px;
    font-size: 16px;
    min-height: 34px;
}

.description {
    font-size: 16px;
    margin-left: 10px;
    min-height: 20px;
}

.descriptionInput {
    font-family: 'Nunito';
    font-size: 16px;
    margin-left: 2px;
    margin-top: -6px;
    padding-left: 12px;
    width: calc(100% - 15px);
}

.permsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.permsLabel {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.addPermButton {
    width: 150px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 15px;
    cursor: pointer;
}
.addIconContainer {
    font-size: 28px;
    height: 28px;
}
.addPermLbl {
    font-size: 16px;
    color: var(--accent);
}
.permContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin: 10px;
    align-items: center;
}
.deleteBtn {
    font-size: 26px;
    height: 26px;
    color: '#787878';
    cursor: pointer;
}

.relationshipsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.inputContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.permissionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    gap: 5px;
    margin-bottom: 15px;
}
.filterContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    gap: 5px;
}

.valuesContainer {
    width: calc(100% - 15px);
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
