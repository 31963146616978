
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    font-size: 1rem;
}

.infoContainer{
    width: 100%;
}

.loaderContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.noResults{
    font-size: 1.5rem;
}

.optionsNav {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 2px solid var(--light-grey);
    width: fit-content;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 20px;
}
.optionsNav {
    scrollbar-width: thin;
    scrollbar-color: var(--defaultGrey) transparent;
}


.formName,
.selectedFormName {
    width: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    padding: 5px 10px;
    border-top: 2px solid white;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}
.formName {
    margin-left: 2px;
    margin-right: 2px;
}

.selectedFormName {
    border-top: 2px solid var(--light-grey);
    border-left: 2px solid var(--light-grey);
    border-right: 2px solid var(--light-grey);
    background-color: white;
}

.loader{
    width: 100%;
    height: 100%;
}