.container {
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-flow: column;
}

.containerTop {
    flex-grow: 1;
    overflow-y: hidden;
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.mobileContainer {
    display: flex;
    overflow: auto;
    flex-flow: column;
    position: relative;
    min-height: 100%;
    padding-bottom: 30px;
}

.returnButton {
    position: absolute;
    left: 5px;
    top: 5px;
}
