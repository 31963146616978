.editingContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    margin-bottom: 20px;
}

.toggleContainer {
    margin-top: 2px;
}

.timeContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.timeLabel {
    font-size: 18px;
}
