.container {
    border-radius: 30px;
    background-color: var(--secondary);
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--primary1);
    font-size: 20px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -o-user-select: none;
    user-select: none;
    position: relative;
}

.label {
    text-align: center;
    position: absolute;
    width: 70px;
    height: 10px;
    font-size: 6px;
    font-weight: bold;
    bottom: -11px;
    color: var(--accent);
}
/* The cover blocks dragging the img */
.cover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 30px;
}
/* This is so that the node shows a border and doesn't change positions when it is selected */
:global(.selected) .container {
    border: var(--accent) 1px solid;
    margin-top: -1px;
    margin-left: -1px;
}

:global(.selected) .leftHandle {
    margin-top: -1px;
}
:global(.selected) .label {
    bottom: -11px;
}
.leftHandle::before {
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    height: 16px;
    width: 16px;
    border-radius: 10px;
}
