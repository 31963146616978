.container {
    display: flex;
    align-items: center;
    font-size: 24px;
    height: 50px;
}

.nameContainer {
    margin-left: 10px;
    font-size: 24px;
    position: relative;
}

.nameInput {
    width: 100%;
    margin-top: 0;
    margin-left: -8px;
    font-family: 'Nunito';
    font-size: 24px;
    padding-top: 0;
    padding-bottom: 0;
}
