.toggleContainer {
    margin-top: 2px;
}

.subjectContainer,
.clientContainer,
.clientInfoContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}
.subjectLabel,
.clientLabel,
.clientInfoLabel {
    font-size: 18px;
}

.clientInfoSelectorContainer {
    width: 160px;
    margin-left: 10px;
    max-width: 100%;
}

.clientInfoContainer {
    margin-top: 0px;
}

.clientContainer {
    margin-top: 2px;
    margin-bottom: -3px;
    height: 47px;
}

.propertySelectorContainer {
    width: 220px;
    margin-left: 15px;
    max-width: 100%;
}
