.messageContainer {
    font-size: 15px;
}
.btnsContainer {
    display: flex;
    flex-direction: row;
    max-width: calc(100%);
}

.messageBtn {
    background-color: var(--greyHover);
    border-radius: 10px;
    border: 1px solid var(--greyBtn);
    padding: 6px 13px;
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
    flex-direction: row;
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.replyContent {
    margin: 3px 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.replyBtn {
    background-color: var(--secondary2);
    border-radius: 10px;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    font-weight: 600;
    padding: 6px 12px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: default;
    min-width: 110px;
}

.startContainer {
    width: 100%;
    height: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.separator {
    height: 1px;
    margin-left: 10px;
    margin-right: 5px;
    flex-grow: 1;
    background-color: var(--secondary2);
}
.endSeparator {
    height: 1px;
    margin-left: 5px;
    margin-right: 10px;
    flex-grow: 1;
    background-color: var(--secondary2);
}

.endingLbl {
    font-size: 12px;
    color: var(--greyFont);
    display: flex;
}
