.pageContainer {
    position: relative;
    width: 100%;
}
.container {
    flex-direction: row;
    display: flex;
    height: calc(100vh - 54px);
    width: 100%;
}

.flowWrapper {
    flex-grow: 1;
    height: 100%;
    position: relative;
}

.save {
    z-index: 5;
    border-radius: 10px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.saving {
    background-color: rgb(0, 0, 0, 0.4);
    z-index: 20;
    height: calc(100vh);
    width: 100%;
    position: absolute;
}

.whiteCurtain {
    height: calc(100vh - 54px);
    position: absolute;
    width: 100%;
    display: flex;
    flex-flow: column;
    z-index: 4;
    background-color: white;
}
