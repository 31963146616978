.container {
    display: flex;
    align-items: center;
    flex-direction: row;

    margin-bottom: 10px;
}

.bullet {
    border: 2px solid var(--greyFont);
    background-color: var(--light-grey);
    height: 10px;
    width: 10px;
    border-radius: 20px;
    margin-right: 10px;
    margin-left: 5px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.containerLbl {
    min-width: 100px;

    /* background-color: var(--primary1); */
    border-radius: 7px;
    /* border: 1px solid var(--light-grey); */
    display: flex;
    align-items: center;
    font-size: 18px;
    padding-left: 5px;
}

.btnsContainer {
    margin-left: 7px;
    display: flex;
    flex-direction: row;
    column-gap: 3px;
}

.hideBtn:hover,
.addBtn:hover,
.deleteBtn:hover {
    border: 1px solid var(--light-grey);
}

.addBtn,
.hideBtn,
.deleteBtn {
    font-size: 26px;
    display: flex;
    align-items: center;
    color: var(--greyFont);
    border: 1px solid transparent;
    border-radius: 5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hideBtn,
.deleteBtn {
    font-size: 22px;
}

.contentContainer {
    margin-right: 20px;
}
