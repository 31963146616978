.interactionImg {
    height: 20px;
    width: 20px;
}

.loaderContainer {
    position: relative;
    height: 26px;
    width: 26px;
    overflow: hidden;
}
