.messageContainer {
    margin-top: 5px;
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    width: inherit;
    height: fit-content;
}
.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.image {
    max-width: 100%;
    width: auto;
    height: auto;
}
.imagePlaceholder {
    max-width: 100%;
    opacity: 0.5;
    width: auto;
    height: auto;
}
