.entityValuesContainer {
    max-width: 1000px;
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid var(--light-grey);
    margin-top: 10px;
}
.entityValuesLbl {
    font-size: 24px;
    display: flex;
    flex-direction: row;
}

.addBtnContainer {
    margin-left: auto;
}

.loaderContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 200px;
}
.emptyLbl {
    font-size: 18px;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.entityValuesListContainer {
    justify-content: center;
    gap: 20px 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}
