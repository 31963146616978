.fileLabel {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fileContainer {
    margin-left: 20px;
    height: 50px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: calc(100% - 40px);
}

.loaderContainer {
    position: relative;
}
.fileSelectContainer {
    width: fit-content;
    display: flex;
    margin-left: 60px;
}

.selectedFileContainer {
    margin-left: 20px;
    max-width: calc(100% - 40px);
}

.maxSize {
    font-size: 14px;
    margin-left: auto;
}
