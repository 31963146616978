.container {
    width: 400px;
}

.label {
    margin-left: 15px;
    font-size: 18px;
}

.parentInfo {
    margin-top: -10px;
    margin-bottom: 10px;
}
