.property {
    padding-left: 0px;
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
}
.propertyMenuItem {
    height: 42px;
    display: flex;
}

.icon {
    width: 30px;
    display: flex;
    align-items: center;
    margin-right: 7px;
    margin-left: 7px;
}

.shapesIcon {
    color: var(--secondary);
    height: 24px;
    width: 30px;
    margin-left: 1px;
}
.companyIcon {
    color: var(--secondary);
    height: 24px;
    width: 30px;
    margin-left: 3px;
}

.subjectLbl {
    font-size: 26px;
    margin-left: 2px;
    height: 32px;
    width: 26px;
    color: var(--secondary);
}

.formStepIcon {
    border-radius: 5px;
    padding-right: 4px;
    width: 17px;
    height: 20px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 14px;
    font-weight: 500;
    padding-left: 4px;
}

.flowsIcon {
    margin-left: 2px;
    height: 24px;
    width: 24px;
    color: var(--secondary);
}

.expressionIcon {
    font-size: 29px;
    margin-left: 2px;
    height: 32px;
    width: 32px;
    color: var(--secondary);
    transform: rotate(90deg);
}

.textAreaContainer {
    position: relative;
    border-radius: 5px;
    background-color: var(--light-grey);
    width: 24px;
    height: 24px;
    border: 2px solid var(--dark-grey);
    font-size: 24px;
    margin-left: 1px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--dark-grey);
}

.dateInputContainer {
    width: 30px;
    height: 26px;
    color: var(--secondary);
    font-size: 20px;
    font-weight: 600;
    padding-left: 2px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sentimentContainer {
    font-size: 30px;
    height: 30px;
    width: 30px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
}

.clientContainer {
    font-size: 30px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
}

.stateIcon {
    color: var(--secondary);
    height: 24px;
    width: 28px;
    margin-left: 4px;
}
