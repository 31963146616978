.actionContainer {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    min-width: 320px;
    position: relative;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    border: 2px solid var(--secondary);
    border-top: 0;
    border-radius: 0px 0px 10px 10px;
}

.titleContainer {
    background-color: var(--secondary);
    color: var(--primary1);
    border-radius: 10px 10px 0 0;
    padding-left: 8px;
    padding-right: 8px;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid var(--secondary);
    cursor: default;
}

@media screen and (max-width: 768px) {
    .actionContainer {
        max-width: calc(100vw - 45px);
        min-width: 75vw;
        margin-left: 5px;
        margin-right: 5px;
    }
}
