.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.content {
    min-height: 52px;
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.infoContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.message {
    flex-grow: 1;
    font-size: 12px;
    padding: 10px 8px 5px 8px;
    font-weight: 600;
    min-height: 30px;
    display: flex;
    padding-bottom: 20px;
}

.icon {
    position: relative;
    align-items: center;
    display: flex;
    padding: 8px;
    padding-left: 0px;
    justify-content: center;
    align-items: center;
}

.numberIcon {
    color: var(--secondary);
    position: absolute;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
}

.dropdown {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown:hover {
    .dropdownIcon {
        color: var(--contrast);
    }
}

.dropdownIcon {
    color: var(--secondary);
}

.ungroupedList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    cursor: default;
}

.ungroupedItem {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid var(--secondary);
    border-radius: 10px;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.notificationIcon {
    position: absolute;
    right: 2px;
    top: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--contrast);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    min-width: 14px;
    text-align: center;
    padding: 0 3px;
    z-index: 4;
}

.sideBar {
    background-color: var(--secondary);
    height: 60px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 8px;
    width: 8px;
    border-radius: 15px;
}

.dropUp {
    cursor: pointer;
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
}

.dropUp:hover {
    .dropdownIcon {
        color: var(--contrast);
    }
}
