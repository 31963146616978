.conditionContainer {
    min-height: 10px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: calc(100% - 20px);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: fit-content;
}

.valueContainer,
.operatorContainer {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.multiSelectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 300px;
    width: 100%;
    position: relative;
}

.valueContainer {
    flex-grow: 1;
    max-width: calc(80vw - 500px);
}

.removeContainer {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.removeConditionContainer {
    height: 26px;
    width: 26px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 20px;
    border: 3px solid var(--secondary);
    font-size: 20px;
    color: var(--secondary);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selectorContainer {
    width: fit-content;
    display: flex;
    min-width: 180px;
    max-width: 600px;
}

.classifierSelectorContainer {
    min-width: 300px;
    width: 100%;
    display: flex;
}

.sentiment {
    padding-left: 0px;
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
}

.sentimentIconContainer {
    margin-right: 5px;
    margin-left: -8px;
    font-size: 30px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
}

.expressionContainer {
    width: 100%;
    max-width: calc(100% - 4px);
    border-radius: 10px;
    border: var(--secondary2) solid 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.expressionConditionsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0px;
}

.deleteContainer {
    height: 21px;
    color: var(--accent);
    font-size: 20px;
    padding-left: 1px;
    padding-top: 1px;
    width: 21px;
    background-color: inherit;
    top: -13px;
    right: 20px;
    position: absolute;
    cursor: pointer;
    border-radius: 7px;
}
.deleteContainer:hover {
    border: var(--error) solid 2px;
    top: -15px;
    right: 18px;
}

.labelOthers {
    font-size: 20px;
}

.addCondition {
    height: 28px;
    width: 28px;
    border-radius: 7px;
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary1);
    cursor: pointer;
    position: absolute;
    bottom: -14px;
}

.addConditionIconContainer {
    font-size: 23px;
    height: 25px;
    width: 22px;
}

.expression {
    margin: 10px 0px;
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.expressionHr {
    height: 1px;
    flex-grow: 1;
    background-color: var(--secondary2);
}

.expressionBtn {
    border-radius: 7px;
    border: var(--secondary2) solid 1px;
    background-color: var(--primary1);
    z-index: 2;
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.expressionLbl {
    font-size: 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}
