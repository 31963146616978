.container {
    position: relative;
}
.card,
.mobileCard {
    margin: 0 20px 0 15px;
    border: 2px solid var(--secondary2);
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
    position: relative;
}
.mobileCard {
    margin: 0 10px;
}
.cardContent {
    padding-left: 10px;
    padding-right: 5px;
    overflow-y: auto;
    scroll-behavior: smooth;
    max-height: 260px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.mobileCardContent {
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.textParagraph,
.clampedTextParagraph,
.mobileTextParagraph {
    font-size: 15px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    line-height: 22px;
}

.clampedTextParagraph,
.mobileTextParagraph {
    -webkit-line-clamp: 5;
}

.keyword {
    background-color: var(--secondary2);
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 2px;
}

.footer {
    position: absolute;
    bottom: -15px;
    border: 2px solid var(--secondary2);
    border-top: 0;
    left: 35px;
    height: 15px;
    border-radius: 0 0 17px 17px;
    z-index: 2;
    width: 35px;
    background-color: white;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.arrowIcon {
    margin-top: 7px;
    font-size: 27px;
    color: var(--accent);
}

@media screen and (max-height: 899px) {
    .cardContent {
        max-height: 30vh;
    }
    .clampedTextParagraph {
        -webkit-line-clamp: 3;
    }
}

.sentimentContainer,
.mobileSentimentContainer {
    font-size: 24px;
    height: 24px;
    border-radius: 20px;
    position: absolute;
    top: -12px;
    right: 30px;
    z-index: 2;
    background-color: white;
}
.mobileSentimentContainer {
    right: 20px;
}
.negativeSentiment {
    color: var(--error);
}
.neutralSentiment {
    color: var(--greyFont);
}

.positiveSentiment {
    color: var(--secondary);
}
