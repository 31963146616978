.menuContainer {
    background-color: var(--primary1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid var(--outlineGrey);
    display: flex;
    margin-left: 2px;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
}
.container:not(:last-child) {
    border-bottom: 1px solid var(--outlineGrey);
}

.container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    height: fit-content;
    max-width: 300px;
    position: relative;
    cursor: pointer;
}

.container:hover {
    background-color: var(--light-grey);
}

.icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    padding: 3px 10px;
}

.label {
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}
.arrowContainer {
    margin-left: auto;
    margin-top: -6px;
    padding-right: 5px;
    font-size: 25px;
    height: 20px;
    overflow: hidden;
    cursor: pointer;
}
