.navBarContainer {
    width: 100vw;
    height: 54px;
    background-color: var(--primary1);
    z-index: 1;
    display: flex;
    overflow: hidden;
}

.mobileSidebarIconPadding {
    width: 50px;
    margin-right: auto;
}
.navBarLogo {
    height: 54px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}
.captaLogo {
    height: 40px;
    width: 146px;
}
.containerBtnsNavbar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.curtainMobileDrawer {
    position: absolute;
    width: 100vw;
    height: 100%;
}

.menu {
    margin: auto;
    margin-right: 14px;
    height: 36px;
    width: 36px;
    font-size: 34px;
    color: var(--accent);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.menuContainer {
    width: 100vw;
    background-color: var(--primary1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rightButtonsContainer {
    margin-left: auto;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}


.notificationIcon {
    position: absolute;
    right: 2px;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--contrast);
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    min-width: 14px;
    text-align: center;
    padding: 0 3px;
    z-index: 4;
}