.property {
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
}
