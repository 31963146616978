.content {
    margin-left: 40px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.toggleLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-top: 5px;
}

.toggleContainer {
    margin-left: 5px;
}

.integrationTxtField {
    width: 240px;
    max-width: calc(100% - 60px);
    margin-top: 10px;
    margin-left: 20px;
}

.valuesContainer {
    display: flex;
    width: 240px;
    flex-direction: row;
    margin-top: 10px;
    margin-left: 20px;
}
