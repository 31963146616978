
.negocios{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
}

.title{
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% + 6px);
    overflow-y: auto;
    margin-left: -6px;
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.label{
    font-weight: bold;
    margin-right: 3%;
}

.value {
    overflow-wrap: break-word;
}

.element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background-color: var(--primary1);
    border: var(--dark-grey) 1px solid;
    width: calc(99% - 6px);
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 6px;
    overflow: auto;
    cursor: pointer
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    width: calc(100% - 40px);
    padding: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sideBar {
    height: 100%;
    position: absolute;
    background-color: var(--secondary);
    width: 10px;
    border-radius: 10px 0 0 10px;
}