.list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow-y: auto;
    max-height: 70vh;
    min-height: 40vh;
    border-radius: 10px;
    background-color: var(--primary2);
}

.noResults{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    min-height: 30vh;
    text-align: center;
}

.loader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 30vh;
}