.container {
    background-color: var(--secondary);
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
    max-width: 200px;
    /* This is so the parent container doesn't show on drag https://stackoverflow.com/questions/22922761/rounded-corners-with-html-draggable*/
    transform: translate(0, 0);
}

.top {
    height: 27px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.title {
    width: 100%;
    font-size: 13px;
    margin-top: 4px;
    margin-left: 3px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--primary1);
}

.contentContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2px;
    padding-right: 2px;
    width: calc(100% - 4px);
}

.decisionContainer {
    background-color: var(--secondary2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 16px;
    border-radius: 5px;
    padding-left: 7px;
    margin-bottom: 5px;
}
