.agentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    min-width: 130px;
}

.nameLbl {
    font-size: 14px;
    margin-left: 4px;
    color: var(--accent);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
