.container {
    width: 90vw;
    height: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
.containerLbl {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.selector {
    margin: 0 auto 15px 0;
    width: 200px;
}

.emptyLbl {
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin: auto;
}
