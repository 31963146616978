.headerContainer,
.mobileHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mobileHeaderContainer {
    height: 60px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--light-grey);
}

.caseNumberLbl {
    font-size: 20px;
}

.stateLbl {
    font-size: 16px;
    cursor: inherit;
    /* margin-top: 5px; */
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editContainer {
    height: 16px;
    font-size: 16px;
    margin-left: 3px;
    margin-bottom: 3px;
    position: absolute;
    right: -17px;
    margin-top: -4px;
}

.stateContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    position: relative;
}

.header {
    display: flex;
    flex-direction: row;
    /* margin-bottom: 5px; */
}
.titleLbl {
    font-size: 17px;
}

.clockContainer {
    margin-left: auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
}
