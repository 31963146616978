.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    position: relative;
}
.image {
    max-height: 200px;
    max-width: 100%;
    width: auto;
    height: auto;
}
.imagePlaceholder {
    max-height: 200px;
    max-width: 100%;
    opacity: 0.5;
    width: auto;
    height: auto;
}
.stickerIconContainer {
    position: absolute;
    color: var(--secondary);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    overflow: hidden;
    height: 35px;
    width: 35px;
}
.stickerIcon {
    height: 50px;
    width: 50px;
}
