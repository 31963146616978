.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}
.previewBtn {
    position: absolute;
    width: 70px;
    height: 40px;
    background-color: var(--secondary);
    top: 40px;
    right: 30px;
    border-radius: 12px;
    color: white;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;
}

.saveBtn {
    position: absolute;
    top: 40px;
    right: 110px;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.whiteCurtain {
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    flex-flow: column;
    z-index: 4;
    background-color: white;
}

.settingsContainer {
    width: 400px;
    padding: 20px;
}

.settingsLabel {
    font-size: 24px;
    margin-bottom: 15px;
}
