.container {
    background-color: var(--primary1);
    border-radius: 10px;
    max-height: 300px;
    max-width: 500px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    overflow: hidden;
    overflow-y: auto;
    margin-left: -20px;
}

.fileContainer,
.allOption {
    border-top: 1px solid var(--light-grey);
    height: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}

.allOption {
    font-size: 1rem;
    font-weight: 600;
    justify-content: center;
    cursor: pointer;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
}
.allOption:hover {
    text-decoration: underline;
}
