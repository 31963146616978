.AIButton {
    border: 1px solid var(--light-grey);
    padding: 5px;
    width: 37px;
    height: 32px;
    border-radius: 7px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    font-size: 22px;
    position: relative;
}
.AIButton:hover {
    border: 1px solid var(--greyBtn);
}
