.typeContainer {
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.container {
    display: flex;
    flex-direction: column;
}

.editorContainer {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
    margin-bottom: 5px;
}

.passthroughHelperMessage {
    font-size: 15px;
    margin-left: 20px;
    font-weight: 400;
    font-style: italic;
}

.headerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.footerContainer {
    margin-left: 20px;
    margin-right: 20px;
}

.toggleLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 10px;
}

.toggleContainer {
    margin-left: 5px;
}

.btnLblContainer {
    width: 250px;
    max-width: calc(100% - 80px);
    margin-left: 60px;
    margin-top: 5px;
    margin-right: 20px;
}

.templateComponentsContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.optionsContainer {
    display: flex;
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    flex-direction: column;
}
.optionContainer {
    display: flex;
    margin-bottom: 10px;
    width: calc(100% - 40px);
}
.optionContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.optionRadio {
    height: 31px;
    margin-bottom: 20px;
    margin-right: 5px;
    margin-left: 10px;
}
.optionLabelInput {
    width: 232px;
    margin-top: 11px;
    max-width: calc(100% - 108px);
}

.lblCont {
    font-size: 20px;
    margin-bottom: 10px;
}
.addOption {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 17px;
    cursor: pointer;
}
.addIconContainer {
    font-size: 28px;
    height: 28px;
}

.addOptionLbL {
    font-size: 16px;
    color: var(--accent);
}

.btnsContainer {
    margin-left: auto;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.deleteBtn {
    font-size: 21px;
    margin-left: 8px;
    height: 20px;
    width: 20px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greyFont);
    cursor: pointer;
}

.optionContainer {
    display: flex;
    flex-direction: column;
}
.deleteBtn:hover {
    color: var(--dark-grey);
}

.optionClassifierContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 60px;
    margin-bottom: 15px;
}

.classifierNameLbl {
    font-size: 18px;
    margin-right: 8px;
}
.optionClassifierInput {
    width: calc(100% - 107px);
}

.conditionsBtnContainer {
    font-size: 21px;
    margin-left: 10px;
    height: 20px;
    width: 20px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: var(--greyFont);
    cursor: pointer;
}

.conditionsBtnContainer:hover {
    fill: var(--dark-grey);
}

.clientContainer {
    display: flex;
    flex-direction: column;
}

.clientLbl,
.clientInfoLabel {
    font-size: 18px;
}
.clientLbl {
    margin-right: 5px;
}

.clientInfoContainer {
    margin-left: 50px;
    margin-bottom: 10px;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.clientInfoSelectorContainer {
    width: 200px;
    margin-left: 20px;
    max-width: 100%;
}

.recurringLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 20px;
}
