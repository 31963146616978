.container {
    width: 100%;
    display: flex;
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5px;
    margin-bottom: 0px;
    /* min-height: 55px; */
}

.titleLbl {
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 35px;
    margin-top: 10px;
    margin-left: 5px;
}

.editContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-top: 10px;
    font-size: 20px;
    margin-left: 5px;
}
