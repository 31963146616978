.boldSpan {
    font-weight: 500;
}

.valuesContainer {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
