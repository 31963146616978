.container {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.warning {
    height: 18px;
    width: 18px;
    margin-left: 15px;
    margin-right: 2px;
    font-size: 18px;
    color: var(--error);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.failedText {
    color: var(--error);
    font-weight: bold;
    font-size: 13px;
    margin-left: 5px;
    margin-top: 2px;
}

.retryButton {
    margin-left: auto;
}

.cancelButton {
    margin-left: 12px;
    margin-right: 5px;
}
