.container {
    width: 400px;
    display: flex;
    flex-direction: column;
}
.toggleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
    height: fit-content;
    margin-bottom: 20px;
}

.permissionsContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}
.permssionsToggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.permissionsLbl {
    font-size: 20px;
    font-weight: 600;
    margin-right: auto;
    color: var(--secondary);
}

.subtitlesContainer {
    margin-top: 5px;
    margin-bottom: 20px;
    margin-right: auto;
    max-width: 100%;
}

.permissionListContainer {
    margin-top: 0px;
}

.orgLblName {
    font-size: 17px;
    margin-left: 10px;
    font-weight: 600;
}

.orgPermissionsList {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    margin-bottom: 10px;
}
.orgPermission {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}
.orgPermissionLbl {
    font-size: 15px;
}
.toggleContainer {
    margin-left: 3px;
}
