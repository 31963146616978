.container {
    min-height: 80px;
    background-color: var(--primary1);
    margin-bottom: 16px;
    border-radius: 10px;
    border: 1px solid var(--defaultGrey);
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 25px;
    align-items: center;
}

.agentName {
    margin-right: 5px;
    font-weight: bold;
    color: var(--secondary);
}

.highlighted {
    font-weight: bold;
    color: var(--secondary);
    margin-left: 5px;
    margin-right: 5px;
}

.ticketName {
    color: var(--secondary);
    font-weight: 600;
    text-decoration: underline;
    margin-left: 5px;
}

.activityParagraph {
    font-size: 14px;
    margin: 0px;
    margin-left: 10px;
    -webkit-line-clamp: 3;
    max-height: 60px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
}
