.property {
    padding-left: 0px;
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
}

.icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    margin-left: 7px;
}
.propertyLbl {
    font-size: 26px;
    margin-left: 2px;
    height: 32px;
    width: 26px;
    color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mailIcon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    margin-left: 7px;
    height: 32px;
    width: 26px;
    font-size: 26px;
    color: var(--secondary);
}

.whatsappIcon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    margin-left: 5px;
    width: 32px;
}
