.toggleContainer {
    margin-top: 2px;
}

.notificationContainer {
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}
.notificationLabel {
    font-size: 18px;
    margin-left: 5px;
}

.notificationSelectorContainer {
    width: 210px;
    margin-left: 35px;
    max-width: 100%;
    margin-top: 4px;
    height: 58px;
}
