.navBarAvatar {
    height: 54px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.navBarAvatarIcon {
    width: 100px;
    height: 100px;
}
.link {
    font-family: 'Nunito';
    font-size: 18px;
    width: 100%;
}
