.container {
    width: 450px;
}

.message {
    font-size: 17px;
    text-align: center;
    margin-bottom: 20px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
.btnContainer {
    margin-top: 30px;
    margin-bottom: 10px;
}

.nameSpan {
    margin-left: 5px;
    font-weight: bold;
}
