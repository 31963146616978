.editingContainer {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    width: fit-content;
    max-width: 100%;
}

.classifierNameLbl {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 5px;
}

.inputContainer {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subjectContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.subjectLabel {
    font-size: 18px;
}

.optionsLabel {
    margin-top: 5px;
    margin-left: 15px;
    font-size: 18px;
}
.optionsList {
    padding-top: 10px;
    margin-left: 20px;
    max-width: calc(100% - 20px);
    width: 100%;
}

.swapIcon {
    background-color: var(--secondary2);
    font-size: 18px;
    height: 22px;
    width: 22px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.classifierLbl {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.arrowIconContainer {
    margin-top: -2px;
    font-size: 15px;
    height: 15px;
    width: 20px;
    vertical-align: middle;
    justify-content: center;
    display: inline-flex;
}
.valueSpan {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
    margin-left: 4px;
    font-size: 16px;
}

.emptyLbl {
    font-size: 16px;
}
.subjectContainer {
    margin-left: 15px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}
.subjectLabel {
    font-size: 18px;
}

.toggleContainer {
    margin-top: 2px;
}
