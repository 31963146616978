.addBtn {
    width: 46px;
    height: 46px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: var(--secondary2);
    color: var(--primary1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.addIconContainer {
    font-size: 46px;
    height: 100%;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
}
