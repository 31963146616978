.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.addBtn {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}

.label {
    margin-left: 7px;
    color: var(--secondary);
}

.container:hover {
    text-decoration: underline;
    text-decoration-color: var(--secondary);
}
