.title {
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.titleContainer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 40px);
    height: 36px;
}

.colorIcon {
    margin-left: 7px;
    height: 16px;
    width: 16px;
    border-radius: 5px;
}

.rootLbl {
    margin-left: 7px;
}
.dotsLbl {
    margin-left: 2px;
    font-weight: 700;
    font-size: 15px;
}
.valueLbl {
    margin-left: 5px;
}

.nameLbl {
    max-width: calc(100% - 70px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
}

.backBtn {
    width: 30px;
    height: 30px;
    margin-right: 3px;
    margin-left: 3px;
    border-radius: 10px;
    background-color: var(--light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 22px;
}

.backBtn:hover {
    background-color: var(--greyBtn);
}

.emptyContainer {
    color: var(--accent);
    padding-top: 10px;
    font-size: 12px;
    padding-left: 12px;
    padding-bottom: 5px;
}

.container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 298px;
}

.search {
    background-color: var(--secondary2);
    border-radius: 3px;
}
