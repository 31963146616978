.container {
    width: 400px;
    height: 180px;
}
.containerErrors {
    width: 500px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 28px;
    margin-top: 40px;
    margin-bottom: 15px;
}
.titleError {
    font-size: 25px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.errorMsg {
    font-size: 18px;
}
.errorsIcon {
    height: 37px;
    margin-left: 7px;
    font-size: 30px;
    color: var(--error);
}

.savingMsg {
    font-size: 26px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 100%;
}

.cerrarBtn {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
