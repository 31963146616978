.historyButton {
    margin-bottom: 2px;
    margin-right: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.historyTitle {
    margin-top: 20px;
    font-size: 30px;
    margin-bottom: -10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.history {
    width: fit-content;
    max-width: 100%;
}

.historyContent {
    padding: 0px 30px 30px 30px;
    overflow-y: scroll;
    max-height: 75vh;
    max-width: calc(100% - 40px);
    display: flex;
    flex-direction: column-reverse;
}

.editText {
    font-size: 13px;
    cursor: pointer;
}

.editText:hover {
    text-decoration: underline;
    color: var(--contrast);
}

.currentContainer {
    display: block;

    max-width: 100%;
}

.currentCont {
    display: flex;
    flex-direction: column;
}
