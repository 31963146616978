.projPermissionsList {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    max-width: calc(100% - 25px);
}

.projPermission {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.projPermissionLbl {
    font-size: 15px;
}
.toggleContainer {
    margin-left: 3px;
}

.allBtn {
    margin-left: auto;
    margin-top: 10px;
    margin-right: auto;
}

.countContainer {
    height: 20px;
    top: -8px;
    right: -10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.countLbl {
    font-size: 8px;
    background-color: var(--contrast);
    font-weight: 600;
    color: white;
    padding-top: 1px;
    min-width: 5px;
    text-align: center;
    padding-bottom: 0px;
    border-radius: 20px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
}

.summaryContainer {
    position: relative;
}
