.messageContainer {
    margin-top: 5px;
    font-size: 15px;
    max-width: 100%;
}
.documentContainer {
    padding: 0px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
