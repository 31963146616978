.layoutContainer {
    width: 100%;
    flex-grow: 1;
    min-height: 0;
}

.listContainer {
    height: 100%;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.detailContainer {
    overflow: hidden;
    position: relative;
    height: calc(100% - 5px);
    margin-top: 5px;
}

.listContent {
    margin: 15px 0px 0px 0px;
    height: calc(100% - 15px);
}

.detailCard {
    background-color: white;
    margin: 10px 0px 0px 25px;
    width: calc(100% - 50px);
    height: calc(100% - 5px);
    border-radius: 10px 0 0 0;
    overflow: auto;
}

.returnButton {
    position: absolute;
    top: 45px;
    left: 10px;
    z-index: 2;
}
.xsmHeadersContainer {
    width: 100vw;
}

@media screen and (max-width: 576px) {
    .listContent {
        margin: 5px 0px 0px 0px;
        height: calc(100% - 5px);
    }

    .detailContainer {
        margin-top: 0px;
        height: 100%;
    }

    .detailCard {
        margin-left: 0px;
        border-radius: 0px;
    }

    .returnButton {
        left: 10px;
        top: 10px;
    }
}
