.container {
    width: 450px;
}

.message {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
    width: 400px;
    margin-left: 25px;
}
.dateChange {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
}
.LblSpan {
    font-weight: 600;
    margin-right: 8px;
}

.btnContainer {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.confirmBtn {
    margin-left: 20px;
}

.dateLbl {
    margin-top: 3px;
    font-size: 17px;
}
