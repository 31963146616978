.container {
    border-radius: 10px;
    padding: 3px;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: background-color 0.4s;
}

.container:hover {
    background-color: var(--primary2);
}

.hovered:hover {
    background-color: transparent;
}

.confirmationContainer {
    position: absolute;
    right: 0;
    z-index: 1;
}

.confirmationButton {
    margin: 2px;
}

.errorMessage {
    color: var(--error);
    font-size: 12px;
    padding-top: 1px;
    padding-right: 60px;
    position: absolute;
}
