.editingContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    margin-bottom: 20px;
}

.iconContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}
.iconLabel {
    font-size: 18px;
}

.optionsLabel {
    font-size: 18px;
    margin-left: 15px;
    font-weight: 600;
    margin-top: 10px;
}

.optionsList {
    padding-top: 15px;
    margin-left: 20px;
    max-width: calc(100% - 20px);
    width: 100%;
}

.addOption {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 10px;
    cursor: pointer;
}
.addIconContainer {
    font-size: 28px;
    height: 28px;
}

.addOptionLbL {
    font-size: 16px;
    color: var(--accent);
}

.filtersLabel {
    font-size: 18px;
    margin-left: 15px;
    font-weight: 600;
    margin-top: 10px;
}
.toggleContainer {
    margin-top: 2px;
}
.filtersContainer {
    margin-bottom: 5px;
}

.addFilter {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.loaderContainer {
    width: 100%;
    height: 100px;
    position: relative;
}

.pathContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}
