.messageContainer {
    font-size: 15px;
    position: relative;
}

.replyContent {
    margin: 3px 3px;
    display: flex;
    flex-direction: row;
}

.w1,
.w2,
.w3,
.w4 {
    border-radius: 10px;
    background-color: var(--secondary2);
    height: 22px;
    width: 100px;
    margin-left: 3px;
    margin-right: 3px;
}
.w2 {
    width: 60px;
}
.w3 {
    width: 80px;
}

.authPickerContainer {
    width: 65px;
}

.endingContainer {
    width: 100%;
    height: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.separator {
    height: 1px;
    margin-left: 10px;
    margin-right: 5px;
    flex-grow: 1;
    background-color: var(--secondary2);
}
.endSeparator {
    height: 1px;
    margin-left: 5px;
    margin-right: 10px;
    flex-grow: 1;
    background-color: var(--secondary2);
}

.endingLbl {
    font-size: 12px;
    color: var(--greyFont);
    display: flex;
}
