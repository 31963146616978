.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.stylesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stylesLabel {
    width: 155px;
    font-size: 18px;
}

.styleContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 15px;
    width: 100%;
}

.colorPick {
    height: 30px;
    width: 60px;
    margin-left: 20px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

.colorPickContainer {
    border-radius: 8px;
    margin-left: 10px;
}

.buttonContainer {
    margin-right: 10px;
    width: fit-content;
    position: relative;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}
