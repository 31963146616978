.button {
    background-color: var(--defaultGrey);
    border-radius: 10px;
    width: 100px;
    height: 25px;
    border: 1px solid #0000003b;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button:hover {
    border: 1px solid var(--greyFont);
}

.filtrosLbl {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.countContainer {
    width: 26px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.countLbl {
    font-size: 10px;
    background-color: var(--contrast);
    font-weight: 600;
    color: white;
    padding-top: 1px;
    min-width: 10px;
    text-align: center;
    padding-bottom: 1px;
    border-radius: 20px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
}

.dropDownContainer {
    width: 18px;
    height: 20px;
}
.dropDown {
    font-size: 30px;
    width: 30px;
    margin-left: -6px;
    margin-top: -5px;
    height: 30px;
    cursor: pointer;
}
