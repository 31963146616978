.container {
    display: flex;
    flex-direction: column;
}

.editorContainer {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
    margin-bottom: 5px;
}

.headerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.footerContainer {
    margin-left: 20px;
    margin-right: 20px;
}

.btnLblContainer {
    max-width: calc(100% - 20px);
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 20px;
}

.conceptLabel {
    font-size: 18px;
    margin-left: 25px;
    font-weight: 600;
}
.toggleCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.toggleContainer {
    margin-top: 2px;
    margin-left: 5px;
}

.conceptPickerContainer {
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 25px;
    width: 300px;
}

.conceptPropContainer {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 35px;
    margin-right: 50px;
}
.propContainer {
    width: 200px;
    width: fit-content;
}

.maxToggleContainer {
    display: flex;
    flex-direction: row;
    margin-left: 25px;
    align-items: center;
}
.maxToggleLabel {
    font-size: 18px;
    font-weight: 600;
    margin-right: 3px;
}
.maxInputContainer {
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 30px;
    width: 160px;
}
