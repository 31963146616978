.container {
    width: 400px;
    height: 400px;
    padding: 20px;
}
.valueContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}
.valueLabel {
    font-size: 20px;
}
.pickerContainer {
    margin-top: 10px;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
}

.btn {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.configIcon {
    height: 23px;
    width: 23px;
    fill: var(--dark-grey);
    /* height: 18,
                            width: 18,
                            marginTop: 2, */
}
