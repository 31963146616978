.container {
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
    max-width: calc(100% - 60px);
    width: 500px;
}
.templateLabel {
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
}

.templateDescription {
    margin: 0px;
    font-size: 14px;
    font-style: italic;
    margin-top: 5px;
}
.selectedCont,
.messageCont {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 75%;
    min-width: 100px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.cardContainer {
    position: relative;
    padding-right: 20px;
    overflow: hidden;
}

.messageCard {
    min-height: 20px;
    background-color: var(--greyHover);
    border-radius: 10px 0px 10px 10px;
    border: 1px solid var(--greyBtn);
    cursor: pointer;
    padding: 10px 13px;
    position: relative;
}

.selectedCont .messageCard {
    border-width: 2px;
    padding: 9px 12px;
}

.headerContainer {
    font-size: 15px;
    font-weight: bold;
    margin-top: -3px;
    margin-bottom: 5px;
}

.messageContainer {
    font-size: 15px;
}

.footerContainer {
    font-size: 13px;
    font-weight: 300;
    padding-left: 2px;
    margin-top: 5px;
    margin-bottom: -3px;
}

.selectedCont .tr,
.selectedCont .trBorder {
    border-width: 2px;
}

.trBorder {
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0px;
    z-index: 2;
    right: 8px;
    border-top: 1px solid var(--greyBtn);
}

.trContainer {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -8px;
    right: -15px;
    overflow: hidden;
}

.tr {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0px;
    z-index: 2;
    right: 26px;
    background-color: var(--greyHover);
    border-right: 1px solid var(--greyBtn);
    border-top: 1px solid var(--greyBtn);
    border-radius: 0px 2px 0px 0px;
    transform: rotate(33deg);
    cursor: pointer;
}

.btnsContainer {
    padding-right: 20px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    display: inline;
    max-width: calc(100% - 20px);
}
