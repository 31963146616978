.container {
    padding: 30px 40px 100px 40px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-height: 300px;
}

.nameLbl {
    padding-left: 8px;
    padding-right: 5px;
    font-size: 28px;
    margin-bottom: 15px;
}

.decisionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    min-width: 785px;
}
