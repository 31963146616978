.container {
    max-width: 280px;
    width: calc((100% - 20px) / 4);
    min-width: 200px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
}
.mobileContainer {
    width: calc(100% - 30px);
    padding-left: 15px;
    padding-right: 15px;
}

.hr {
    width: 100%;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--light-grey);
}
