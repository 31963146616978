.addBtnContainer {
    margin-top: 10px;
    width: 100%;
}
.stepsContainer {
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}
