.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
.cardContainer {
    position: relative;
    background-color: var(--primary1);
    height: 110px;
    width: 400px;
    border-radius: 10px;
    margin-top: 10px;
    border: 2px solid var(--light-grey);
    display: flex;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}
.removeBtn {
    font-size: 20px;
    position: absolute;
    right: 3px;
    top: 3px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    fill: var(--secondary2);
}

.info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.title {
    display: flex;
    margin: 10px 0 0 20px;
    font-size: 16px;
    font-weight: 600;
    height: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1px;
    cursor: default;
}

.photo {
    margin: auto 15px;
}

.sideBar {
    position: absolute;
    background-color: var(--secondary);
    height: 110px;
    width: 10px;
    border-radius: 10px 0 0 10px;
}

.viewMoreBtn {
    margin-top: auto;
    margin-left: 25px;
    margin-bottom: 15px;
}
