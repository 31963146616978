.ticketContentCard .DraftEditor-editorContainer {
    -webkit-line-clamp: inherit;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.ticketContentCard .DraftEditor-editorContainer,
.ticketContentCard .DraftEditor-root,
.ticketContentCard .rdw-editor-main,
.ticketContentCard .rdw-editor-wrapper {
    -webkit-line-clamp: inherit;
}
