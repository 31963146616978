.messageContainer {
    font-size: 15px;
}

.messageBtn {
    background-color: var(--greyHover);
    border-radius: 10px;
    border: 1px solid var(--greyBtn);
    padding: 6px 12px;
    font-size: 15px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.linkContainer {
    font-size: 18px;
    height: 18px;
    width: 18px;
    margin-right: 3px;
}

.passthroughContainer {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.passthoughLbl {
    margin-left: 15px;
    text-overflow: ellipsis;
}

.ticketIcon {
    width: 40px;
    height: 40px;
}
