.container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 45px;
    border-top: 1px solid var(--greyBtn);
}

.container:hover {
    background-color: var(--light-grey);
}

.topContainer {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
}

.bottomContainer {
    display: flex;
    flex-direction: row;
    margin-top: 1px;
    column-gap: 3px;
}
.name {
    padding-left: 10px;
    padding-right: 2px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    padding-left: 10px;
    padding-right: 2px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.templatesContainer .templateContainer:not(:last-child) {
    border-bottom: 1px solid var(--light-grey);
}

.icon {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    padding: 3px 7px 3px 7px;
    height: 10px;
    column-gap: 2px;
    color: var(--primary1);
}

.iconLbl {
    line-height: 11px;
    font-size: 10px;
    font-weight: bold;
}

.captaIcon {
    margin-top: -1px;
}
.imagesIcon {
    font-size: 14px;
    margin-top: -2px;
}

.filesIcon {
    font-size: 12px;
    height: 10px;
    margin-left: -2px;
    transform: rotate(-90deg);
}
