.templateBtn {
    border: 1px solid var(--light-grey);
    padding: 5px;
    width: 37px;
    height: 32px;
    border-radius: 7px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    font-size: 22px;
    position: relative;
}
.templateBtn:hover {
    border: 1px solid var(--greyBtn);
}

.container {
    background-color: var(--primary1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    width: 250px;
    /* padding-right: 10px; */
    border: 1px solid var(--outlineGrey);
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.emptyContainer {
    margin-left: 2px;
    margin-top: 2px;
    background-color: transparent;
}

.titleContainer {
    height: 25px;
    display: flex;
    align-items: center;
}
.title {
    margin-left: 10px;
    font-weight: 600;
    cursor: default;
}
.searchContainer {
    padding: 5px;
    border-top: 1px solid var(--greyBtn);
}

.scrollContainer {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 229px;
}
.loaderContainer {
    border-top: 1px solid var(--greyBtn);
    position: relative;
    height: 60px;
}

.templatesContainer {
    display: flex;
    flex-direction: column;
}
.noTemplateContainer {
    border-top: 1px solid var(--greyBtn);
    display: flex;
    height: 60px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
}
