.searchContainer {
    padding: 10px;
    padding-right: 7px;
    display: flex;
    flex-direction: row;
}

.roundSelect {
    max-width: calc(100% - 30px);
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.allCases {
    font-size: 16px;
}

.toggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
}
.companyToggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 5px;
}

.creationDateLbl {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
}

.datePickerContainer {
    max-width: calc(100% - 30px);
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}
