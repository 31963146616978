.container {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    overflow: hidden;
    max-width: 100%;
}

.listContainer {
    flex-grow: 1;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.listCont {
    overflow-y: scroll;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column-reverse;
    display: flex;
    align-items: flex-end;
}

.headerContainer {
    width: 100%;
}

.listContCont {
    width: 100%;
    max-width: 100%;
    margin-bottom: auto;
}
