.iframeContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.iframeStyles {
    height: calc(100% + 40px);
    z-index: 1;
    margin-top: -40px;
    overflow-y: hidden;
    overflow-x: scroll;
}
.mobileIframeStyles {
    height: calc(100% + 20px);
    z-index: 1;
    margin-top: -20px;
    overflow-y: hidden;
    overflow-x: scroll;
}

.vizTitle {
    margin: 10px 0 0 10px;
    z-index: 2;
}
