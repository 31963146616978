.lastContainer,
.container {
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}
.lastContainer {
    height: 46px;
}

.separator {
    height: 1px;
    margin-left: 10px;
    margin-right: 10px;
    flex-grow: 1;
    background-color: var(--light-grey);
}
.endSeparator {
    height: 1px;
    margin-left: 10px;
    margin-right: 10px;
    flex-grow: 1;
    background-color: var(--light-grey);
}

.btnsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnContainer,
.pasteContainer {
    height: 24px;
    width: 24px;
    color: var(--secondary);
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--secondary);
}

.btnContainer:hover,
.pasteContainer:hover {
    color: white;
    background-color: var(--secondary);
}

.lastBtnContainer {
    height: 24px;
    width: 42px;
    color: white;
    background-color: var(--secondary);
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.pasteContainer {
    margin-left: 10px;
}

.addIconContainer {
    font-size: 22px;
    width: 22px;
    height: 22px;
    display: flex;
}
.lastBtnContainer .addIconContainer {
    font-size: 26px;
    width: 26px;
    height: 26px;
}
.pasteIconContainer {
    font-size: 22px;
    width: 22px;
    height: 22px;
    display: flex;
}

.disabledCurtain {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 5;
}
