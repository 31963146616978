.actionEndUserContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.actionTitleContainerEndUser {
    padding-left: 6px;
    padding-right: 6px;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: var(--secondary);
    border-radius: 10px 10px 0 0;
    border: 1px solid var(--secondary);
}

.actionDate {
    font-size: 12px;
    white-space: nowrap;
}

.actionTitle {
    font-size: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.actionProject {
    font-size: 12px;
    white-space: nowrap;
    font-weight: bold;
    width: 45%;
    text-align: center;
    overflow-wrap: break-word;
}

.iconInfo {
    width: 15px;
    height: 15px;
    stroke: var(--secondary2);
    fill: transparent;
    border-radius: 50%;
    text-align: center;
    font-weight: 800;
    font-size: 12px;
    cursor: pointer;
}

.iconInfo:hover {
    stroke: var(--contrast);
}

.actionContentEndUserContainer {
    border: 1px solid var(--secondary);
    border-top: 0;
    border-radius: 0 0 10px 10px;
    min-height: 30px;
    font-size: 14px;
    background-color: var(--primary1);
}

.infoTicketContainer {
    max-width: calc(100% - 5px);
    padding-right: 5px;
}

.closedBadge {
    position: absolute;
    bottom: 12px;
    right: -22px;
    background-color: var(--secondary2);
    color: var(--primary1);
    font-weight: 600;
    padding: 2px 5px 3px 5px;
    rotate: -45deg;
    width: 80px;
    text-align: center;
    font-size: 12px;
    clip-path: polygon(25% 100%, 75% 100%, 100% 0, 0 0);
}
