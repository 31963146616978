.editingContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    width: fit-content;
}

.inputContainer {
    margin-bottom: 20px;
}
