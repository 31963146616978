.messageContainer {
    margin-top: 5px;
    font-size: 15px;
}
.videoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    position: relative;
    overflow: hidden;
}
.videoPlayer {
    max-width: 100%;
    width: auto;
    height: auto;
}
.videoPlayerPlaceHolder {
    max-width: 100%;
    opacity: 0.5;
    width: auto;
    height: auto;
}

.videoIcon {
    position: absolute;
    color: var(--secondary);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    font-size: 28px;
    overflow: hidden;
    height: 35px;
    width: 35px;
}
