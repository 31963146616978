.container {
    color: var(--accent);
    max-height: 100%;
    height: fit-content;
    width: fit-content;
    max-width: 100%;
    overflow: auto;
    position: relative;
    display: block;
}

.close {
    height: 1px;
    width: 1px;
    right: 0px;
    top: 0px;
    background-color: transparent;
    position: absolute;
}
