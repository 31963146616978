.treeItemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.treeItemTitle {
    padding-left: 0px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
}
.label {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdownIcon {
    padding: 6px;
    width: 13px;
    height: 13px;
}

.areaItemSubContainer {
    padding-left: 22px;
}
