.container {
    border-radius: 30px;
    background-color: var(--accent);
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--primary1);
    font-size: 20px;
}

.label {
    text-align: center;
    position: absolute;
    width: 70px;
    height: 10px;
    font-size: 8px;
    font-weight: bold;
    bottom: -11px;
    color: var(--accent);
}
/* This is so that the node shows a border and doesn't change positions when it is selected */
:global(.selected) .container {
    border: var(--accent) 1px solid;
    margin-top: -1px;
    margin-left: -1px;
}

:global(.selected) .leftHandle {
    margin-top: -1px;
}
:global(.selected) .label {
    bottom: -10px;
}
.leftHandle::before {
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    height: 16px;
    width: 16px;
    border-radius: 10px;
}
