.statePill {
    padding: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    height: 16px;
    background-color: var(--secondary2);
}
.statePillLbl {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    font-weight: 600;
}
.stateValueSpan {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
    font-size: 12px;
}
