.container {
    display: flex;
    flex-direction: column;
}

.editorContainer {
    display: flex;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
}

.toggleLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 10px;
}

.toggleContainer {
    margin-left: 5px;
}

.clientContainer {
    display: flex;
    flex-direction: column;
}

.clientLbl,
.clientInfoLabel {
    font-size: 18px;
}
.clientLbl {
    margin-right: 5px;
}

.clientInfoContainer {
    margin-left: 50px;
    margin-bottom: 10px;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.clientInfoSelectorContainer {
    width: 200px;
    margin-left: 20px;
    max-width: 100%;
}

.recurringLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 20px;
}
