.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 10px;
    border: 1px solid var(--light-grey);
    border-radius: 10px;
}
.inputContainer {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.inputContainer2 {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.deleteBtn {
    font-size: 21px;
    margin-left: 8px;
    height: 31px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greyFont);
    cursor: pointer;
}

.optionContainer {
    display: flex;
    flex-direction: column;
}
.deleteBtn:hover {
    color: var(--dark-grey);
}
.toggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.toggleLabel {
    font-size: 18px;
}
