.treeNodeContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.treeNodeTitle {
    padding-left: 0px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
}

.userIconContainer {
    margin-left: 5px;
    margin-right: 5px;
    width: 15px;
    height: 15px;
    font-size: 20px;
    color: var(--accent);
}
.label {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.userIcon {
    margin-left: -3px;
    margin-top: -3px;
}
