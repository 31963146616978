/* @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap'); */

html {
    scroll-behavior: smooth;
    height: 100%;
}

body {
    margin: 0;
    --primary1: #ffffff;
    --primary2: #f0f0f0;
    --secondary: #3d5a7f;
    --transparentsecondary: rgb(61, 90, 127, 0.04);
    --secondary2: #98c1d9;
    --accent: #293241;
    --contrast: #ee6c4d;
    --defaultGrey: #d6d6d6;
    --secondaryHover: #4b6c9b;
    --secondaryRipple: hsl(214, 35%, 53%);
    --greyHover: #ebebeb;
    --disabledGrey: #a0a0a0;
    --outlineGrey: #b8b8b8;
    --greyRipple: #ffffff;
    --greyFont: #787878;
    --greyBtn: #afafaf;
    --light-grey: #d6d6d6;
    --dark-grey: #4f4f4f;
    --error: #cc2936;
    color: var(--accent);
    scrollbar-width: none;
    overflow: overlay;
    font-family: 'Nunito';
    /* Style to remove space between inline-block elements */
    font-size: 0;
    unicode-bidi: embed;
    height: 100%;
}

/* Style to override the font-size: 0 added to teh body selector */
h1,
h2,
h3,
h4,
h5,
h6,
label,
p {
    font-size: 22px;
}
/* Styles for all the scrollbars of the app */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--defaultGrey) transparent;
}
*::-webkit-scrollbar {
    width: 4px;
}
*::-webkit-scrollbar-track {
    background: transparent;
}
*::-webkit-scrollbar-thumb {
    background: var(--defaultGrey);
    border-radius: 2px;
}
*::-webkit-scrollbar-thumb:hover {
    background: var(--dark-grey);
}

.flexbox {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-wrap: wrap;
}

.wdn-text-hidden {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
}

#root {
    height: 100%;
}

.app {
    display: flex;
    flex-flow: row;
    background-color: var(--primary2);
    width: 100vw;
    height: calc(100% - 54px);
    overflow: hidden;
}

.selected-item-shadow {
    -webkit-box-shadow: -6px 0px 8px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -6px 0px 8px 3px rgba(0, 0, 0, 0.2);
    box-shadow: -6px 0px 8px 3px rgba(0, 0, 0, 0.2);
}
.center-anything {
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}

.detail-sub-title {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
}

.capta-logo {
    height: 65px;
    width: 154px;
}

@media screen and (max-width: 576px) {
    .selected-item-shadow {
        -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -o-user-select: none;
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.secondary-btn {
    cursor: pointer;
    background-position: center;
    background-color: var(--secondary);
    transition: background 0.8s;
}

.secondary-btn:hover {
    background: var(--secondaryHover)
        radial-gradient(circle, transparent 1%, var(--secondaryHover) 1%)
        center/15000%;
}

.secondary-btn:active {
    background-color: var(--secondaryRipple);
    background-size: 100%;
    transition: background 0s;
}
.grey-btn {
    cursor: pointer;
    background-position: center;
    background-color: var(--defaultGrey);
    transition: background 0.8s;
}

.grey-btn:hover {
    background: var(--greyHover)
        radial-gradient(circle, transparent 1%, var(--greyHover) 1%)
        center/15000%;
}

.grey-btn:active {
    background-color: var(--greyRipple);
    background-size: 100%;
    transition: background 0s;
}

.editInput {
    height: 24px;
}

.edit-input {
    width: calc(100% - 12px);
    height: 100%;
    padding: 3px 6px;
    border-radius: 10px;
    font-size: inherit;
    outline: none;
    border-color: var(--secondary);
}

.OverFlowContainer {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.app-link {
    text-decoration: none;
    color: var(--accent);
}

.flowPath,
.flowPath-error {
    stroke-dasharray: 5;
    -webkit-animation: dashdraw 1.5s linear infinite;
    animation: dashdraw 1.5s linear infinite;
    stroke-width: 4;
    fill: none;
    stroke: var(--accent);
    cursor: pointer;
}

.flowPath-error {
    stroke: var(--error);
}

.flowPathBack {
    stroke-dasharray: 0;
    stroke-width: 10;
    fill: none;
    cursor: pointer;
}

.formPath {
    stroke-width: 6;
    fill: none;
    stroke: var(--accent);
    cursor: pointer;
}

.formPathBack {
    fill: none;
    cursor: pointer;
}

@keyframes blink {
    50% {
        color: transparent;
    }
}

.loader__dot {
    animation: 1s blink infinite;
}

.loader__dot:nth-child(2) {
    animation-delay: 250ms;
}

.loader__dot:nth-child(3) {
    animation-delay: 500ms;
}

/* Overrides autocomplete error label */
.Erk-MuiFormControl-root.Mui-error-override .Erk-MuiAutocomplete-root label {
    color: var(--error);
}
