.container {
    z-index: 2;
    max-height: calc(100% - 40px);
}

.titles {
    height: 35px;
    margin-top: -35px;
    display: flex;
    flex-flow: row;
    width: fit-content;
}

.tab,
.selectedTab {
    width: fit-content;
    height: 35px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 2px;
    display: flex;
    cursor: pointer;
}
.selectedTab:hover,
.tab:hover {
    text-decoration: underline;
}

.selectedTab {
    background-color: var(--secondary);
    color: white;
}
.tab {
    background-color: var(--defaultGrey);
}
.lblTab {
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}
.actionForm {
    display: flex;
    height: 0px;
    position: relative;
    transition: height 0.5s;
    flex-direction: column;
    background-color: white;
    max-height: 100%;
}

.textField {
    margin: 15px;
    width: calc(100% - 30px);
    position: relative;
    background-color: white;
    border-radius: 15px;
    /** 32 por la linea de arriba **/
    height: calc(100% - 32px);
}

.resizer {
    position: absolute;
    height: 7px;
    z-index: 3;
    top: -3px;
    width: 100%;
    background-color: transparent;
    cursor: ns-resize;
}

.resizer:active {
    cursor: ns-resize;
}

.resizerLine {
    height: 1px;
    width: 100%;
    background-color: var(--defaultGrey);
    border-bottom: 1px solid white;
}
