.container {
    border-radius: 10px;
    background-color: var(--accent);
    height: 60px;
    width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--primary1);
    font-size: 45px;
    /* This is so the parent container doesn't show on drag https://stackoverflow.com/questions/22922761/rounded-corners-with-html-draggable*/
    transform: translate(0, 0);
    margin-left: 5px;
    margin-right: 5px;
}
