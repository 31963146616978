.subStepContainer {
    margin-top: 10px;
    margin-left: 5px;
}
.subStepLbl {
    margin-left: 15px;
    font-size: 18px;
    margin-bottom: 5px;
}
.addSubElement {
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 15px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
}
.addIconContainer {
    font-size: 28px;
    height: 28px;
}

.addSubElementLbL {
    font-size: 16px;
    color: var(--accent);
}
