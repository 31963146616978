.emptyContainer {
    max-width: 100%;
    width: 100%;
    position: relative;
}

.container {
    position: relative;
    background-color: var(--primary1);
    height: 90px;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .emptyContainer {
        height: 100px;
    }
}

.sideBar {
    background-color: var(--secondary);
    height: 100%;
    width: 10px;
    border-radius: 10px 0 0 10px;
}

.infoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dateContainer {
    height: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-right: 10px;
    min-width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.ticketInfo {
    flex-grow: 1;
    padding-left: 10px;
    max-width: calc(100% - 80px);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.dateLbl {
    font-size: 15px;
    color: var(--accent);
    text-align: center;
    cursor: pointer;
}

.dateTitleLblExpired {
    font-size: 11px;
    color: var(--error);
    cursor: pointer;
}

.dateLblExpired {
    font-size: 15px;
    color: var(--error);
    cursor: pointer;
}
.ticketTypeLbl {
    font-size: 10px;
    font-weight: 700;
    height: 14px;
    margin-top: 6px;
    position: static;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    cursor: pointer;
    text-align: center;
    bottom: 20px;
    /* visibility: hidden; */
}

.ticketCaseLbl {
    font-size: 10px;
    font-weight: 700;
    height: 14px;
    margin-top: 6px;
    position: static;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    position: absolute;
    top: 16px;
}

.closedLbl {
    font-size: 14px;
    color: var(--accent);
    cursor: pointer;
}
.dateTitleLbl {
    font-size: 11px;
    color: var(--accent);
    cursor: pointer;
}
.clientNameLbl {
    font-size: 10px;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.ticketTitleLbl {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.classifiersContainer {
    width: calc(100% - 20px);
    margin-top: 2px;
    align-items: center;
    overflow: visible;
    height: 50px;
}
.bottomPadding {
    background-color: white;
    z-index: 2;
    width: calc(100%);
    height: 2px;
}
.pillContainer {
    max-width: 100%;
    margin-right: 5px;
    height: 24px;
    margin-top: 1px;
    display: flex;
}

.notShowingIndicatorCont {
    height: 24px;
    width: 1px;
    margin-left: -1px;
    position: relative;
    overflow: visible;
}

.notShowingIndicator {
    background-color: var(--secondary);
    padding: 3px;
    position: absolute;
    width: fit-content;
    padding-left: 4px;
    padding-right: 8px;
    border-radius: 15px;
}

.notShowingIndicatorLbl {
    color: white;
    font-size: 12px;
    font-weight: 500;
}

.entity {
    font-weight: 700;
}

.subTicketSpan {
    font-weight: bold;
    margin-left: 4px;
}

.subTicketImg {
    position: absolute;
    bottom: 17px;
    height: 21px;
    width: 21px;
}

.firstLineContainer {
    width: fit-content;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    height: 24px;
    margin-top: 1px;
}

.firstPillContainer {
    margin-left: 5px;
    margin-right: 5px;
    height: 24px;
    min-width: 0;
    width: fit-content;
    display: inline;
}

.notificationIcon {
    position: absolute;
    right: 2px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--contrast);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    min-width: 14px;
    text-align: center;
    padding: 0 3px;
    z-index: 2;
}
