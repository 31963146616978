.container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formContainer {
    height: 100%;
    min-width: 1020px;
    min-height: 600px;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 100%;
    width: fit-content;
}
