.container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    min-height: 30px;
    padding-bottom: 10px;
    width: fit-content;
    row-gap: 5px;
}

.dateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    height: 30px;
    overflow: hidden;
}

.iconContainer {
    margin-left: 8px;
    color: var(--secondary);
    font-size: 26px;
    width: 30px;
    height: 28px;
}
.dateLbl {
    font-size: 14px;
    margin-left: 4px;
    color: var(--accent);
    white-space: nowrap;
    overflow: hidden;
    margin-right: 5px;
    text-overflow: ellipsis;
    min-width: 125px;
}

.subjectLbl {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
}

.messageContainer {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 22px;
    cursor: default;
}

.locationLbl {
    font-size: 14px;
    margin-left: 7px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
