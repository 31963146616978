.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 5px 0px;
    width: 100%;
}
.editingContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    margin-top: 20px;
}

.subStepsLbl {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-weight: 600;
}

.elementsLbl {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 600;
    margin-right: 10px;
}
.lvlContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.levelsPicker {
    width: 100px;
}

.titleLbl {
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.descriptionPar {
    font-size: 16px;
    white-space: pre-wrap;
    cursor: default;
}

.btnContainer {
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 3px;
    width: fit-content;
}

.incidentContainer,
.mobileContainer {
    background-color: #f0f0f0;
    border: 1px solid #d6d6d6;
    width: fit-content;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
}

.incidentContainer {
    max-width: calc(100% - 112px);
    padding-right: 55px;
    padding-left: 55px;
}

.mobileContainer {
    max-width: calc(100% - 42px);
    padding-right: 20px;
    padding-left: 20px;
}

.elementTitleLbl {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    margin-left: 10px;
    margin-bottom: 10px;
}

.contentContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.deleteBtn {
    font-size: 24px;
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 15px;
}

.subStepContainer {
    width: fit-content;
    max-width: calc(100% - 20px);
    display: flex;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 0px;
    flex-direction: column;
    padding-top: 5px;
    margin-bottom: 5px;
    position: relative;
}
.stepsLbl {
    font-size: 20px;
    margin-left: 15px;
    margin-bottom: 10px;
    font-weight: 600;
}
.stepsContainer {
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.addBtnContainer {
    margin-top: 10px;
    width: 100%;
}

.maxToggleContainer {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-top: 15px;
    align-items: center;
}
.maxToggleLabel {
    font-size: 20px;
    font-weight: 600;
    margin-right: 3px;
}
.maxInputContainer {
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 30px;
    width: 160px;
}
