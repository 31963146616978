.sideBarContainer {
    height: 100%;
    background-color: var(--accent);
}
.sideBarDrawer {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    background-color: var(--accent);
    height: calc(100% - 15px);
    z-index: 5;
}
.minifiedContainer {
    width: 46px;
    height: 46px;
    position: absolute;
    top: 6px;
    left: 5px;
}

.minifiedSideBarDrawer {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    background-color: var(--accent);
    width: 300px;
    height: 100%;
}
.containerElement {
    margin: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: var(--primary1);
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
}
.containerElement:hover:not(.selectedElement) {
    background-color: var(--secondary);
}
.selectedElement {
    background-color: var(--contrast);
}

.elementLabel {
    overflow: hidden;
    flex-wrap: nowrap;
    height: 24px;
    width: 130px;
}
/* Desktop */
.openDesktopElement {
    height: 50px;
}
.closedDesktopElement {
    height: 50px;
    width: 50px;
}
.openDesktopElement .elementIcon,
.closedDesktopElement .elementIcon {
    margin: 5px;
    margin-left: 7.5px;
    width: 35px;
    height: 35px;
}
.openDesktopElement .elementLabel,
.closedDesktopElement .elementLabel {
    margin-left: 20px;
    font-size: 18px;
    margin-right: 20px;
}
/* Movil */
.openMobileElement {
    height: 40px;
}
.minifiedElement {
    height: 32px;
    width: 32px;
}
.minifiedElement .elementIcon {
    margin: 5px;
    width: 22px;
    height: 22px;
}
.minifiedElement .elementLabel {
    margin-left: 15px;
    font-size: 18px;
    margin-right: 15px;
}

/* Closed */
.closedDesktopElement .elementLabel,
.minifiedElement .elementLabel,
.invisibleElement {
    display: none;
}

.lastElement {
    margin-top: auto;
}
