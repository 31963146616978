.container {
    height: 2px;
    width: 900px;
    max-width: 100%;
    margin-bottom: 5px;
    border-radius: 20px;
}
.labelContainer {
    margin-top: -40px;
    padding-top: 3px;
    height: 25px;
    width: fit-content;
    font-size: 20px;
}
.editingContainer {
    display: flex;
    flex-direction: column;
}

.transparentContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.transparentLabel {
    width: 150px;
    font-size: 18px;
}
