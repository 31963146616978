.isContentContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.isContentLabel {
    width: 150px;
    font-size: 18px;
}

.toggleContainer {
    margin-top: 2px;
}
