.container {
    max-width: 100%;
}
.actionTitleContainerUser {
    padding-left: 8px;
    padding-right: 8px;
    height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid var(--light-grey);
    cursor: default;
    background-color: var(--secondary);
    color: var(--primary1);
    border-radius: 10px 10px 0 0;
    max-width: calc(100% - 20px);
}

.actionTitle,
.actionDate {
    font-size: 15px;
    white-space: nowrap;
}

.actionDate {
    margin-top: 2px;
    font-size: 13px;
    margin-left: auto;
}
.actionTitle {
    margin-top: 1px;
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.boldName {
    font-weight: bold;
    margin-right: 5px;
}

.msgContainer {
    border-radius: 0 0 10px 10px;
    border: 2px solid var(--light-grey);
    border-top: 0;
    max-width: calc(100% - 4px);
}

.textContainer {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    line-height: 22px;
    cursor: default;
}
.lblFile {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
    margin-right: 5px;
}

.filesContainer {
    padding: 5px;
    padding-left: 2px;
    display: flex;
}

.filesContainer {
    display: flex;
}
.fileContainer {
    padding-right: 5px;
    max-width: 100%;
}
.validationContainer {
    font-size: 12px;
    text-align: end;
    font-style: italic;
    font-weight: 600;
    padding-bottom: 2px;
    padding-right: 5px;
}
