.btnContainer {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 4;
}

.interaction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.badge {
    position: absolute;
    background-color: var(--contrast);
    padding: 3px;
    min-width: 11px;
    height: 11px;
    border-radius: 15px;
    color: var(--primary1);
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    margin-left: 16px;
}
.interactionImg {
    height: 30px;
    width: 30px;
}
.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}
.appBar {
    background-color: var(--secondary);

    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    min-height: 48px;
}

.backBtn {
    color: white;
}
