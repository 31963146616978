.container {
    padding: 5px;
    display: flex;
    flex-direction: row;
    height: 40px;
}

.companyContainer {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    margin-bottom: 5px;
}

.nameLbl {
    font-size: 14px;
    margin-left: 4px;
}

.arrowContainer {
    font-size: 40px;
    margin-top: 0px;
    margin-right: 5px;
    color: var(--secondary);
}

.companyIconContainer {
    background-color: var(--secondary);
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
.companyIcon {
    height: 22px;
    width: 22px;
}
