.button {
    background-color: var(--defaultGrey);
    border-radius: 10px;
    width: 25px;
    height: 25px;
    border: 1px solid #0000003b;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--accent);
    justify-content: center;
}

.infoContainer {
    width: 80vw;
    font-size: 12px;
}
.iconContainer {
    height: 22px;
    font-size: 22px;
}

.button:hover {
    border: 1px solid var(--greyFont);
}
