.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: calc(100% - 2px);
    max-width: 100%;
    overflow-y: hidden !important;
    overflow: hidden;
}

.container div {
    overflow-y: hidden !important;
}

.header {
    font-weight: bold;
    font-size: 12px;
}

.textContainer {
    line-height: 14.5px;
    font-size: 12px;
    max-height: 42px;
    cursor: default;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
