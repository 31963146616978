.container {
    width: calc(100% - 8px);
    height: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 6px;
}

.loader {
    width: 100%;
}

.sendNow {
    margin-left: 8px;
}

.cancel {
    margin-left: 5px;
    margin-right: 0px;
}

.loaderLine {
    background-color: var(--secondary) !important;
}

.loaderFont {
    background-color: #3d5a7f66 !important;
    border-radius: 5px;
}
