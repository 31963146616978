.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2px;
}

.arrowContainer {
    height: 22px;
    margin-top: -5px;
    font-size: 40px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 3px;
}

.pillContainer {
    min-width: 0px;
    position: relative;
    display: flex;
    width: fit-content;
    margin-bottom: 5px;
    flex-direction: row;
    max-width: 100%;
    display: inline;
}
