.projectsDropdown {
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dropDownBtn {
    text-transform: 'none';
    font-family: 'Nunito';
    font-size: 18;
}

.link {
    font-family: 'Nunito';
    width: 100%;
    max-width: 100vw;
    font-size: 16px;
    padding: 6px 16px 6px 16px;
}
