.container {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.headerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}
.editorLabel {
    margin-left: 5px;
    font-size: 18px;
    margin-bottom: 5px;
}

.editorContainer {
    display: flex;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
    flex-direction: column;
    margin-bottom: 15px;
}

.footerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.btnLblContainer {
    margin-left: 20px;
    margin-right: 20px;
}
