.container {
    border-radius: 10px;
    background-color: var(--accent);
    height: 48px;
    width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--primary1);
    padding-top: 2px;
}
.icon {
    fill: var(--primary1);
    height: 32px;
    padding-bottom: 2px;
}
.config {
    height: 10px;
    width: 10px;
    font-size: 10px;
    color: var(--primary1);
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
}
.iconsContainer {
    width: 100%;
    position: absolute;
    bottom: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.shapesIcon {
    height: 8px;
    fill: var(--primary1);
    margin-left: 1px;
}

.chatIcon {
    height: 6px;
    padding-bottom: 2px;
    margin-left: 2px;
}

/* This is so that the node shows a border and doesn't change positions when it is selected */
:global(.selected) .container {
    border: var(--contrast) 1px solid;
    margin-top: -1px;
    margin-left: -1px;
}

:global(.selected) .leftHandle {
    margin-top: -1px;
}
:global(.selected) .config {
    margin-top: 0px;
    right: 5px;
}
:global(.selected) .iconsContainer {
    bottom: 2px;
}

:global(.selected) .rightHandle {
    margin-top: -1px;
    margin-right: 1px;
}

.leftHandle::before,
.rightHandle::before {
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    height: 16px;
    width: 16px;
    border-radius: 10px;
}
