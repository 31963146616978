.messageContainer {
    font-size: 15px;
}

.messageBtn {
    background-color: var(--greyHover);
    border-radius: 10px;
    border: 1px solid var(--greyBtn);
    padding: 6px 12px;
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.replyContent {
    margin: 3px 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.w1,
.w2,
.w3,
.w4 {
    border-radius: 10px;
    background-color: var(--secondary2);
    height: 22px;
    width: 100px;
    margin-left: 3px;
    margin-right: 3px;
}
.w2 {
    width: 60px;
}
.w3 {
    width: 80px;
}

.replyBtn {
    background-color: var(--secondary2);
    border-radius: 10px;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    font-weight: 600;
    padding: 6px 12px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: default;
}
