.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    max-width: 100%;
}

.containerLbl {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.descriptionLbl {
    font-size: 18px;
    margin-top: 15px;
}

.filesContainer {
    margin-top: auto;
    padding: 5px;
    display: flex;
}

.btnsContainer {
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-bottom: 25px;
}
.btn {
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
}

.optionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 8px;
    margin-top: 15px;
}

.toggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.optionLabel {
    font-size: 18px;
}

.warningContainer {
    display: flex;
    flex-direction: column;
}
.tagWarning {
    font-size: 14px;
    font-weight: 700;
    color: var(--error);
}
