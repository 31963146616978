.title {
    margin: 0;
    font-size: 24px;
    height: 35px;
    max-width: calc(100% - 100px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.titlelbl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
}
.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.delBtn {
    margin-left: 20px;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.containerDetail {
    font-size: 24px;
    position: relative;
}

.descriptionLabel, .payloadLabel {
    color: var(--accent);
    font-size: 18px;
}

.description, .payload {
    font-size: 18px;
    margin-left: 10px;
    min-height: 20px;
}

.payload{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.editBtn{
    margin-left: 10px;
}

.descriptionContainer {
    margin-bottom: 10px;
}

.descriptionInput {
    font-family: 'Nunito', serif;
    font-size: 18px;
    width: 100%;
    max-height: 100%;
    margin-left: 3px;
    margin-top: -4px;
}

.payloadContainer {

}


.btnsContainer {
    z-index: 2;
    position: absolute;
    top: -2px;
    right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.delBtn {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-right: 5px;
}
