.notificationContainer {
    position: fixed;
    top: 80px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 400px;
    max-width: 80vw;
    z-index: 100;
}

.notification {
    width: 100%;
    background-color: transparent;
    margin-bottom: 15px;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    animation-duration: 0.3s;
    animation-name: VanillaToasts;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    pointer-events: all;
    padding: 1px;
}

.notificationFadeOut {
    animation-name: VanillaToastsFadeOut;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-fill-mode: forwards;
}

@keyframes VanillaToasts {
    from {
        transform: translate3d(100%, 0, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes VanillaToastsFadeOut {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        transform: translate3d(100%, 0, 0);
        opacity: 0;
    }
}
