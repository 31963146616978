.container {
    width: 920px;
}
.title {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 22px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.swapIcon {
    background-color: var(--secondary);
    font-size: 16px;
    height: 34px;
    width: 60px;
    color: var(--primary1);
    font-weight: 700;
    border-radius: 5px;
    display: flex;
    position: absolute;
    right: 60px;
    top: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 4;
}

.clientContainer {
    padding: 20px;
}
