.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    border-radius: 10px 0 0 10px;
}
.label {
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: inherit;
    height: 100%;
}

.dropdownIcon {
    margin-left: 9px;
    margin-right: 7px;
    width: 17px;
    height: 17px;
}

.itemSubContainer {
    padding-left: 30px;
    padding-bottom: 5px;
}

.colorIcon {
    height: 15px;
    border-radius: 5px;
    width: 17px;
    margin-right: 7px;
}
