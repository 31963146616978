.selectedBtn {
    background-color: var(--secondary);
    color: var(--primary1);
}
.btn {
    background-color: var(--primary1);
    color: var(--secondary);
}

.selectedBtn,
.btn {
    transform: rotate(90deg);
}

.imgWrapper,
.imgWrapperHovering,
.imgWrapperActive {
    height: calc(100% - 6px);
    width: 100%;
    position: absolute;
    top: 0;
}

.imgWrapperHovering {
    border: 2px solid var(--secondary);
    margin-top: -2px;
    margin-left: -2px;
}
.imgWrapperActive {
    border: 4px solid var(--secondary);
    margin-top: -4px;
    margin-left: -4px;
}

.loaderContainer,
.errorContainer {
    height: 100%;
    width: 100%;
    display: flex;
    background-color: rgb(0, 0, 0, 0.2);
    z-index: 20;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 6px);
}
.errorContainer {
    background-color: rgb(0, 0, 0, 0.6);
}

.loader {
    margin: auto;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.warningIcon {
    color: var(--error);
}

.warningLbl {
    text-align: center;
    color: var(--error);
}
