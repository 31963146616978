.container {
    display: flex;
    flex-direction: column;
}

.editorContainer {
    display: flex;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
}

.validationContainer {
    margin-left: 20px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.validationLabel {
    font-size: 18px;
}

.validationSelectorContainer {
    width: 250px;
    margin-left: 10px;
    max-width: 100%;
}

.integrationValidationContainer,
.otherValidationContainer {
    width: 300px;
    margin-left: 20px;
    margin-top: 5px;
    max-width: calc(100% - 40px);
}
.integrationValidationContainer {
    margin-bottom: 10px;
}

.inputContainer {
    margin-bottom: 20px;
}

.toggleLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 10px;
}

.toggleContainer {
    margin-left: 5px;
}

.clientInfoContainer {
    margin-left: 50px;
    margin-bottom: 10px;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}
.clientLbl,
.clientInfoLabel {
    font-size: 18px;
}
.clientLbl {
    margin-right: 5px;
}

.clientInfoSelectorContainer {
    width: 200px;
    margin-left: 20px;
    max-width: 100%;
}

.clientContainer {
    display: flex;
    flex-direction: column;
}

.propertySelectorContainer {
    width: 220px;
    margin-left: 45px;
    max-width: 100%;
}

.recurringLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 10px;
}

.cbrValidation {
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
}

.authSelectContainer {
    width: 250px;
    margin-left: 35px;
    margin-top: 5px;
    max-width: 100%;
}

.authLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 20px;
}

.authContainer {
    margin-bottom: 10px;
}
