.header {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}
.titleLbl {
    font-size: 17px;
    cursor: pointer;
}

.editContainer {
    margin-left: auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
}
.agentContainer {
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
}

.nameLbl {
    font-size: 14px;
    margin-left: 6px;
}

.container {
    margin-bottom: 10px;
    margin-top: 10px;
}
