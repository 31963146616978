.btnContainer {
    cursor: pointer;
}

.btnContainer:hover {
    color: var(--contrast);
}

.editContainer {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    width: min(70vw, 800px);
}

.editHeader {
    width: 100%;
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
}

.editorContainer {
    height: 50vh;
    width: 100%;
    background-color: transparent;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 25px;
}

.saveButton {
    margin-left: 60px;
}
