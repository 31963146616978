.loginLayout {
    height: 100%;
    display: flex;
    width: 100%;
    overflow: hidden;
    background-color: var(--primary2);
}
.semiCircle {
    margin-top: auto;
    width: 30vmin;
    height: 30vmin;
    min-width: 100px;
    min-height: 100px;
    object-fit: contain;
    background-color: var(--secondary2);
    border-top-left-radius: 0%;
    border-top-right-radius: 100%;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
}
.triangle {
    width: 0;
    height: 0;
    border-bottom: 500px solid var(--contrast);
    border-left: 500px solid transparent;
    margin-left: auto;
    transform: translateY(-300px) translateX(150px) rotate(10deg);
}
.cardLayout {
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    overflow-y: auto;
}
.loginCard {
    margin: 7.5vh auto;
    z-index: 2;
    width: 85vw;
    background-color: white;
    border-radius: 20px;
    min-height: 85vh;
    height: fit-content;
    display: flex;
    flex-direction: column;
}
.cardContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    height: 100%;
}

@media screen and (max-width: 320px) {
    .loginCard {
        width: 100vw;
        height: 100vh;
        margin: 0px;
        border-radius: 0px;
    }
}
