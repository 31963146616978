.header {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 22px;
    align-items: center;
    cursor: pointer;
    max-width: 100%;
}

.label {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iconContainer {
    margin-right: 5px;
    width: 18px;
    height: 18px;
}

.partialIcon {
    width: 14px;
    height: 14px;
    --p: 0;
    aspect-ratio: 1;
    display: inline-grid;
    place-content: center;
    font-weight: bold;
    font-family: sans-serif;
    position: relative;
    border: 2px solid var(--secondary);
    background-color: var(--defaultGrey);
    border-radius: 20px;
}
.partialIcon:before {
    content: '';
    position: absolute;
    border-radius: 20px;
    inset: 0;
    background: conic-gradient(var(--secondary) var(--p), #0000 0);
}

.fullIcon {
    width: 18px;
    height: 18px;
    background-color: var(--secondary);
    border-radius: 20px;
    font-size: 14px;
    color: white;
}
.errorIcon {
    width: 18px;
    height: 18px;
    background-color: var(--error);
    border-radius: 20px;
    font-size: 14px;
    color: white;
}
.emptyIcon {
    width: 18px;
    height: 18px;
    background-color: #bdbdbd;
    border-radius: 20px;
}

.separator {
    display: flex;
    min-height: 16px;
    width: 2px;
    background-color: #bdbdbd;
    border-radius: 10px;
    height: calc(100% - 4px);
}

.separatorCont {
    width: 18px;
    min-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.showMoreIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
}

.subElementsContainer {
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 7px;
    max-width: calc(100% - 20px);
}
.infoContainer {
    display: flex;
    flex-direction: row;
}
.lastInfo {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    gap: 5px;
}
.info {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    gap: 5px;
}

.dateInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
}
.dateContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-top: 3px;
    margin-left: 20px;
}

.agentsInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
}

.dateLbl {
    font-size: 12px;
    margin-left: 3px;
}
