.settingsContainer {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.settingsInputContainer {
    width: 200px;
    padding: 10px;
}

.designLabel {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
}
