.agentContainer {
    min-height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkIcon {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 22px;
    height: 22px;
}
.closeIcon {
    margin-right: 7px;
    font-size: 22px;
    height: 22px;
    margin-left: auto;
    cursor: pointer;
    border-radius: 6px;
}
.closeIcon:hover {
    border: 1px solid var(--greyFont);
    margin-right: 6px;
}

.nameLbl {
    font-size: 14px;
    margin-left: 8px;
    color: var(--accent);
}
.emailContainer {
    font-size: 12px;
    margin-left: 8px;
    color: var(--greyFont);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lblContainer {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 90px);
}
