.container {
    margin-left: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 10px;
    width: fit-content;
    border-radius: 10px;
    width: 640px;
}
.checkboxContainer {
    margin-bottom: auto;
}
.container:hover {
    background-color: var(--primary2);
}
.messageContainer {
    font-size: 1rem;
    cursor: pointer;
}

.highlight {
    color: var(--secondary);
    font-weight: bold;
    cursor: pointer;
}

.editingContainer {
    background-color: var(--primary2);
    padding: 10px;
    padding-top: 10px;
    margin-left: 9px;
    width: 640px;
    border-radius: 20px;
}

.interactionContainer {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.interactionLabel {
    font-size: 18px;
}

.interactionSelectorContainer {
    width: 160px;
    margin-left: 10px;
    max-width: 100%;
}

.linkInteractionContainer {
    margin-left: 20px;
    margin-right: 100px;
}

.dialogTitleContainer {
    margin-left: 10px;
    margin-right: 10px;
}
.dialogContentContainer {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.btnContainer {
    display: flex;
    flex-direction: row;
    height: 30px;
    margin-bottom: 10px;
}

.deleteBtn {
    width: 30px;
    height: 30px;
    font-size: 23px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    color: var(--greyFont);
    border: solid 1px var(--primary2);
    margin-left: auto;
}
.deleteBtn:hover {
    border: solid 1px var(--light-grey);
}
