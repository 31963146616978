.container {
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    padding-right: 7px;
}
.container:hover {
    background-color: var(--greyHover);
}
.nameLbl {
    cursor: pointer;
    font-size: 14px;
    margin-left: 8px;
    max-height: 38px;
    color: var(--accent);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.lblContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    margin-right: 5px;
    margin-right: auto;
}
.settingsIcon {
    margin: 1px;
    font-size: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 6px;
}

.settingsIcon:hover {
    border: 1px solid var(--greyFont);
    margin: 0px;
}

.iconsContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
