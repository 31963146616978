.container {
    display: flex;
}

.editorContainer {
    display: flex;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
}
