.optionLabel {
    margin-left: 12px;
    font-size: 18px;
    color: var(--accent);
}

.container {
    background-color: var(--primary1);
    border-radius: 0px;
    max-height: 300px;
    width: 230px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    overflow: hidden;
    margin-left: 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}

.cbrIcon {
    margin-left: 17px;
    margin-right: 7px;
    height: 28px;
    width: 28px;
    color: var(--secondary);
}

.cbrImg {
    display: flex;
    height: inherit;
    width: inherit;
}

.bottomLeftPadding {
    width: 10px;
    height: 40px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    cursor: pointer;
    z-index: 4000;
}

.iconContainer {
    width: 30px;
    height: 30px;
}
.selectInputContainer {
    position: relative;
    margin-left: 10px;
    border-radius: 5px;
    width: 19px;
    height: 20px;
    border: 2px solid var(--secondary);
    font-size: 18px;
    font-weight: 600;
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--secondary);
}
.inputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 32px;
    height: 19px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 20px;
    font-weight: 600;
    padding-top: 1px;
    padding-left: 3px;
}
.textInputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 30px;
    height: 20px;
    border: 2px solid var(--secondary);
    font-size: 16px;
    font-weight: 600;
    padding-left: 5px;
}
.agentInputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 32px;
    height: 19px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 20px;
    font-weight: 600;
    padding-top: 1px;
    padding-left: 3px;
}
.iconContainer {
    color: var(--secondary);
    height: 18px;
    width: 18px;
    margin-left: 1px;
    margin-top: 0px;
    font-size: 18px;
}
