.container {
    width: 100%;
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-top: 8px;
    margin-right: 5px;
}

.loader {
    width: 100%;
}

.loaderLine {
    background-color: var(--secondary) !important;
}

.loaderFont {
    background-color: #3d5a7f66 !important;
    border-radius: 5px;
}
