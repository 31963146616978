.container {
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    overflow: hidden;
    width: 280px;
    position: relative;
    z-index: 20;
    background-color: white;
}
.btnsContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 10px;
}
.title {
    padding-left: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid var(--light-grey);
    color: var(--accent);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.titleLbl {
    margin-top: 5px;
}
.searchContainer {
    padding: 10px;
    padding-right: 7px;
    display: flex;
    flex-direction: row;
}

.emptyContainer {
    padding-top: 5px;
    min-height: 315px;
    padding-bottom: 5px;
}
.mobileContainer {
    height: 80vh;
    background-color: transparent;
    overflow-x: hidden;
}

.noElementsContainer {
    color: var(--accent);
    padding-top: 10px;
    font-size: 12px;
    padding-left: 12px;
    padding-bottom: 7px;
}

.listContainer {
    border-top: 1px solid var(--light-grey);
}

.confirmBtnContainer {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--light-grey);
}
