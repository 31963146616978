.textField {
    padding: 20px;
}

.ratingContainer {
    display: flex;
    max-width: 100%;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
}

.formBtnContainer {
    margin-top: 5px;
    margin-bottom: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .ratingContainer {
        font-size: 40px;
    }
    .textField {
        padding: 10px;
    }
}
