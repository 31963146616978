.container {
    width: 920px;
    min-height: 400px;
}
.title {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 22px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
