.messageContainer {
    font-size: 15px;
}
.btnsContainer {
    display: flex;
    flex-direction: row;
    max-width: calc(100%);
}
.selectedMessageBtn,
.messageBtn {
    background-color: var(--greyHover);
    border-radius: 10px;
    border: 1px solid var(--greyBtn);
    padding: 6px 12px;
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
    flex-direction: row;
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectedMessageBtn {
    border-color: var(--greyFont);
    background-color: var(--outlineGrey);
}

.replyContent {
    margin: 3px 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.replyBtn {
    background-color: var(--secondary2);
    border-radius: 10px;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    font-weight: 600;
    padding: 6px 12px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: default;
    min-width: 110px;
}

.unSelected {
    width: 100%;
    position: relative;
    min-height: 20px;
    font-size: inherit;
    font-style: italic;
    cursor: default;
}

.endingContainer {
    width: 100%;
    height: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.separator {
    height: 1px;
    margin-left: 10px;
    margin-right: 5px;
    flex-grow: 1;
    background-color: var(--secondary2);
}
.endSeparator {
    height: 1px;
    margin-left: 5px;
    margin-right: 10px;
    flex-grow: 1;
    background-color: var(--secondary2);
}

.endingLbl {
    font-size: 12px;
    color: var(--greyFont);
    display: flex;
}

.option {
    font-family: 'Nunito';
    font-size: 15px;
    position: relative;
}

.sectionMenuLbl {
    font-family: 'Nunito';
    font-size: 15px;
    margin-left: 10px;
    padding-left: 5px;
    padding-bottom: 1px;
    margin-right: 10px;
    font-weight: 600;
    color: var(--secondary);
    border-bottom: 1px solid var(--secondary);
}

.sectionMenu {
    height: fit-content;
}
.optionDescription {
    font-size: 12px;
    font-weight: 400;
}
.classifierIcon {
    position: absolute;
    height: 14px;
    width: 14px;
    right: -12px;
    top: 0px;
}
