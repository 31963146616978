.descriptionLabel {
    color: var(--accent);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
}

.descriptionInputContainer {
    margin-right: 25px;
    font-size: 16px;
    min-height: 34px;
}

.description {
    font-size: 16px;
    margin-left: 10px;
    min-height: 20px;
}

.descriptionInput {
    font-family: 'Nunito';
    font-size: 16px;
    margin-left: 2px;
    margin-top: -6px;
    padding-left: 12px;
    width: calc(100% - 15px);
}

.publicContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}

.publicLabel,
.internalLabel {
    font-size: 20px;
    font-weight: 600;
}

.internalContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    height: fit-content;
}
.disableChannelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    height: fit-content;
}

.standAloneLabel,
.urlLabel,
.captchaLabel {
    font-size: 18px;
}

.urlContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 15px;
}
.standAloneContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
}

.captchaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
}

.togglecontainer {
    margin-top: 2px;
    padding-left: 10px;
}

.urlInputContainer {
    margin-left: 15px;
    width: 600px;
}

.copyLink {
    margin-left: 3px;
    height: 31px;
    width: 30px;
    background-color: transparent;
    cursor: pointer;
    font-size: 24px;
    color: var(--greyBtn);
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: inherit;
}
.copyLink:active {
    color: var(--greyFont);
}

.btnContainer {
    margin-left: 10px;
    margin-bottom: 15px;
}

.confirmationContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 15px;
}
.confirmationLabel {
    font-size: 20px;
    font-weight: 600;
}
.confirmationEditorContainer {
    margin-top: 10px;
    width: 600px;
    font-size: 19.5px;
}
