.container {
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    overflow: hidden;
    width: 280px;
    max-width: 90vw;
    position: relative;
    z-index: 20;
    background-color: white;
}
.title {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 5px;
    font-size: 14px;
    height: 20px;
    font-weight: 600;
    border-bottom: 1px solid var(--light-grey);
    color: var(--accent);
    display: flex;
    flex-direction: row;
}
.searchContainer {
    padding: 10px;
    padding-right: 7px;
    display: flex;
    flex-direction: row;
}

.emptyContainer {
    padding-top: 5px;
    min-height: 315px;
}
.mobileContainer {
    height: 80vh;
    background-color: transparent;
}

.filterBtn {
    background-color: var(--light-grey);
    width: 20px;
    margin-left: 5px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.filterIcon {
    position: absolute;
    width: 12px;
    height: 12px;
}

.filterBtn:hover {
    border: 1px solid var(--greyFont);
}

.transferBtn {
    font-size: 14px;
    border-radius: 3px;
    height: 20px;
    /* margin-top: -1px; */
    margin-right: 2px;
    margin-left: auto;
    display: flex;
}
.transferToggle {
    height: 20px;
    margin-left: 0px;
    margin-top: -2px;
}

.filtersContainer {
    /* border-top: 1px solid var(--light-grey); */
    display: flex;
    flex-direction: column;
}

.areasFilters {
    margin-top: -5px;
    height: 30px;
    margin-left: 10px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--accent);
}

.areasDropDownContainer {
    width: 222px;
    margin-left: 7px;
}

.tabsContainer {
    display: flex;
    height: 18px;
    flex-direction: row;
    border-bottom: 1px solid var(--light-grey);
}

.agentsTab,
.areasTab {
    font-size: 12px;
    height: 17px;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid var(--primary1);
}

.agentsTab {
    margin-left: 8px;
}

.autoScaling {
    border-top: 1px solid var(--light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
}
