.editingContainer {
    min-width: calc(100% - 22px);
    min-height: 30px;
    max-width: calc(100% - 22px);
    height: fit-content;
    display: flex;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px;
    padding-bottom: 0px;
    flex-direction: column;
    padding-top: 5px;
    background-color: var(--primary2);
    border: 1px solid var(--light-grey);
    width: fit-content;
}
.container {
    width: fit-content;
    max-width: calc(100% - 20px);
    display: flex;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 0px;
    flex-direction: column;
    padding-top: 5px;
    margin-bottom: 5px;
    position: relative;
}
.btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 30px;
    margin-bottom: 10px;
}

.conditionsBtn,
.copyBtn,
.deleteBtn,
.pasteBtn,
.disabledBtn,
.disabledCopyBtn,
.upBtn,
.downBtn {
    width: 30px;
    height: 30px;
    font-size: 23px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    color: var(--greyFont);
    border: solid 1px var(--primary2);
}

.disabledBtn,
.disabledCopyBtn {
    color: var(--light-grey);
    cursor: default;
}

.copyBtn:hover,
.pasteBtn:hover,
.deleteBtn:hover,
.upBtn:hover,
.conditionsBtn:hover,
.downBtn:hover {
    border: solid 1px var(--light-grey);
}

.copyBtn {
    font-size: 20px;
}

.stepCurtain:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
}

.stepCurtain {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3;
}

.firstLvlContainer {
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.smallSeparator {
    height: 0;
    max-width: 100%;
}
.separator {
    flex-basis: 100%;
    height: 0;
}

/* Bottom Container */

.toggleContainer {
    margin-top: 2px;
}

.bottomContainer {
    margin-top: 15px;
    border-top: 1px solid var(--light-grey);
    margin-left: -10px;
    width: calc(100% + 20px);
}
.bottonContentContainer {
    height: 40px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.propContainer {
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.propLabel {
    font-size: 16px;
}

.sliderContainer {
    margin-left: 30px;
    display: flex;
    width: 150px;
    margin-right: 30px;
    height: 40px;
}

.idEditor {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
}
.idContainer {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
}
.idInputContainer {
    margin-left: 10px;
    width: 350px;
    max-width: calc(100% - 120px);
}

.changeIdContainer {
    margin-left: 5px;
    margin-top: 2px;
}
