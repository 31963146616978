.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 5px 0px;
}
.editingContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    margin-bottom: 20px;
}

.filtersLabel {
    font-size: 18px;
    margin-left: 20px;
    font-weight: 600;
}

.toggleContainer {
    margin-top: 2px;
}

.filtersContainer {
    margin-bottom: 5px;
}

.addOption {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 25px;
    cursor: pointer;
    margin-top: 10px;
}

.addIconContainer {
    font-size: 28px;
    height: 28px;
}

.addOptionLbL {
    font-size: 16px;
    color: var(--accent);
}
.property {
    display: flex;
    flex-direction: row;
    padding-left: 14px;
    padding-right: 32px;
    gap: 20px;
    font-size: 18px;
    color: var(--accent);
    padding-right: 20px;
    align-items: center;
}

.entityInputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 32px;
    height: 19px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 20px;
    font-weight: 600;
    padding-top: 1px;
    padding-left: 3px;
}

.entityIconContainer {
    color: var(--secondary);
    height: 18px;
    width: 18px;
    margin-left: 1px;
    margin-top: 0px;
    font-size: 18px;
}

.mapperIcon {
    position: relative;
    display: flex;
    margin-left: 15px;
    margin-right: 4px;
    margin-bottom: 3px;
    height: 30px;
    width: 30px;
    font-size: 30px;
    color: var(--secondary);
}

.pillContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 5px 0px;
    width: 100%;
}

.titleLbl {
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.descriptionPar {
    font-size: 16px;
    white-space: pre-wrap;
    cursor: default;
}

.btnContainer {
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 3px;
    width: fit-content;
}

.elementContainer,
.mobileContainer {
    background-color: #f0f0f0;
    border: 1px solid #d6d6d6;
    width: fit-content;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
}

.elementContainer {
    max-width: calc(100% - 112px);
    padding-right: 55px;
    padding-left: 55px;
}

.mobileContainer {
    max-width: calc(100% - 42px);
    padding-right: 20px;
    padding-left: 20px;
}

.elementTitleLbl {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    margin-left: 10px;
    margin-bottom: 10px;
}

.contentContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.deleteBtn {
    font-size: 24px;
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 15px;
}
