.container {
    min-height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.container:hover {
    background-color: var(--greyHover);
}
.iconContainer {
    margin-left: 15px;
    color: var(--secondary);
    font-size: 26px;
    height: 28px;
}
.nameLbl {
    cursor: pointer;
    font-size: 14px;
    margin-left: 8px;
    color: var(--accent);
}
.descriptionContainer {
    font-size: 12px;
    margin-left: 8px;
    color: var(--greyFont);
    cursor: pointer;
}
.lblContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
