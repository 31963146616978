.container {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 15px;
}

.textContainer {
    border-radius: 15px;
    font-size: 15px;
    width: calc(100% - 2px);
    border: 1px solid var(--outlineGrey);
    height: calc(100% - 2px);
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.textContainer:hover {
    border: 1px solid var(--secondary);
}

.editor {
    cursor: text;
    height: calc(100% - 46px) !important;
    width: 100%;
}

.wrapper {
    height: 100%;
    min-height: inherit;
    border-radius: 15px;
    flex-grow: 1;
    overflow: hidden;
}

.disabledCurtain {
    position: absolute;
    top: 0;
    height: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

.fullscreenBtn {
    border: 1px solid #f1f1f1;
    padding: 5px;
    width: 37px;
    height: 32px;
    border-radius: 7px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    border: 1px solid var(--light-grey);
    font-size: 30px;
    position: relative;
    margin-left: auto;
}

.fullscreenBtn:hover {
    border: 1px solid var(--greyBtn);
}

.charLimitContainer {
    font-size: 12px !important;
    text-align: right;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.errorMsg {
    position: absolute;
    margin-left: 14px;
    font-size: 0.75rem;
    margin-top: 2px;
    color: var(--error);
}

.fullScreenTextField {
    width: 80vw;
    height: 80vh;
    margin: 20px;
    position: relative;
    background-color: white;
}
