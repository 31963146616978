.container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 335px;
}

.emptyContainer {
    color: var(--accent);
    padding-top: 10px;
    font-size: 12px;
    padding-left: 12px;
    padding-bottom: 5px;
}
