.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    padding: 10px;
}
