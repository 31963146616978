.container {
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    overflow: hidden;
    width: 280px;
    max-width: 90vw;
    position: relative;
    z-index: 20;
    background-color: white;
}
.title {
    padding-left: 8px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid var(--light-grey);
    color: var(--accent);
}
.searchContainer {
    padding: 10px;
}

.emptyContainer {
    padding-top: 5px;
    min-height: 305px;
}

.mobileContainer {
    height: 80vh;
    background-color: transparent;
}

@media screen and (min-height: 620px) and (max-height: 799px) {
    .emptyContainer {
        min-height: 275px;
    }
}
