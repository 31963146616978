.container {
    position: relative;
}
.header {
    margin-bottom: 5px;
}
.subHeader {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-bottom: 5px;
}
.menuContainer {
    position: relative;
}
.titleLbl {
    font-size: 17px;
    cursor: pointer;
    color: var(--accent);
}
.subTicketSubject {
    margin-bottom: 5px;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.subjectLabel {
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 13px;
}
.pillContainer {
    margin-left: auto;
}
.subTicketContainer {
    margin-left: 10px;
    display: block;
}

.editContainer {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    color: var(--accent);
}
.pillContainer {
    max-width: 100%;
    padding-top: 1px;
    height: 26px;
    display: flex;
}

.pillsContainer {
    width: calc(100%);
    display: flex;
    flex-direction: column;
}

.pillCont {
    max-width: 100%;
    display: inline;
    cursor: pointer;
}
.pillContClosed {
    max-width: 100%;
    display: inline;
}

.container {
    margin-top: 15px;
    margin-bottom: 10px;
}
