.container {
    height: 100%;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}

.stepsContainer {
    height: 100%;
    flex-grow: 1;
    min-width: 0px;
    background-color: white;
    position: relative;
}
.detailContainer {
    min-width: 400px;
    width: 40%;
    max-width: 600px;
    background-color: var(--primary2);
    border-left: 1px solid var(--greyFont);
}

.containerLine {
    width: 100%;
    height: 10px;
    margin-top: -10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.previewBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.saveBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    /* right: 130px; */
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.whiteCurtain {
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    flex-flow: column;
    z-index: 5;
    background-color: white;
}

.overViewBtnContainer {
    top: 80px;
    position: absolute;
    height: 50px;
    width: 60px;
    background-color: var(--secondary2);
    font-size: 35px;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--accent);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-right: 10px;
    z-index: 2;
}
.overViewBtnContainer:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
}
.pageTitleCont {
    position: absolute;
    top: 0px;
    z-index: 2;
}
.layoutContainer {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
}
.initialMessage {
    padding-top: 90px;
}
.stepsLayout {
    width: 85%;
    max-width: 1000px;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    position: relative;
}

.replyContent {
    margin: 3px 3px;
    display: flex;
    flex-direction: row;
}

.w1,
.w2,
.w3,
.w4 {
    border-radius: 10px;
    background-color: var(--secondary2);
    height: 22px;
    width: 100px;
    margin-left: 3px;
    margin-right: 3px;
}
.w2 {
    width: 60px;
}
.w3 {
    width: 80px;
}
