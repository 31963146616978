.container {
    margin-top: 5px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

.toggleLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 5px;
}

.toggleContainer {
    margin-left: 5px;
}

.contentContainer {
    margin-left: 10px;
    margin-right: 40px;
    display: flex;
}

.editorContentContainer {
    min-width: 600px;
    height: 265px;
    position: relative;
    margin-left: 10px;
    margin-right: 40px;
    padding-bottom: 10px;
}

.numPickerContainer {
    margin-right: 20px;
    height: 40px;
    width: 140px;
    display: flex;
}
