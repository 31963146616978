.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.label {
    font-size: 20px;
    margin-right: 10px;
}
