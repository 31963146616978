.container {
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    overflow: hidden;
    width: 280px;
    max-width: 90vw;
    position: relative;
    z-index: 20;
    background-color: white;
    padding-bottom: 10px;
}
.title {
    padding-left: 10px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 5px;
    font-size: 16px;
    height: 20px;
    font-weight: 600;
    border-bottom: 1px solid var(--light-grey);
    color: var(--accent);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.processRolesLbl {
    margin-top: 10px;
    margin-left: 12px;
    font-size: 14px;
}
.processRolesContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 12px;
}

.saveBtn {
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.changeProcessBtn {
    margin-top: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.changeProcessTitle {
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.changeProcessContainer {
    padding: 30px 40px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.changeBtnsContainer {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.emptyRolesLbl {
    font-size: 14px;
    font-weight: bolder;
    margin-left: auto;
    margin-right: auto;
}
