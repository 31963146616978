.typeContainer {
    padding-left: 0px;
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
    color: var(--accent);
    line-height: 20px;
    text-align: left;
}

.icon {
    color: var(--secondary);
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
}

.iconContainer {
    font-size: 26px;
    margin-left: 2px;
    height: 32px;
    width: 26px;
    color: var(--secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.stickerIcon {
    color: var(--secondary);
    height: 50px;
    width: 50px;
}

.typeLblContainer {
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.typePickerContainer {
    width: 170px;
    margin-left: 5px;
}
