.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    width: fit-content;
    max-width: 100%;
}
