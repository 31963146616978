.container {
    display: flex;
}

.editorContainer {
    display: flex;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
}

.headerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.footerContainer {
    margin-left: 20px;
    margin-right: 20px;
}

.btnLblContainer {
    max-width: calc(100% - 20px);
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 20px;
}

.urlContainer {
    max-width: calc(100% - 20px);
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 20px;
}
