.label {
    font-size: 16px;
    max-width: 160px;
    padding-left: 12px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.container {
    height: 50px;
    width: 200px;
    border: 1px solid var(--secondary);
    border-radius: 25px;
    color: var(--accent);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.iconContainer {
    color: var(--secondary);
    font-size: 31px;
    margin-left: 12px;
    height: 31px;
}
