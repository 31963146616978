.container {
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    overflow: hidden;
    width: 280px;
    position: relative;
    z-index: 20;
    background-color: white;
}
.title {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 5px;
    font-size: 14px;
    height: 20px;
    font-weight: 600;
    border-bottom: 1px solid var(--light-grey);
    color: var(--accent);
    display: flex;
    flex-direction: row;
}
.transferBtn {
    font-size: 14px;
    border-radius: 3px;
    height: 20px;
    /* margin-top: -1px; */
    margin-right: 2px;
    margin-left: auto;
    display: flex;
}
.transferToggle {
    height: 20px;
    margin-left: 0px;
    margin-top: -2px;
}

.searchContainer {
    padding: 10px;
}

.emptyContainer {
    padding-top: 5px;
}
.mobileContainer {
    height: 80vh;
    background-color: transparent;
}
