.container {
    width: 1000px;
    min-height: 700px;
    height: 80vh;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
}

.containerLbl {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.noFormsFoundLabel {
    margin-top: 30px;
    font-size: 18px;
}

.formNav {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 2px solid var(--light-grey);
    max-width: 100%;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
}
.formNav {
    scrollbar-width: thin;
    scrollbar-color: var(--primary2) transparent;
}
.formNav::-webkit-scrollbar {
    height: 2px;
}

.formName,
.selectedFormName {
    width: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 300px;
    border-top: 2px solid white;
    border-radius: 10px 10px 0px 0;
    cursor: pointer;
}
.formName {
    margin-left: 2px;
    margin-right: 2px;
}

.selectedFormName {
    border-top: 2px solid var(--light-grey);
    border-left: 2px solid var(--light-grey);
    border-right: 2px solid var(--light-grey);
    background-color: white;
}
.formContainer {
    flex-grow: 1;
    height: auto;
    position: relative;
}
.scrollContainer {
    flex-grow: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    overflow-y: scroll;
    margin-top: -20px;
}

.widgetContainer {
    display: block;
    height: fit-content;
    width: fit-content;
    max-width: calc(100% - 40px);
    width: 960px;
    padding: 20px;
}

.btnContainer {
    margin-bottom: 40px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.configTab {
    height: 20px;
    margin-left: 30px;
    margin-right: auto;
    width: fit-content;
    color: var(--primary1);
    background-color: var(--secondary);
    border-radius: 0px 0px 15px 15px;
    padding-top: 2px;
    padding-left: 12px;
    column-gap: 3px;
    padding-right: 12px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    z-index: 3;
}

.configContainer {
    transition: height 0.5s;
    margin-top: -2px;
    background-color: var(--primary2);
    border-bottom: 2px solid var(--light-grey);
    overflow: hidden;
}

.expandLessIcon {
    display: flex;
    flex-direction: column;
    width: 15px;
}
.configContent {
    padding: 25px 30px;
}

.clientPickerContainer {
    max-width: 200px;
}
