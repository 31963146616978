.title {
    margin: 0;
    font-size: 24px;
    height: 35px;
    max-width: calc(100% - 100px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.titlelbl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    font-size: 24px;
}
.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.delBtn {
    margin-left: 20px;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.flujoLabel {
    margin-top: 15px;
    color: var(--accent);
    font-size: 18px;
}
.flujo {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
}
.containerDetail {
    font-size: 24px;
}

.label {
    margin-top: 5px;
    font-size: 18px;
}

.subAreasContainer {
    margin-top: 10px;
}

.subElementContainer {
    width: 220px;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.subElementContainerCreate {
    width: 208px;
    margin-left: 12px;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.membersContainer {
    margin-top: 10px;
}

.nameInput {
    font-family: 'Nunito';
    font-size: 24px;
    width: 100%;
    margin-left: -8px;
    margin-top: -8px;
}

/* TODO arreglar imagen del flujo */
.flowFoto {
    width: 300px;
    margin-left: -30px;
    max-height: auto;
}

.flowBtn {
    margin-left: 72px;
    margin-top: 10px;
    margin-bottom: 30px;
}
.arrowIconContainer {
    font-size: 24px;
    height: 24px;
    width: 30px;
    vertical-align: middle;
    justify-content: center;
    display: inline-flex;
}
.descriptionLabel {
    color: var(--accent);
    font-size: 18px;
    margin-bottom: 10px;
}

.description {
    font-size: 14px;
    margin-left: 10px;
    min-height: 20px;
}

.descriptionInput {
    font-family: 'Nunito';
    font-size: 14px;
    width: 100%;
    max-height: 100%;
    margin-left: 3px;
    margin-top: -4px;
}
.descriptionContainer {
    font-size: 14px;
}
