.container {
    margin-top: 10px;
}
.designLabel {
    font-size: 20px;
    font-weight: 600;
}

.stylesContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 15px;
    margin-left: 15px;
    width: 100%;
}

.stylesLabel {
    width: 150px;
    font-size: 18px;
}

.colorPick {
    height: 30px;
    width: 60px;
    margin-left: 35px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

.colorPickContainer {
    border-radius: 8px;
    margin-left: 10px;
}
