.container {
    display: flex;
    flex-direction: column;
}

.editorContainer {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    height: fit-content;
    margin-bottom: 5px;
}

.headerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.footerContainer {
    margin-left: 20px;
    margin-right: 20px;
}

.btnLblContainer {
    max-width: calc(100% - 20px);
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 20px;
}

.toggleLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 10px;
}

.toggleContainer {
    margin-left: 5px;
}

.clientContainer {
    display: flex;
    flex-direction: column;
}

.clientLbl,
.clientInfoLabel {
    font-size: 18px;
}
.clientLbl {
    margin-right: 5px;
}

.clientInfoContainer {
    margin-left: 50px;
    margin-bottom: 10px;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.clientInfoSelectorContainer {
    width: 200px;
    margin-left: 20px;
    max-width: 100%;
}

.recurringLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 20px;
}

.filtersLabel {
    font-size: 18px;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.toggleContainer {
    margin-top: 2px;
}
.filtersContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    row-gap: 10px;
    margin-left: 40px;
}

.addOption {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: 25px;
    cursor: pointer;
    margin-top: 10px;
}
.addIconContainer {
    font-size: 28px;
    height: 28px;
}

.addOptionLbL {
    font-size: 16px;
    color: var(--accent);
}

.pathContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 20px;
}
