.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.warning {
    height: 35px;
    width: 35px;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 35px;
    color: var(--error);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.msgContainer {
    padding-right: 10px;
    display: flex;
    font-weight: 600;
}
.msgLbl {
    font-size: 14px;
}
