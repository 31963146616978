.header {
    display: flex;
    flex-direction: row;
}
.titleLbl {
    font-size: 17px;
}

.clockContainer {
    margin-left: auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
}
