.container {
    width: 100%;
    flex-direction: row;
    display: flex;
    overflow-y: scroll;
    overflow-x: hidden;
}

.rightColumn {
    width: 450px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.leftContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.topContainer {
    height: 250px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
}
.projectsTitle {
    margin-top: 30px;
    font-size: 26px;
    margin-left: 75px;
}
.projectsContainer {
    margin-left: 83px;
    margin-top: 35px;
    height: 120px;
    overflow: hidden;
}

.recentContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.activityTitle {
    margin-top: 10px;
    font-size: 26px;
    margin-left: 75px;
}
.containerActivities {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: fit-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.pendingTitle {
    margin-top: 30px;
    font-size: 26px;
    margin-left: 20px;
}
.expiredTitle {
    font-size: 26px;
    margin-left: 20px;
}

.pendingTicketsContainer {
    margin-left: 10px;
    margin-right: 40px;
    margin-top: 5px;
    min-height: 360px;
}

@media screen and (max-width: 768px) {
    .topContainer {
        height: fit-content;
        margin-left: 0px;
        margin-right: 0px;
    }
    .projectsTitle,
    .activityTitle {
        margin-left: 20px;
        margin-top: 10px;
        font-size: 24px;
    }
    .activityTitle {
        margin-top: 5px;
    }

    .projectsContainer {
        margin-left: 0px;
        margin-top: 0px;
        justify-content: center;
        height: fit-content;
    }
    .containerActivities {
        margin-left: 60px;
        margin-right: 60px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 576px) {
    .projectsContainer {
        width: 100vw;
    }
    .projectCardContainer {
        width: calc(100% - 40px);
    }
    .containerActivities {
        margin-left: 20px;
        margin-right: 20px;
    }
}
