.container {
    width: 1100px;
    min-height: 700px;
    max-height: 90vh;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.widgetContainer {
    height: fit-content;
    width: fit-content;
    max-width: calc(100% - 40px);
    min-height: 400px;
    width: 980px;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 90vw;
        min-height: 60vh;
        max-height: 80vh;
    }

    .widgetContainer {
        padding: 0px;
        max-width: 100%;
    }
}
