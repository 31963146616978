.container {
    display: flex;
    flex-direction: column;
}

.pickerContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}
