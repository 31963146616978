.container {
    padding: 5px;
    padding-bottom: 0px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 15px);
}

.ticketContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 5px 9px 10px 9px;
    max-width: 100%;
    cursor: pointer;
}
.nameLbl {
    font-size: 14px;
    margin-left: 4px;
    color: var(--accent);
    cursor: pointer;
}
.ticketContainer:hover {
    text-decoration: underline;
    cursor: pointer;
}

.caseLbl {
    font-weight: 600;
    font-size: 14px;
}
