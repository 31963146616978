.container {
    background-color: var(--primary1);
    border-radius: 10px;
    max-height: 300px;
    width: 230px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    overflow: hidden;
    overflow-y: auto;
}

.option {
    border-top: 1px solid var(--light-grey);
    height: 39px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.option:hover {
    background-color: var(--light-grey);
}
.titleIcon {
    margin-left: 15px;
    height: 30px;
    width: 30px;
    font-size: 30px;
    color: var(--secondary);
}
.checkBox {
    border-top: 1px solid var(--light-grey);
    height: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.checkBox:hover {
    background-color: var(--light-grey);
}
.checkBoxIcon {
    margin-left: 15px;
    margin-right: 3px;
    margin-bottom: 3px;
    height: 30px;
    width: 30px;
    font-size: 30px;
    color: var(--secondary);
}
.titleOptionLabel {
    margin-left: 13px;
    font-size: 18px;
    color: var(--accent);
}
.optionLabel {
    margin-left: 8px;
    font-size: 18px;
    color: var(--accent);
}

.textInputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 30px;
    height: 20px;
    border: 2px solid var(--secondary);
    font-size: 16px;
    font-weight: 600;
    padding-left: 5px;
}

.selectInputContainer {
    position: relative;
    margin-left: 10px;
    border-radius: 5px;
    width: 19px;
    height: 20px;
    border: 2px solid var(--secondary);
    font-size: 18px;
    font-weight: 600;
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--secondary);
}

.dateInputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 30px;
    height: 18px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 16px;
    font-weight: 600;
    padding-top: 2px;
    padding-left: 5px;
}

.agentInputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 32px;
    height: 19px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 20px;
    font-weight: 600;
    padding-top: 1px;
    padding-left: 3px;
}

.textAreaContainer {
    position: relative;
    margin-left: 12px;
    margin-right: 3px;
    border-radius: 5px;
    background-color: var(--light-grey);
    width: 30px;
    height: 28px;
    border: 2px solid var(--dark-grey);
    font-size: 24px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--dark-grey);
}

.fileInputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 30px;
    height: 18px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 16px;
    font-weight: 600;
    padding-top: 2px;
    padding-left: 5px;
}

.separatorContainer {
    position: relative;
    margin-left: 11px;
    margin-right: 2px;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--secondary);
}
.separatorSeparator {
    height: 20px;
    margin-left: -4px;
    margin-right: -4px;
    width: 2px;
    background-color: var(--secondary);
}

.checkBoxIcon {
    margin-left: 15px;
    margin-right: 4px;
    margin-bottom: 3px;
    height: 30px;
    width: 30px;
    font-size: 30px;
    color: var(--secondary);
}

.entityIcon {
    margin-left: 15px;
    margin-right: 5px;
    height: 30px;
    width: 30px;
    font-size: 30px;
    color: var(--secondary);
}

.mapperIcon {
    margin-left: 15px;
    margin-right: 4px;
    margin-bottom: 3px;
    height: 30px;
    width: 30px;
    font-size: 30px;
    color: var(--secondary);
}
