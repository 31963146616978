.container {
    border-right: 1px solid #eee;
    font-size: 12px;
    background: var(--primary1);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -o-user-select: none;
    user-select: none;
    border: 1px var(--light-grey) solid;
    width: 250px;
    overflow-y: auto;
    height: 100vh - 54px;
}

.nodeContainer {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.sideBarTitle {
    padding: 5px 15px 0px 15px;
    margin-bottom: -5px;
    font-size: 20px;
    font-weight: bold;
}
.hr {
    width: 100%;
    height: 1px;
    background-color: var(--light-grey);
}

.agentsTopRow {
    display: flex;
    flex-direction: row;
}

.subTitle {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    width: fit-content;
}
.areasContainer {
    padding-left: 15px;
    padding-bottom: 10px;
}
.areasContContainer {
    max-height: 230px;
    overflow-y: scroll;
}
.agentsContainer {
    padding-left: 15px;
    padding-bottom: 10px;
    overflow-y: scroll;
    max-height: 280px;
}

.addAgent {
    margin-top: auto;
    margin-left: auto;

    height: 21px;
    margin-right: 20px;
    font-size: 23px;
}
