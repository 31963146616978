.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: default;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 90vh;
    z-index: 6;
    margin-top: 10px;
    max-width: 600px;
}

.header {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    margin-left: 10px;
}

.headerTitle {
    text-decoration: underline;
}

.headerButtons {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}

.refreshIconContainer {
    margin-right: 10px;
    position: relative;
}
.refreshIconBorder {
    color: var(--secondary) !important;
}

.refreshIconBorder:hover {
    background-color: var(--secondary) !important;
    color: white !important;
}

.notificationIcon {
    position: absolute;
    right: 0;
    top: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--contrast);
    color: #fff;
    font-size: 9px;
    font-weight: 600;
    height: 11px;
    width: 11px;
    text-align: center;
    z-index: 14;
}

.list {
    width: 100%;
    height: calc(100% - 40px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
