.property {
    padding-left: 0px;
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
}
.propertyMenuItem {
    height: 42px;
    display: flex;
}

.icon {
    width: 26px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: 7px;
    font-size: 22px;
    color: var(--secondary);
}

.shapesIcon {
    color: var(--secondary);
    height: 24px;
    width: 30px;
}
.companyIcon {
    color: var(--secondary);
    height: 24px;
    width: 30px;
}

.sentimentContainer {
    font-size: 30px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
}
