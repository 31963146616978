.iframeStyles {
    height: calc(100% + 40px);
    z-index: 1;
    margin-top: -40px;
    overflow-y: hidden;
    overflow-x: scroll;
}

.vizTitle {
    margin: 10px 0 0 10px;
    z-index: 2;
}
