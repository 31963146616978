.optionLabel {
    margin-left: 12px;
    font-size: 18px;
    color: var(--accent);
}

.container {
    background-color: var(--primary1);
    border-radius: 0px;
    max-height: 300px;
    min-width: 230px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    overflow: hidden;
    margin-left: 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}

.bottomLeftPadding {
    width: 10px;
    height: 40px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    cursor: pointer;
    z-index: 4000;
}

.entityInputContainer {
    margin-left: 10px;
    border-radius: 5px;
    width: 32px;
    height: 19px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 20px;
    font-weight: 600;
    padding-top: 1px;
    padding-left: 3px;
}
.entityIconContainer {
    color: var(--secondary);
    height: 18px;
    width: 18px;
    margin-left: 1px;
    margin-top: 0px;
    font-size: 18px;
}
.relationshipContainer {
    position: relative;
    font-size: 26px;
    height: 26px;
    color: var(--secondary);
    margin-right: 5px;
    margin-left: auto;
}
