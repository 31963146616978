.title {
    margin: 0;
    font-size: 24px;
    height: 35px;
    max-width: calc(100% - 100px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.titlelbl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.delBtn {
    margin-left: 20px;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.descriptionLabel {
    color: var(--accent);
    font-size: 18px;
    margin-bottom: 10px;
}
.description {
    font-size: 14px;
    margin-left: 10px;
    min-height: 20px;
}
.containerDetail {
    font-size: 24px;
}

.label {
    margin-top: 10px;
    font-size: 18px;
}

.nameInput {
    font-family: 'Nunito';
    font-size: 24px;
    width: 100%;
    margin-left: -8px;
    margin-top: -8px;
}

.descriptionInput {
    font-family: 'Nunito';
    font-size: 14px;
    width: 100%;
    max-height: 100%;
    margin-left: 3px;
    margin-top: -4px;
}

.arrowIconContainer {
    font-size: 24px;
    height: 24px;
    width: 30px;
    vertical-align: middle;
    justify-content: center;
    display: inline-flex;
}

.entityBtn {
    margin-top: 15px;
}
.descriptionContainer {
    font-size: 14px;
}

.pluralNameLbl {
    font-size: 14px;
    margin-left: 10px;
    min-height: 20px;
}

.pluralNameInput {
    font-family: 'Nunito';
    font-size: 14px;
    width: 100%;
    max-height: 100%;
    margin-left: 0px;
    margin-top: -7px;
    padding-left: 8px !important;
}

.pluralNameContainer {
    max-width: 300px;
    min-height: 30px;
}
