.container {
    max-width: 90vw;
    width: fit-content;
    padding: 10px 20px;
    height: calc(90vh - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.widgetContainer {
    height: 100%;
    max-width: 100%;
}
.previewBtn {
    position: absolute;
    width: 70px;
    height: 40px;
    background-color: var(--secondary);
    top: 40px;
    right: 30px;
    border-radius: 12px;
    color: white;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

.mobileWidgetContainer {
    width: 30vw;
    min-width: 304px;
    max-width: 450px;
    height: calc(100% - 140px);
    overflow-y: scroll;
    border: 20px solid black;
    border-bottom: 40px solid black;
    border-radius: 20px;
}
