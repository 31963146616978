.valueContainer,
.propertySelectorContainer {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.propertySelectorContainer {
    min-width: 176px;
    max-width: 600px;
}
