.container {
    width: calc(100% - 20px);
    display: flex;
    position: relative;
    align-items: center;
    padding: 0px 10px;
    padding-top: 10px;
}
.inputContainer {
    margin-right: auto;
    display: flex;
    padding-bottom: 0px;
    margin-bottom: 0px;
    flex-grow: 1;
}

.titleLbl {
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 35px;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: auto;
}

.btnsContainer {
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-left: 10px;
}
