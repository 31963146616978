.stepContainer,
.thickStepContainer {
    display: flex;
    position: relative;
    overflow: hidden;
}

.stepCont {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
    max-width: 75%;
    min-width: 100px;
    position: relative;
}
.disabledIcon {
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 3;
    opacity: 0.6;
}

.thickStepContainer .disabledIcon {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    height: calc(100% + 2px);
    width: calc(100% + 2px);
}

.hiddenCont {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    min-width: 100px;
    max-width: 100%;
}

.messageCard {
    min-height: 20px;
    background-color: var(--greyHover);
    border-radius: 10px 0px 10px 10px;
    border: 1px solid var(--greyBtn);
    cursor: pointer;
    padding: 10px 13px;
    position: relative;
}

.thickStepContainer .messageCard {
    border-width: 2px;
    padding: 9px 12px;
}

.hiddenCard {
    min-height: 20px;
    border-radius: 10px;
    border: 2px solid var(--greyBtn);
    cursor: pointer;
    padding: 9px 12px;
    position: relative;
    border-style: dashed;
    overflow: hidden;
    margin-left: 20px;
    min-height: 50px;
    min-width: 300px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thickStepContainer .hiddenCard {
    background-color: var(--primary2);
    padding: 9px 12px;
}

.trContainer {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -8px;
    right: -15px;
    overflow: hidden;
}

.thickStepContainer .tr,
.thickStepContainer .trBorder,
.thickStepContainer .locationTag {
    border-width: 2px;
}

.tr {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0px;
    z-index: 2;
    right: 26px;
    background-color: var(--greyHover);
    border-right: 1px solid var(--greyBtn);
    border-top: 1px solid var(--greyBtn);
    border-radius: 0px 2px 0px 0px;
    transform: rotate(33deg);
    cursor: pointer;
}

.trBorder {
    position: absolute;
    width: 30px;
    height: 1px;
    top: 0px;
    z-index: 2;
    right: 8px;
    border-top: 1px solid var(--greyBtn);
}

.headerContainer {
    font-size: 15px;
    font-weight: bold;
    margin-top: -3px;
    margin-bottom: 5px;
}

.footerContainer {
    font-size: 13px;
    font-weight: 300;
    padding-left: 2px;
    margin-top: 5px;
    margin-bottom: -3px;
}

.bottomComponent {
    padding-right: 20px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    display: inline;
    max-width: calc(100% - 20px);
}

.cardCurtain {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.locationTagContainer {
    max-width: calc(100% - 60px);
    display: flex;
    margin-left: 10px;
    position: relative;
    margin-right: -20px;
    height: 20px;
}

.hiddenCont .locationTagContainer {
    max-width: calc(100% - 80px);
    margin-left: 30px;
}

.hiddenCont .locationTag {
    background-color: transparent;
    border-top: 2px solid var(--greyBtn);
    border-left: 2px solid var(--greyBtn);
    border-right: 2px solid var(--greyBtn);
    border-bottom: 0px;
    border-style: dashed;
}

.locationTag {
    background-color: var(--greyHover);
    height: 17px;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: -10px;
    border-top: 1px solid var(--greyBtn);
    border-left: 1px solid var(--greyBtn);
    border-right: 1px solid var(--greyBtn);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-top: 1px;
    padding-right: 5px;
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;
    font-size: 12px;
    width: fit-content;
    max-width: calc(100% + 5px);
    position: absolute;
}

.thickStepContainer .hiddenCont .locationTag {
    background-color: var(--primary2);
}

.thickStepContainer .stepCont .locationTag {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: -11px;
}

.locationContent {
    max-width: 100%;
}
.btnIcon {
    width: 10px;
    height: 5px;
    margin-top: 4px;
    margin-bottom: 7px;
    border-radius: 2px;
    margin-right: 3px;
    background-color: var(--greyFont);
}

.listIcon {
    font-size: 14px;
}

.cardContainer {
    position: relative;
    padding-right: 20px;
    overflow: hidden;
}

.pill {
    display: flex;
    flex-direction: row;
    height: 16px;
}
.pillLbl {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.arrowIconContainer {
    margin-top: -1px;
    font-size: 15px;
    height: 16px;
    width: 20px;
    vertical-align: middle;
    justify-content: center;
    display: inline-flex;
}
.valueSpan {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
    margin-left: 0px;
    font-size: 12px;
}

.iconContainer {
    font-size: 15px;
    height: 16px;
    vertical-align: middle;
    justify-content: center;
    display: inline-flex;
}

@media (max-width: 900px) {
    .hiddenCard {
        min-width: 170px;
    }
}
