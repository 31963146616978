.container {
    width: 400px;
    height: 180px;
}
.containerErrors {
    width: 500px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
}

.containerUnknownError {
    width: 500px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 15px;
}
.titleError {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
}

.errorsIcon {
    height: 37px;
    margin-left: 7px;
    font-size: 30px;
    color: var(--error);
}
.message {
    font-size: 22px;
    margin-bottom: 35px;
}

.messageErrors {
    font-size: 16px;
    margin-bottom: 15px;
}
.messageErrorsEnd {
    font-size: 16px;
    padding-top: 5px;
    margin-top: auto;
    margin-bottom: 5px;
}
.validatingMsg {
    font-size: 26px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 100%;
}

.errorMsgContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.errorsBullet {
    background-color: var(--accent);
    height: 10px;
    width: 10px;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 10px;
}

.viewErrorBtn {
    width: 75px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    background-color: var(--secondary);
    color: white;
    margin-left: auto;
    margin-right: 15px;
    cursor: pointer;
    font-size: 16px;
    flex-direction: row;
}

.errorMessageSpan {
    margin-right: 10px;
    width: calc(100% - 98px);
    font-size: 18px;
}

.cerrarBtn {
    margin-top: 80px;
    display: flex;
    justify-content: center;
}
