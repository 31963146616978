.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
.cardContainer {
    position: relative;
    background-color: var(--primary1);
    height: 110px;
    width: 400px;
    border-radius: 10px;
    margin-top: 10px;
    border: 2px solid var(--light-grey);
    display: flex;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}
.removeBtn {
    font-size: 20px;
    position: absolute;
    right: 3px;
    top: 3px;
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.title {
    display: flex;
    margin: 10px 0 0 20px;
    max-width: calc(100% - 50px);
    font-size: 16px;
    font-weight: 600;
    height: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1px;
    cursor: default;
}

.infoLbl {
    margin-top: 2px;
    font-size: 14px;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bottomContainer {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    height: 30px;
    align-items: center;
    margin-bottom: 6px;
}

.caseLbl {
    margin-left: 22px;
    font-size: 16px;
    font-weight: 600;
    width: 200px;
}

.photo {
    margin: auto 15px;
}

.sideBar {
    position: absolute;
    background-color: var(--secondary);
    height: 110px;
    width: 10px;
    border-radius: 10px 0 0 10px;
}
.viewMoreBtn {
    margin-bottom: 2px;
}

.email {
    margin: 10px 0 0 20px;
    font-size: 14px;
}
.permissionsContainer {
    background-color: var(--primary2);
    margin-left: 7px;
    width: calc(100% - 43px);
    padding: 10px;
    padding-left: 15px;
    padding-top: 5px;
    border: 2px solid var(--light-grey);
    border-top: none;
    border-radius: 0px 0px 0px 10px;
}

.permissionsList {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    max-width: calc(100% - 25px);
}

.permission {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.permissionLbl {
    font-size: 15px;
}
.toggleContainer {
    margin-left: 3px;
}

.permissionsTabContainer {
    height: 30px;
}

.permissionsTab {
    height: 22px;
    width: fit-content;
    margin-left: auto;
    font-size: 14px;
    margin-right: 7px;
    background-color: var(--light-grey);
    border-radius: 0px 0px 10px 10px;
    padding-left: 7px;
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    position: relative;
}

.permissionsIcon {
    font-size: 20px;
    margin-top: 0px;
    margin-left: 7px;
    cursor: pointer;
}

.countLbl {
    font-size: 8px;
    background-color: var(--contrast);
    font-weight: 600;
    color: white;
    padding-top: 1px;
    min-width: 6px;
    height: 11px;
    text-align: center;
    padding-bottom: 0px;
    border-radius: 20px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
    margin-top: 1px;
    right: 21px;
    position: absolute;
}

.viewMoreBtn {
    margin-top: auto;
    margin-left: 25px;
    margin-bottom: 15px;
}
