.container {
    margin-top: 20px;
}
.emailPreferenceList {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.emailPreference {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
}
.emailPreferenceLbl {
    font-size: 16px;
}
.toggleContainer {
    margin-left: 3px;
}

.titleLbl {
    font-size: 18px;
    margin-top: 10px;
}
