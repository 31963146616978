.container {
    max-width: 100%;
}
.pill {
    padding: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    height: 16px;
}
.pillLbl {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.arrowIconContainer {
    margin-top: -2px;
    font-size: 15px;
    height: 15px;
    width: 20px;
    vertical-align: middle;
    justify-content: center;
    display: inline-flex;
}
.valueSpan {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
    margin-left: 4px;
    font-size: 12px;
}
