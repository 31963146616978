.container {
    z-index: 2;
}

.titles {
    display: flex;
    flex-flow: row;
    width: fit-content;
    height: 22px;
    margin-top: -22px;
    margin-left: 10px;
}

.tab,
.selectedTab {
    width: fit-content;
    height: 22px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 2px;
    display: flex;
    cursor: pointer;
}

.selectedTab {
    background-color: var(--secondary);
    color: white;
}
.tab {
    background-color: var(--defaultGrey);
}
.lblTab {
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}

.actionForm {
    display: flex;
    height: 100%;
}

.textFieldContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding-bottom: 3px;
    z-index: 5;
}
.textField {
    width: calc(100% - 51px);
    margin-left: 3px;
    position: relative;
    background-color: white;
    border-radius: 15px;
    height: fit-content;
}
