.treeItemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.treeItemTitle {
    margin: 7.5px;
    padding: 2.5px;
    padding-left: 0px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    border-radius: 10px 0 0 10px;
}
.label {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdownIcon {
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 17px;
    height: 17px;
}

.areaItemSubContainer {
    padding-left: 30px;
    padding-bottom: 5px;
}
