.agentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    width: fit-content;
    margin-right: auto;
    /* This is so the parent container doesn't show on drag https://stackoverflow.com/questions/22922761/rounded-corners-with-html-draggable*/
    transform: translate(0, 0);
    margin-top: 5px;
    margin-left: 5px;
    width: calc(100% - 15px);
}
.container {
    border-radius: 40px;
    background-color: var(--accent);
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--primary1);
    font-size: 20px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -o-user-select: none;
    user-select: none;
    position: relative;
}

.label {
    margin-left: 10px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: var(--accent);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* The cover blocks dragging the img */
.cover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 30px;
}
