.container {
    background-color: transparent;
    color: var(--accent);
    overflow-y: hidden;
    overflow-x: hidden;
}
.scrollContainer {
    min-width: 500px;
    min-height: 400px;
    max-height: 80vh;
    display: flex;
    height: fit-content;
    overflow-y: scroll;
    overflow-x: hidden;
}

.content {
    margin-left: 25px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.toggleLabelContainer {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-top: 5px;
}

.toggleContainer {
    margin-left: 5px;
}

.helperText {
    font-size: 15px;
    margin: 0px;
    font-style: italic;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 2px;
}

.autoSendContainer {
    margin-top: auto;
    margin-bottom: 10px;
    font-weight: 600;
}

.classifiersSelector {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 30px;
}
