.container {
    background-color: transparent;
    color: var(--accent);
    overflow-y: hidden;
    overflow-x: hidden;
}
.scrollContainer {
    min-width: 500px;
    min-height: 400px;
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.top {
    margin-top: 10px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
}

.valueContainer {
    position: relative;
    width: fit-content;
    margin-left: 15px;
}

.nameLbl {
    padding-left: 8px;
    font-size: 26px;
}

.autoContainer {
    margin-top: 5px;
    margin-left: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.autoLbl {
    font-size: 22px;
}

.togglecontainer {
    margin-top: 2px;
    padding-left: 10px;
}

.notAutoContainer {
    margin-top: 25px;
    margin-left: 15px;
    width: 250px;
}

.internalContainer {
    margin-top: 10px;
    margin-left: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.internalLbl {
    font-size: 17px;
}
