.editingContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    margin-bottom: 20px;
}

.toggleContainer {
    margin-top: 2px;
}

.textEditorContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.textEditorLabel {
    width: 150px;
    font-size: 18px;
}

.textEditorPreviewContainer {
    margin-bottom: 10px;
    height: 265px;
}

.textEditorPreviewLabel {
    margin-left: 5px;
    margin-bottom: 2px;
    font-size: 1rem;
}
