.container {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding: 3px 5px;
    align-items: center;
    justify-content: center;
}
.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
}
.label {
    padding-left: 3px;
    padding-right: 3px;
}

.inserting {
    position: absolute;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: linear;
    background: linear-gradient(
        to right,
        var(--secondary) 2%,
        rgba(255, 255, 255, 0.3) 20%,
        var(--secondary) 50%
    );
    background-size: 800px 100px;
    height: 100%;
    width: 100%;
}

@keyframes loading {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}
