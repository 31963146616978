.container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    font-size: 20px;
    justify-content: center;
}

.editorContainer {
    max-width: 1000px;
    width: 80vw;
    position: relative;
    background-color: white;
    border-radius: 15px;
    margin-top: 20px;
    min-height: 300px;
}
.commentContainer {
    margin-top: 25px;
    width: 100%;
}

.buttonsContainer {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    row-gap: 10px;
    column-gap: 30px;
}
