.container {
    background-color: var(--contrast);
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
    min-height: 60px;
    max-width: 200px;
    /* This is so the parent container doesn't show on drag https://stackoverflow.com/questions/22922761/rounded-corners-with-html-draggable*/
    transform: translate(0, 0);
}

.top {
    height: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.title {
    width: 100%;
    font-size: 9px;
    font-size: 13px;
    margin-top: 4px;
    margin-left: 3px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--primary1);
}
