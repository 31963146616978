.container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.entityContainer {
    display: flex;
    /* justify-content: center; */
    position: relative;
    width: fit-content;
    max-width: 100%;
    flex-direction: column;
}

.btnsContainer {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    flex-direction: row;
    height: 60px;
    align-items: center;
}

.delBtn {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-right: 5px;
}

.clientsContainer {
    max-width: 1000px;
    width: 100%;
    margin-top: 10px;
}
.clientsLbl {
    font-size: 24px;
    display: flex;
    flex-direction: row;
}

.addBtnContainer {
    margin-left: auto;
}

.loaderContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 200px;
}
.emptyLbl {
    font-size: 18px;
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clientsListContainer {
    justify-content: center;
    gap: 20px 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}
