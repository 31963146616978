.searchContainer {
    padding: 10px;
    padding-right: 7px;
    display: flex;
    flex-direction: row;
}

.filtersContainer {
    padding: 20px;
}
