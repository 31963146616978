.editingContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    margin-bottom: 20px;
}

.prevContainer {
    width: fit-content;
    max-width: 100%;
    display: flex;
    padding-bottom: 10px;
    width: 100%;
    flex-direction: column;
}

.labelLabel {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
}

.stepDescriptionLabel {
    font-size: 0.75rem;
    margin-top: 5px;
    font-weight: 400;
    line-height: 1.66;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: #989898;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.btnContainer {
    margin-top: 5px;
    margin-left: 10px;
    width: fit-content;
}

.ratingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ratingTypeContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.ratingTypeLbl {
    font-size: 18px;
}
.ratingTypeSelectorContainer {
    width: 170px;
    margin-left: 15px;
    height: 31px;
    display: flex;
}
.toggleContainer {
    margin-top: 2px;
}

.optionContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
}

.bulletContainer {
    height: 14px;
}
.bullet {
    border: 2px solid var(--greyFont);
    background-color: var(--light-grey);
    height: 10px;
    width: 10px;
    border-radius: 20px;
    margin-right: 10px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.optionRatingContainer {
    height: 30px;
    display: flex;
}

.optionsLabel {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 18px;
}

.optionsList {
    margin-left: 30px;
    padding-top: 10px;
    max-width: calc(100% - 40px);
    min-width: calc(100% - 30px);
    width: fit-content;
}
