.editingContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    margin-bottom: 20px;
}

.validationContainer {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.validationLabel {
    font-size: 18px;
}

.validationSelectorContainer {
    width: 160px;
    margin-left: 10px;
    max-width: 100%;
}

.otherValidationContainer {
    width: 400px;
    margin-left: 20px;
    margin-top: 10px;
    max-width: calc(100% - 20px);
}
