.baseInfo{
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    padding: 2%;
    border: #1a192b 3px solid;
    margin-bottom: 10px;
    margin-top: 20px;
    border-radius: 10px;
}

.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.label{
    font-size: 1rem;
    font-weight: bold;
    margin-right: 5%;
}