.container {
    font-size: 1rem;
    min-width: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.entidad {
    min-width: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid var(--dark-grey);
    padding-bottom: 5px;
}

.label {
    font-weight: bold;
    width: 20%;
}

.valor{
    width: 30%;
    display: flex;
    flex-direction: column;
}

.nombre{
    width: 40%;
}

.subLabel{
    font-weight: bold;
    width: 100%;
    margin-top: 5px;
}