.container {
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--accent);
    font-size: 40px;
    margin-left: 5px;
}
.interaction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.badge {
    position: absolute;
    background-color: var(--contrast);
    padding: 3px;
    min-width: 11px;
    height: 11px;
    border-radius: 15px;
    color: var(--primary1);
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    margin-left: 16px;
}
.interactionImg {
    height: 30px;
    width: 30px;
}
