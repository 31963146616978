.container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    position: relative;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #fff;
    margin: 20px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.label {
    font-size: 20px;
    font-weight: 600;
}

.constructionContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 20px;
}

.editorContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
}

.editorElement {
    margin-top: 15px;
    margin-left: 20px;
}

.stylesContainer {
    margin-left: 20px;
}
