.treeItemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.treeItemTitle {
    padding-left: 0px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
}
.label {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    white-space: nowrap;
}

.dropdownIcon {
    margin-left: 6px;
    margin-right: 6px;
    width: 13px;
    height: 13px;
}

.itemSubContainer {
    padding-left: 22px;
}
