.contextIcon {
    display: flex;
    height: 100%;
    border: 1px solid var(--light-grey);
    padding: 0;
    height: 28px;
    border: none;
    background-color: transparent;
    margin-right: 5px;
    cursor: pointer;
}

.contextBtn {
    border: 1px solid var(--light-grey);
    width: 37px;
    height: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin: 0 4px;
    cursor: pointer;
    position: relative;
    background-color: var(--primary1);
}

.contextBtn:hover:enabled {
    border: 1px solid var(--greyBtn);
}

.contextBtn:disabled {
    cursor: default;
    opacity: 0.3;
}
