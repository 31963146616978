.container {
    height: 50px;
    width: 200px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid var(--secondary);
}
.containerHover {
    cursor: pointer;
    background-color: rgb(79, 79, 79, 0.5);
    height: 50px;
    width: 200px;
    border-radius: 25px;
    z-index: 3;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 65px;
}

.photoContainer {
    width: 35px;
    margin-left: 10px;
    text-align: center;
}

.avatarContainer {
    width: min-content;
    margin: 0 auto;
}

.basicInfoContainer {
    width: 145px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.nameContainer {
    height: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 4px;
}

.nameLabel {
    font-size: 14px;
    color: var(--accent);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jobTitleLabel {
    font-size: 12px;
    color: var(--greyFont);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 6px;
}

.jobTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 17px;
}

.basicInfoContainer {
    padding-left: 10px;
}
