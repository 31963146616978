.top,
.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container {
    gap: 10px;
    margin-left: 15px;
}
.bottom {
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 10px 20px;
}
.top {
    margin-top: 5px;
}

.keyInput {
    font-size: 18px;
}
.bullet {
    border: 2px solid var(--greyFont);
    background-color: var(--light-grey);
    height: 10px;
    width: 10px;
    border-radius: 20px;
    margin-right: 10px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.deleteBtn {
    font-size: 21px;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greyFont);
    cursor: pointer;
}

.deleteBtn:hover {
    color: var(--dark-grey);
}

.paramTypeSelector {
    width: 170px;
    margin-left: 25px;
}
.requiredLabel {
    font-size: 16px;
}

.requiredContainer {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.toggleContainer {
    margin-top: -15px;
    margin-left: 5px;
    height: 20px;
}

.stepInfoContainer {
    display: flex;
    gap: 20px;
    flex-direction: row;
    max-width: 100%;
    align-items: center;
}

.valueInput {
    width: 150px;
}

.queryValueEditor {
    min-width: 150px;
}
