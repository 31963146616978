.container {
    max-width: 80vw;
    min-height: 50vh;
    max-height: 60vh;
    overflow-y: auto;
}

.entityContainer {
    padding-bottom: 20px;
    width: fit-content;
    max-width: 100%;
}
