.container {
    display: flex;
    flex-direction: row;
    font-size: 14px;
}

.infoContainer {
    max-width: calc(100% - 74px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
    padding: 7px 0px 5px 8px;
}

.leftContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 5px 8px;
    height: 60px;
}
.iconContainer {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hour {
    font-size: 11px;
    font-weight: 600;
    white-space: nowrap;
    color: var(--secondary);
}
