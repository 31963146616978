.contcontainer {
    min-height: 10px;
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 7px;
    min-width: fit-content;
}

.valueContainer,
.numberContainer,
.operatorContainer,
.conditionContainer {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
}
.numberContainer {
    width: 100px;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    min-width: fit-content;
}
.multiSelectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 300px;
    width: 100%;
    position: relative;
}

.valueContainer {
    flex-grow: 1;
}

.removeContainer {
    margin-left: auto;
    min-width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.removeConditionContainer,
.removeConditionContainerHover {
    height: 26px;
    min-width: 26px;
    margin-right: 15px;
    margin-left: auto;
    border-radius: 20px;
    border: 3px solid var(--secondary);
    font-size: 20px;
    color: var(--secondary);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.removeConditionContainerHover {
    color: var(--error);
    border: 3px solid var(--error);
}

.selectorContainer {
    width: fit-content;
    display: flex;
    min-width: 176px;
    max-width: 600px;
}

.classifierSelectorContainer {
    min-width: 300px;
    width: 100%;
    display: flex;
}

.sentiment {
    padding-left: 0px;
    height: 30px;
    display: flex;
    margin-top: auto;
    align-items: center;
    margin-bottom: auto;
    flex-direction: row;
}

.sentimentIconContainer {
    margin-right: 5px;
    margin-left: -8px;
    font-size: 30px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
}

.timeContainer {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
}

.toggleLabel {
    font-size: 16px;
    column-gap: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dayContainer {
    width: 80px;
}
