.editingContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    width: fit-content;
}

.inputContainer {
    margin-bottom: 20px;
}

.optionsLabel {
    margin-top: 10px;
    margin-left: 15px;
    font-size: 18px;
}

.optionsList {
    padding-top: 10px;
    margin-left: 20px;
    max-width: calc(100% - 20px);
    width: 100%;
}

.addOption {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--accent);
    margin-left: -18px;
    cursor: pointer;
}
.addIconContainer {
    font-size: 28px;
    height: 28px;
}

.addOptionLbL {
    font-size: 16px;
    color: var(--accent);
}
