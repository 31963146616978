.container {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
}
.contentContainer {
    min-height: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}
.emptyDetail {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    height: 100%;
}

.titleContainer {
    margin-top: 20px;
    margin-left: 14px;
    margin-right: 20px;
    max-width: calc(100% - 10px);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.title {
    width: 100%;
    font-size: 28px;
    max-width: calc(100% - 23px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.titlelbl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 28px;
    max-width: 100%;
    width: fit-content;
}

.nameInput {
    max-width: calc(100% - 8px);
    font-family: 'Nunito';
    font-size: 28px;
    width: 100%;
    margin-left: -8px;
    margin-top: -8px;
    margin-bottom: -8px;
}

.btn {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.btnsContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    color: var(--dark-grey);
}

.editorContainer {
    display: flex;
    position: relative;
    flex-grow: 1;
    height: 44px;
}
.editor {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.idEditor {
    margin-top: auto;
    display: flex;
    flex-direction: row;
}
.padding {
    min-height: 30px;
}
.idContainer {
    margin-left: 20px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 25px;
}
.idInputContainer {
    margin-top: 20px;
    margin-left: 10px;
    width: 350px;
    max-width: calc(100% - 170px);
}

.changeIdContainer {
    margin-left: 10px;
    margin-top: 12px;
}
