#vanillatoasts-container {
    position: fixed;
    width: 320px;
    font-family: 'Helvetica';
    pointer-events: none;
    z-index: 2000;
}
.toasts-top-right {
    top: 60px;
    right: 0;
}
.vanillatoasts-toast {
    position: relative;
    padding: 20px 17px;
    margin: 20px;
    border-radius: 10px;
    background: #f5f5f5;
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    animation-duration: 0.3s;
    animation-name: VanillaToasts;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    pointer-events: all;
}

.vanillatoasts-fadeOut {
    animation-name: VanillaToastsFadeOut;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-fill-mode: forwards;
}

#vanillatoasts-container p,
#vanillatoasts-container h4 {
    margin: 3px 0 !important;
}

.vanillatoasts-title {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 10px;
}

.vanillatoasts-text {
    font-size: 14px;
    color: #777;
}

.vanillatoasts-toast a,
.vanillatoasts-toast a:hover {
    color: #549edb !important;
    text-decoration: none !important;
}

/** toast types */
.vanillatoasts-success {
    border-bottom: 2px solid #51c625;
}

.vanillatoasts-warning {
    border-bottom: 2px solid #db9215;
}

.vanillatoasts-error {
    border-bottom: 2px solid #db2b1d;
}

.vanillatoasts-info {
    border-bottom: 2px solid #27abdb;
}

@keyframes VanillaToasts {
    from {
        transform: translate3d(400px, 0, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes VanillaToastsFadeOut {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        transform: translate3d(400px, 0, 0);
        opacity: 0;
    }
}
