.organizacionDropdown {
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.editButton {
    margin: 10px 0px 2px 17px;
}

.menu {
    font-size: 16px;
    margin-top: -5px;
}
