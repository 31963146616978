.editingContainer {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    margin-bottom: 20px;
}

.prevContainer {
    width: fit-content;
    max-width: 100%;
    display: flex;
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    flex-direction: column;
}

.labelLabel {
    font-size: 16px;
    margin-left: 8px;
    margin-bottom: 5px;
}

.stepDescriptionLabel {
    font-size: 0.75rem;
    margin-left: 14px;
    margin-top: -4px;
    font-weight: 400;
    line-height: 1.66;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btnContainer {
    margin-top: 5px;
    margin-left: 10px;
    width: fit-content;
}
