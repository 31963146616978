.editingContainer {
    display: flex;
    flex-direction: column;
}
.checkboxLbl,
.checkboxLblOverflow {
    font-size: 18px;
}
.checkboxLblOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.inputContainer {
    margin-bottom: 20px;
}
.labelCheckBoxContainer {
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.descriptionPar {
    color: #989898;
    font-size: 0.75rem;
    margin-left: 14px;
    margin-top: 4px;
    font-weight: 400;
    line-height: 1.66;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subStepsLbl {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-weight: 600;
    display: flex;
}

.checkboxContainer {
    font-size: 18px;
    margin-left: 20px;
    align-items: center;
    display: flex;
}

.stepsContainer {
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.addBtnContainer {
    margin-top: 10px;
    width: 100%;
}

.isListContainer {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}

.isListLbl {
    font-size: 18px;
}
