.passthroughContainer {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.passthoughLbl {
    margin-left: 15px;
    text-overflow: ellipsis;
}

.iconContainer {
    width: 40px;
    height: 40px;
    font-size: 40px;
}
