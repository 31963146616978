.container {
    background-color: var(--primary1);
    border-radius: 10px;
    max-height: 200px;
    width: 200px;
    margin-right: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    overflow: hidden;
    overflow-y: auto;
}
.option {
    border-top: 1px solid var(--light-grey);
    height: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.option:hover {
    background-color: var(--light-grey);
}

.addIcon {
    border-radius: 20px;
    height: 24px;
    width: 24px;
    /* background-color: var(--secondary); */
    color: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    border: 2px solid var(--secondary);
    margin-left: 10px;
}
.titleOptionLabel {
    margin-left: 13px;
    font-size: 18px;
    color: var(--accent);
}
