.infiniteList {
    overflow-y: auto;
    max-height: 295px;
}

.loadMoreBtn {
    font-size: 16px;
    color: var(--secondary);
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
}

.loaderDots {
    text-align: center;
    margin-top: -38px;
    margin-bottom: 10px;
}

.loaderDot {
    animation: blink 1s infinite;
    color: var(--secondary);
    font-size: 48px;
}

.loaderDot:nth-child(2) {
    animation-delay: 250ms;
}

.loaderDot:nth-child(3) {
    animation-delay: 500ms;
}

@keyframes blink {
    50% {
        color: transparent;
    }
}

.loaderContainer {
    position: relative;
    height: 50px;
}
