.company {
    cursor: pointer;
    font-weight: 700;
}
.company:hover {
    text-decoration: underline;
}

.container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: calc(100% - 40px);
    padding: 20px;
    padding-top: 10px;
    position: relative;
    min-height: 700px;
}

.entityContainer {
    display: flex;
    /* justify-content: center; */
    position: relative;
    width: fit-content;
    max-width: 100%;
    max-height: 90vh;
}
.swapIcon {
    background-color: var(--secondary2);
    font-size: 22px;
    height: 26px;
    width: 26px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    position: absolute;
    z-index: 4;
    right: 10px;
    top: 10px;
}
.companyClient {
    width: fit-content;
    padding-left: 30px;
}
.clientTitle {
    margin-left: 30px;
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 5px;
}
