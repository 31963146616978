.pictureCard {
    max-width: 500px;
    width: 25vw;
    min-width: 260px;
    background-color: white;
    margin: 0 0px 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.picture {
    margin: 0 auto 0 auto;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: solid 30px;
    border-color: transparent transparent var(--secondary) transparent;
    text-align: center;
    color: white;
    cursor: pointer;
}

.editLbl {
    margin-top: -4px;
}

.email {
    margin: 10px auto 10px auto;
    font-size: 18px;
    margin-bottom: 50px;
}

@media screen and (max-width: 1240px) {
    .pictureCard {
        width: 100%;
        max-width: 100%;
        min-height: 344px;
    }
}
