.container {
    height: 100%;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}

.templatesContainer {
    height: 100%;
    flex-grow: 1;
    min-width: 0px;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
}
.pageTitleCont {
    position: absolute;
    top: 0px;
    z-index: 2;
    width: calc(100% - 10px);
    background-color: white;
}

.templatesList {
    padding-top: 70px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    overflow-y: scroll;
}

.detailContainer {
    min-width: 400px;
    width: 40%;
    max-width: 600px;
    background-color: var(--primary2);
    border-left: 1px solid var(--greyFont);
}

.containerLine {
    width: 100%;
    height: 10px;
    margin-top: -10px;
    z-index: 3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.saveBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    /* right: 130px; */
    align-items: center;
    justify-content: center;
    z-index: 4;
}

.whiteCurtain {
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    flex-flow: column;
    z-index: 5;
    background-color: white;
}

.emptyDetail {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    height: 100%;
}
