.container {
    background-color: var(--primary1);
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    min-height: 120px;
    width: 600px;
    margin-left: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: calc(100% - 40px);
}

.deleteBtn {
    font-size: 24px;
    top: 10px;
    right: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greyFont);
    cursor: pointer;
    position: absolute;
}

.deleteBtn:hover {
    color: var(--dark-grey);
}

.conditionBtn {
    font-size: 24px;
    top: 10px;
    right: 40px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--greyFont);
    cursor: pointer;
    position: absolute;
}

.conditionBtn:hover {
    color: var(--dark-grey);
}

.conditionContainer {
    padding: 30px 40px 100px 40px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-height: 100px;
}

.nameLbl {
    padding-left: 8px;
    padding-right: 5px;
    font-size: 28px;
    margin-bottom: 15px;
}

.decisionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    min-width: 785px;
}

.iconContainer {
    margin-left: 40px;
}

.textContainer {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 10px;
}

.editIcon {
    font-size: 20px;
    margin-left: 2px;
    cursor: pointer;
}

.editIcon:hover {
    color: var(--contrast);
}

.payloadContainer {
    padding: 20px;
}
