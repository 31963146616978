.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    font-size: 1rem;
}

.infoContainer{
    width: 100%;
}

.loader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loaderRating{
    width: 100%;
}

.noResults{
    font-size: 1.5rem;
}

.searchBar{
    position: relative;
    width: 96%;
    padding: 2%;
}


.baseInfo{
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    padding: 2%;
    border: #1a192b 3px solid;
    margin-bottom: 5px;
    border-radius: 10px;
}

.divider{
    width: 100%;
    margin-bottom: 22px;
}

.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.label{
    font-size: 1rem;
    font-weight: bold;
    margin-right: 5%;
}

.ratingTitle{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.noRating{
    font-size: 1.3rem;
    margin-left: 15px;
}