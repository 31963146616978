.container {
    min-height: 460px;
    height: 93vh;
    padding: 1px 1px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 96vw;
}

.column {
    min-height: 500px;
    height: calc( 100% - 66px);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: #1a192b 2px solid;
    border-radius: 20px;
    overflow-y: auto;
    background-color: white;
}

.returnButton {
    margin-top: 10px;
}