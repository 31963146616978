.list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    min-width: max-content;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 5%;
}

.label {
    font-weight: bold;
    margin-right: 3%;
}

.value {
    overflow-wrap: break-word;
}

.element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background-color: var(--primary1);
    border: var(--dark-grey) 1px solid;
    width: 99%;
    border-radius: 10px;
    margin-top: 10px;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    width: calc(100% - 40px);
    padding: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sideBar {
    height: 100%;
    position: absolute;
    background-color: var(--secondary2);
    width: 10px;
    border-radius: 10px 0 0 10px;
}