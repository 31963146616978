.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imageContainer {
    border: 4px solid var(--greyBtn);
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.loaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
}

.image {
    max-width: 100%;
}
.disabledEditContainer,
.editContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 26px;
    margin-left: 10px;
}

.editContainer {
    cursor: pointer;
}
