.areaContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.label {
    font-size: 15px;
    width: 155px;
    padding-left: 8px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.iconContainer {
    color: var(--secondary);
    font-size: 26px;
    height: 28px;
}
