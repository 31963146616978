.icon {
    height: 30px;
    width: 30px;
    margin-left: 0px;
    margin-top: 1px;
}

.container {
    height: 30px;
    width: 30px;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--secondary);
}
