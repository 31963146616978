.container {
    padding-top: 6px;
    padding-bottom: 6px;

    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--light-grey);
    cursor: pointer;
    min-height: 30px;
}
.left {
    width: calc(100% - 40px);
}
.top {
    height: 20px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bottom {
    font-size: 12px;
    margin-left: 27px;
    margin-top: 2px;
    color: var(--greyFont);
}

.rootLbl {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.nextBtn {
    position: relative;
    width: 29px;
    height: 29px;
    margin-right: 8px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    border-radius: 10px;
    margin-bottom: 1px;
}
.nextBtn:hover {
    margin-right: 7px;
    border: 1px solid var(--light-grey);
    margin-top: -1px;
    margin-bottom: 0px;
}

.nextUnderLine {
    position: absolute;
    border: none;
    margin-top: 28px;
    font-size: 26px;
    border-bottom: 2px solid var(--accent);
}

.searchSymbol {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -16px;
    margin-left: 28px;
    font-size: 10px;
    background-color: var(--secondary2);
    border-radius: 10px;
    padding: 1px;
}
.search {
    background-color: var(--secondary2);
    border-radius: 3px;
}

.checkIcon {
    height: 18px;
    width: 18px;
    min-width: 18px;
    font-size: 18px;
    margin-left: 8px;
    border: 1px solid var(--light-grey);
    border-radius: 20%;
    margin-right: 8px;
}
