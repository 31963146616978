.container {
    padding: 5px;
    display: flex;
    flex-direction: row;
    min-height: 40px;
}
.entityValue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.entityValueContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
    position: relative;
    height: fit-content;
}

.nameLbl {
    font-size: 14px;
    margin-left: 4px;
}

.arrowContainer {
    display: flex;
    font-size: 40px;
    margin-top: 0px;
    margin-right: 5px;
    color: var(--secondary);
    justify-content: center;
    align-items: center;
}

.entityValueIcon {
    height: 22px;
    width: 22px;
}

.emptyContainer {
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
    color: var(--secondary);
    font-weight: 600;
}
