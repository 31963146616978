.processContainer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 100%;
    width: fit-content;
}

.processPicker {
    width: 260px;
}

.processRolesContainer {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.processRolesLbl {
    margin-top: 15px;
    font-size: 18px;
}
.processRolesInfo {
    margin-top: 10px;
    margin-left: 15px;
    font-size: 14px;
    justify-content: center;
}
.processRolePicker {
    width: 260px;
    max-width: calc(100%);
}

.entityValuePicker {
    width: fit-content;
}
.entityValueFallbackPicker {
    width: fit-content;
    margin-left: 20px;
    margin-top: 5px;
}

.entityValueRole {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 30px;
    gap: 20px;
}

.entityValueRoleLbl {
    font-size: 16px;
    margin-top: 5px;
    margin-left: 15px;
}

.formContainer {
    margin: -10px -18px -15px -18px;
    width: 296px;
    max-width: calc(100% + 20px + 16px);
}
