.label {
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-left: 20px;
    margin-top: 10px;
}

.toggleContainer {
    margin-left: 5px;
}
