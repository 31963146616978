.container {
    width: 400px;
}

.colorPick {
    height: 30px;
    width: 60px;
    margin-left: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.colorPickContainer {
    position: absolute;
    height: 100px;
    margin-top: 40px;
    border-radius: 8px;
    z-index: 3;
}
.label {
    margin-left: 15px;
    font-size: 18px;
}
.colorPickCont {
    font-weight: 300;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.parentInfo {
    margin-top: -10px;
    margin-bottom: 10px;
}
